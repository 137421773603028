var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.meta.actions.onChatLoad.loading === true
    ? _c("page-loader")
    : _c("div", [
        _vm.chat.thread == null
          ? _c(
              "div",
              { staticClass: "sp-pc-nochat-actions pt-4" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "is-primary-addon-1",
                    attrs: {
                      type: "is-primary",
                      loading: _vm.meta.actions.onChatStart.loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.startChat($event)
                      }
                    }
                  },
                  [_vm._v("Chat with Seller")]
                )
              ],
              1
            )
          : _c("div", [
              _c(
                "div",
                {
                  ref: "chatMessagesContainer",
                  staticClass:
                    "has-background-white p-5 chat-messages-container"
                },
                [
                  _c(
                    "perfect-scrollbar",
                    { attrs: { options: { suppressScrollX: true } } },
                    [
                      _vm.chat.messages.data.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.chat.messages.data.slice().reverse(),
                              function(message, index) {
                                return _c("div", { key: index }, [
                                  message.user.type == "CUSTOMER"
                                    ? _c("div", [
                                        _c("div", { staticClass: "columns" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-10 is-offset-2"
                                            },
                                            [
                                              message.type == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "p-4 border-top-left",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FFE5C6"
                                                      }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(message.body)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "p-4 border-top-left",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FFE5C6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "figure",
                                                        {
                                                          staticClass:
                                                            "image is-96x96"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: _vm.imageUrl(
                                                                message.body
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cmc_user-footer has-text-right"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-user"
                                                  }),
                                                  _vm._v(" Me ")
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  message.user.type == "SELLER"
                                    ? _c("div", [
                                        _c("div", { staticClass: "columns" }, [
                                          _c(
                                            "div",
                                            { staticClass: "column is-10" },
                                            [
                                              message.type == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "p-4 border-top-left",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FFD8D8"
                                                      }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(message.body)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "p-4 border-top-left",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FFD8D8"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "figure",
                                                        {
                                                          staticClass:
                                                            "image is-96x96"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: _vm.imageUrl(
                                                                message.body
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cmc_user-footer"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-user"
                                                  }),
                                                  _vm._v(" Seller ")
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    : _vm._e()
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    width: "320px",
                    bottom: "0px"
                  }
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { action: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onChatSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "b-field",
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "Type your replies here",
                              type: "search",
                              "custom-class": "chat-input-box",
                              expanded: ""
                            },
                            model: {
                              value: _vm.chatForm.data.body,
                              callback: function($$v) {
                                _vm.$set(_vm.chatForm.data, "body", $$v)
                              },
                              expression: "chatForm.data.body"
                            }
                          }),
                          _c(
                            "p",
                            { staticClass: "control" },
                            [
                              _c("b-button", {
                                staticClass: "button chat-input-button",
                                attrs: {
                                  "icon-pack": "fas",
                                  "icon-right": "camera",
                                  size: "is-medium"
                                },
                                on: { click: _vm.openImageuploadPopupModal }
                              }),
                              _c("b-button", {
                                staticClass: "button chat-input-button",
                                attrs: {
                                  "native-type": "submit",
                                  "icon-pack": "fas",
                                  "icon-right": "paper-plane",
                                  size: "is-medium",
                                  loading: _vm.chatForm.loading,
                                  disabled: _vm.chatForm.loading
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }