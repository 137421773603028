import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class SellerDirectoryService extends BaseService {
    static async getSeller (filter) {
        const response = await axios.get(`${API_URL}/api/customer/seller-directory`, {
            params: filter
        })
        return response.data
    }
}
