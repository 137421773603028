import Vue from 'vue'

import AppLayout from './views/layout/index.vue'
import router from './router'
import store from './store'

import './mixins'
import './plugins'
import './thirdParty'

import './sass/app.scss'
import './assets/fonts/bebasneue.css'

import VueGeolocation from 'vue-browser-geolocation'
import * as VueGoogleMaps from 'vue2-google-maps'
import axios from 'axios'
import { GOOGLE_API_KEY, SERVER_URL } from '@/.env'
import AOS from 'aos'
import 'aos/dist/aos.css'
import OTPInput from '@bachdgvn/vue-otp-input'

Vue.component('v-otp-input', OTPInput)

Vue.config.productionTip = false
Vue.use(VueGeolocation)

Vue.use(VueGoogleMaps, {
    load: {
        key: GOOGLE_API_KEY
    }
})

new Vue({
    router,
    store,
    mounted () {
        store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
        window.addEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))

        // load the user details and set on store

        axios.get(`${SERVER_URL}/sanctum/csrf-cookie`).then(response => {
        })
    },
    beforeDestroy () {
        window.removeEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
    },
    created () {
        AOS.init()
    },
    render: h => h(AppLayout)
}).$mount('#app')
