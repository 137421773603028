/**
 * import and init third party
 * components/validators and other dependencies
 */

import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import VueLogger from 'vuejs-logger'
import LoadScript from 'vue-plugin-load-script'
import ProductZoomer from 'vue-product-zoomer'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import Echo from 'laravel-echo'

Vue.use(Buefy, {
    defaultProgrammaticPromise: true
})
Vue.use(ProductZoomer)
Vue.use(PerfectScrollbar)

axios.defaults.withCredentials = true

const isProduction = process.env.NODE_ENV === 'production'

const options = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}

Vue.use(VueLogger, options)

Vue.use(LoadScript)

// window.Pusher = require('pusher-js')
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'findhub',
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     forceTLS: false,
//     disableStats: true
// })
