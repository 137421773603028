export const API_PORT = '5000'
export const SERVER_URL = process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_FINDHUB_SERVER_BASE_URL
    : process.env.VUE_APP_FINDHUB_SERVER_PROXY_BASE_URL
export const DOMAIN_TITLE = 'Findhub'
export const API_URL = SERVER_URL
export const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID
export const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API_KEY
export const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY ?? 'pk_test_51Iz1tzSChHCusFiV7tFNDArZs2Xj9RBQMUjFDlH7svL6SgbOOT0Mc5QkHdmqJa9LwE3ABu4tqwL3MvT3ca69jf9T00bRil6zJG'
export const CHAT_WS_HOST = (process.env.VUE_APP_CHAT_WS_HOST ? process.env.VUE_APP_CHAT_WS_HOST : '')
export const CHAT_WS_PORT = (process.env.VUE_APP_CHAT_WS_PORT ? process.env.VUE_APP_CHAT_WS_PORT : '')
export const PUSHER_APP_KEY = (process.env.VUE_APP_PUSHER_APP_KEY ? process.env.VUE_APP_PUSHER_APP_KEY : '')
export const PUSHER_APP_CLUSTER = (process.env.VUE_APP_PUSHER_APP_CLUSTER ? process.env.VUE_APP_PUSHER_APP_CLUSTER : '')
export const FACEBOOK_CLIENT_ID = process.env.VUE_APP_FACEBOOK_CLIENT_ID
