// import { BaseService } from './base.service'
import axios from 'axios'
import { API_URL } from '@/.env'

export class UsersService {
    static get entity () {
        return 'users'
    }

    static async getCurrent () {
        const response = await axios.get(`${API_URL}/api/customer/auth/what-is-myname`)
        console.log(response)
    }
}
