<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.onPageLoad.loading === true" ></page-loader>
        <div v-else>
            <div class="page-title">
                <div class="title">
                    Order Details
                </div>
            </div>
            <div class="page-content">
                <div class="box">
                    <div class="columns is-mobile" v-for="(item,index) in order.order_items" v-bind:key="index">
                        <div class="column is-two-fifths">
                            <div class="media">
                                <div class="media-left">
<!--                                    <img class="sr-image-width" :src="imageUrl(term.seller.logo)">-->
                                    <img :src="imageUrl(null)" style="width:50px; height: 50px">
                                </div>
                                <div class="media-content">
                                    <h5 class="has-text-weight-bold is-size-6">{{ item.name }}</h5>
                                    <span class="is-size-7">
                                    Quantity: {{ item.quantity }}
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="column is-two-fifths">
                            <div class="">
                                <h5 class="has-text-weight-bold is-5">{{ item.search_term_response.seller.business_name }}</h5>
                                <figure class="image is-24x24 has-text-centered-mobile">
                                    <img :src="imageUrl(item.search_term_response.seller.logo)">
                                </figure>
                            </div>
                        </div>
                        <div class="column">
                            <div class="has-text-right">
                                <span class="is-size-5">
                                    {{ item.total_price }}
                                </span> <br>
                                <span class="is-size-6 has-text-weight-light">
                                    {{ item.quantity }} x {{item.unit_price}}
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="box">
                            <h3 class="has-text-weight-bold mb-5">
                                Payment Method
                            </h3>
                            <div class="block">
                                <b-radio v-model="payment.method"
                                         name="payment_method"
                                         native-value="ONLINE"
                                         v-if="availablePaymentMethods.indexOf('ONLINE')>-1"
                                         class="mr-6">
                                    Credit / Debit Card
                                </b-radio>
                                <b-radio v-model="payment.method"
                                         name="payment_method"
                                         v-if="availablePaymentMethods.indexOf('COD')>-1"
                                         native-value="COD">
                                 Confirm Order
                                </b-radio>
                            </div>
                            <p>Click to Confirm your Order</p>
                        </div>
                    </div>
                    <div class="column">
                        <div class="box">
                            <h3 class="has-text-weight-bold mb-5">
                                Order Summary
                            </h3>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div>
                                        <h6 class="is-7">Total</h6>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="is-pulled-right">
                                        <h6 class="is-7">{{ order.sub_total }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div>
                                        <h6 class="is-7">Service Charge</h6>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="is-pulled-right">
                                        <h6 class="is-7">{{ order.commission + order.shipping_fee }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="px-10">
                                <hr class="hr-bg">
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div>
                                        <h6 class="is-7">Total Amount Payable </h6>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="is-pulled-right">
                                        <h6 class="is-7 font-color">{{ order.grand_total }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-mobile is-centered">
                    <div class="column is-half-desktop">
                        <div class="has-text-centered">
                            <b-button type="is-primary" class="is-primary-addon-1" :loading="meta.actions.onPlaceOrder.loading" v-on:click.prevent="onPlaceOrder()">
                                Place Order and Pay
                            </b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { OrderService } from '@/services/order.service'
import _ from 'lodash'

export default {
    name: 'OrderDetails',
    async mounted () {
        const order_id = this.$route.query.order_id
        if (!_.isNumber(parseInt(order_id))) {
            this.$router.push({ name: 'Page404' })
        }

        this.order = await OrderService.getOrder(order_id)
        const resp = await OrderService.getPaymentMethods()
        this.availablePaymentMethods = resp.methods
        this.payment.method = resp.methods[0]
        this.meta.actions.onPageLoad.loading = false
    },
    data: function () {
        return {
            meta: {
                actions: {
                    onPageLoad: {
                        loading: true
                    },
                    onPlaceOrder: {
                        loading: false
                    }
                }
            },
            order: null,
            availablePaymentMethods: null,
            payment: {
                method: 'ONLINE'
            }
        }
    },
    methods: {
        async onPlaceOrder () {
            try {
                this.meta.actions.onPlaceOrder.loading = true
                const orderDetails = await OrderService.updatePaymentAndPlaceOrder({ payment_method: this.payment.method })
                if (this.payment.method === 'COD') {
                    return this.$router.push({ name: 'OrderConfirmation', query: { order_id: orderDetails.id } })
                } else {
                    return this.$router.push({ name: 'OrderProcessPayment', query: { order_id: orderDetails.id } })
                }
            } catch (e) {
                this.meta.actions.onPlaceOrder.loading = false
            }
        }
    }

}
</script>

<style scoped>

</style>
