var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.loadCustomerAddresses.loading === true
        ? _c("page-loader")
        : _c("div", [
            _c("div", { staticClass: "page-title" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(" Select a delivery address ")
              ]),
              _c("div", { staticClass: "subtitle" }, [
                _vm._v(
                  " Choose from your saved addresses or create a new address. "
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "page-content" },
              [
                _c(
                  "b-loading",
                  {
                    attrs: { "is-full-page": true },
                    model: {
                      value: _vm.meta.actions.onAddressSelect.loading,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.meta.actions.onAddressSelect,
                          "loading",
                          $$v
                        )
                      },
                      expression: "meta.actions.onAddressSelect.loading"
                    }
                  },
                  [
                    _c("page-loader", {
                      attrs: {
                        "loading-text":
                          _vm.meta.actions.onAddressSelect.loadingText
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "level" }, [
                  _c(
                    "div",
                    { staticClass: "level-left" },
                    [
                      _c("b-button", {
                        staticClass: "is-rounded",
                        attrs: {
                          label: "Add new address",
                          type: "is-default",
                          "icon-left": "plus"
                        },
                        on: { click: _vm.onClickAddAddress }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "level-right" },
                    [
                      _c("b-button", {
                        staticClass: "is-primary-addon-2",
                        attrs: {
                          disabled: _vm.selectedAddress === null,
                          label: "Proceed",
                          type: "is-primary",
                          "icon-right": "arrow-right-bold"
                        },
                        on: { click: _vm.onProceed }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "columns is-multiline oa__saved_address_results_container"
                  },
                  _vm._l(_vm.savedAddressList, function(address, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "column is-one-third" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tile is-child box address-container",
                            class: {
                              "is-active": index === _vm.selectedAddress
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-4",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.onSelectAddress(index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "has-text-weight-bold pb-2",
                                    staticStyle: { "font-size": "0.875rem" }
                                  },
                                  [_vm._v(_vm._s(address.name))]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "0.875rem" } },
                                  [_vm._v(_vm._s(address.address_line_1))]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "0.875rem" } },
                                  [_vm._v(_vm._s(address.address_line_2))]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "0.875rem" } },
                                  [_vm._v(_vm._s(address.landmark))]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "0.875rem" } },
                                  [
                                    _vm._v(
                                      _vm._s(address.city) +
                                        "," +
                                        _vm._s(address.state) +
                                        "," +
                                        _vm._s(address.postcode)
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "0.875rem" } },
                                  [_vm._v(_vm._s(address.country))]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "0.875rem" } },
                                  [
                                    _vm._v(
                                      _vm._s(address.isd_code) +
                                        "-" +
                                        _vm._s(address.phone_number)
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }