import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class SellerService extends BaseService {
    static async saveSeller (reqData) {
        const response = await axios.post(`${API_URL}/api/seller/join_as_seller`, reqData)
        return response
    }
}
