<template>
    <div id="search-history">
        <page-loader v-if="meta.actions.onPageLoad.loading === true" ></page-loader>
        <b-table
            :data="searchHistory.pData.data"
            :bordered="false"
            :striped="false"
            v-else
        >
            <b-table-column field="search_term" label="Search Term" v-slot="props">
                {{ props.row.term }}
            </b-table-column>

            <b-table-column field="actions" label="Actions" v-slot="props">
                <b-button type="is-warning is-light" @click="routeToDetailPage(props.row)" size="is-small">View
                    Details
                </b-button>
            </b-table-column>
            <b-pagination
                :simple="isSimple"
                :per-page="searchHistory.pData.meta.per_page"
                :total="searchHistory.pData.meta.total"
                v-model="searchHistory.pData.meta.current_page"
                :icon-pack="iconPack"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :order="order"
                @change="onChangePage"
                >
            </b-pagination>
        </b-table>
    </div>
</template>

<script>
import { SearchService } from '@/services/search.service'
export default {
    name: 'SearchHistory',
    data: function () {
        return {
            meta: {
                actions: {
                    onPageLoad: {
                        loading: true
                    }
                }
            },
            searchHistory: {
                pData: {
                    data: []
                }
            },
            order: 'is-right',
            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            isSimple: false,
            iconPack: 'fa',
            term: null
        }
    },
    async mounted () {
        this.searchHistory.pData = await SearchService.searchHistory()
        this.meta.actions.onPageLoad.loading = false
    },
    methods: {
        routeToDetailPage (row) {
            this.$router.push({ name: 'SearchProduct', params: { id: row.id } })
        },
        async onChangePage (current_page) {
            this.page = current_page
            this.searchHistory.pData = await SearchService.searchHistory({ params: { per_page: this.searchHistory.pData.meta.per_page, page: this.searchHistory.pData.meta.current_page } })
            this.meta.actions.onPageLoad.loading = false
        }
    }
}
</script>
