<template>
    <div id="home-app" class="">
        <section class="hero is-primary-addon-1 is-fullheight">
            <!-- Hero head: will stick at the top -->
            <div class="hero-head">
                <nav class="navbar">
                    <div class="container">
                        <div class="navbar-brand">
                            <div class="p-4">
                                <img style="width: 10rem;" src="/images/new_logo_name_white.png">
                            </div>
                            <span class="home-navbar-burger" v-if="$store.getters['auth/isAuthenticated']"
                                data-target="navbarMenuHeroA" @click="open = true">
                                <!-- <span></span>
                            <span></span>
                            <span></span> -->
                                <b-button
                                type="has-text-white pt-5 is-hidden-desktop"
                                style="background-color: transparent; border-color: transparent;"
                                :icon-right="active ? 'menu-up' : 'menu-down'">{{ $store.state.auth.currentUser.name }}
                            </b-button>
                                <b-button type="has-text-white pt-5 is-hidden-desktop"
                                    style="background-color: transparent; border-color: transparent;"
                                    :icon-right="'menu-up'">{{ $store.state.auth.currentUser.name }}
                                </b-button>
                            </span>
                            <span class="navbar-burger" style="color: white;"
                                v-if="!$store.getters['auth/isAuthenticated']" data-target="navbarMenuHeroA"
                                v-on:click="openLoginPopupModal">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </div>
                        <div id="navbarMenuHeroA" class="navbar-menu">
                            <div class="navbar-end">
                                <div class="navbar-item">
                                    <span v-if="!$store.getters['auth/isAuthenticated']">
                                        <a v-on:click="openLoginPopupModal">
                                            <img class="toggle-button-image-2 mr-4" style="width: 2.5rem"
                                                src="/images/home-login.svg">
                                        </a>
                                    </span>
                                    <span v-if="$store.getters['auth/isAuthenticated']">
                                        <b-dropdown aria-role="list">
                                            <template #trigger="{ active }">
                                                <b-button type="has-text-white"
                                                    style="background-color: transparent; border-color: transparent;"
                                                    :icon-right="active ? 'menu-up' : 'menu-down'">{{
                                                    $store.state.auth.currentUser.name }} </b-button>
                                            </template>
                                            <template>
                                                <b-button type="has-text-white"
                                                    style="background-color: transparent; border-color: transparent;"
                                                    :icon-right="'menu-up'">{{ $store.state.auth.currentUser.name }}
                                                </b-button>
                                            </template>
                                            <b-dropdown-item v-on:click="onLogout"
                                                aria-role="listitem">Logout</b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </div>
                                <div class="navbar-item">
                                    <div>
                                        <div v-if="$store.getters['auth/isAuthenticated']" style="width: 1.5rem"
                                            class="is-clickable" @click="open = true">
                                            <img class="toggle-button-image-1" src="/images/menubar-white.svg">
                                        </div>
                                        <b-sidebar type="is-primary-sidebargradient has-text-weight-semibold"
                                            :fullheight="fullheight" :fullwidth="fullwidth" :overlay="overlay"
                                            :right="right" v-model="open">
                                            <div class="px-4 pt-6">
                                                <b-menu>
                                                    <b-menu-list label="Menu">
                                                        <b-menu-item tag="router-link" :to="{ name: 'Enquiry' }"
                                                            icon="information-outline"
                                                            label="My Enquiries"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'OrdersList' }"
                                                            icon="cart-outline" label="My Orders"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'Profile' }"
                                                            icon="account-outline" label="My Profile"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'SearchHistory' }"
                                                            icon="history" label="Search History"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'SellerDirectory' }"
                                                            icon="store" label="Seller Directory"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'PrivacyPolicy' }"
                                                            icon="shield" label="Privacy Policy"></b-menu-item>
                                                        <b-menu-item tag="router-link" :to="{ name: 'RefundPolicy' }"
                                                            icon="undo" label="Refund Policy"></b-menu-item>
                                                        <b-menu-item tag="router-link"
                                                            :to="{ name: 'TermsAndConditions' }" icon="file"
                                                            label="Terms & Conditions"></b-menu-item>
                                                    </b-menu-list>
                                                    <b-menu-list label="Actions">
                                                        <b-menu-item v-on:click="onLogout" icon="logout"
                                                            label="Logout"></b-menu-item>
                                                    </b-menu-list>
                                                </b-menu>
                                            </div>
                                            <div class="column p-0 " style="display:flex;align-items:flex-end">
                                                <img class="column p-0 is-pulled-left is-three-quarters"
                                                    src="/images/menubar-icon-lady.svg">
                                            </div>

                                        </b-sidebar>
                                    </div>
                                    <div v-if="!$store.getters['auth/isAuthenticated']">
                                        <div class="is-clickable" v-on:click="openLoginPopupModal">
                                            <img class="toggle-button-image-1" style="width: 1.5rem"
                                                src="/images/menubar-white.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>

            <!-- Hero content: will be in the middle -->
            <div class="hero-body py-0">
                <div class="container">
                    <div class="columns is-vcentered mt-auto">
                        <div class="column">
                            <h1 class="title is-size-1 has-text-white is-size-3-touch mb-2">
                                What are you searching today?!
                            </h1>
                            <form action="" v-on:submit.prevent="onSearchSubmit" ref="searchFormRef">
                                <div class="field has-addons">
                                    <div class="control is-expanded is-hidden-touch">
                                        <b-field>
                                            <b-autocomplete placeholder="iPhone 12 cover, Samsung note 9" type="search"
                                                expanded v-model="search_form.data.query" size="is-medium"
                                                custom-class="sb-input-search" field="name"
                                                @typing="getSearchSuggestions" :data="search_form.suggestion.data"
                                                :clearable="true" :confirm-keys="search_form.suggestion.confirm_keys"
                                                @keyup.native="completeSearchSuggestion" ref="searchQueryInput"
                                                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
                                            </b-autocomplete>
                                            <div class="control">
                                                <b-button native-type="submit"
                                                    class="is-primary-addon-2 search-button-color"
                                                    :loading="search_form.loader" icon-pack="fas" size="is-medium"
                                                    icon-right="search"
                                                    style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                                                </b-button>
                                            </div>
                                        </b-field>
                                    </div>
                                    <div class="control is-expanded is-hidden-desktop">
                                        <b-field>
                                            <b-autocomplete placeholder="iPhone 12 cover , Samsung note 9" type="search"
                                                expanded v-model="search_form.data.query" custom-class="sb-input-search"
                                                field="name" @typing="getSearchSuggestions"
                                                :data="search_form.suggestion.data" :clearable="true"
                                                :confirm-keys="search_form.suggestion.confirm_keys"
                                                @keyup.native="completeSearchSuggestion" ref="searchQueryInput"
                                                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">

                                            </b-autocomplete>
                                            <div class="control">
                                                <b-button native-type="submit"
                                                    class="is-primary-addon-2 search-button-color"
                                                    :loading="search_form.loader" icon-pack="fas" icon-right="search"
                                                    style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                                                </b-button>
                                            </div>
                                        </b-field>
                                    </div>
                                </div>
                            </form>
                            <div class="pt-2">
                                <b-dropdown v-model="user.selected_address" aria-role="list" :triggers="['click']"
                                    v-on:change="onSelectSavedAddress" ref="locationDropdown">
                                    <template #trigger>
                                        <b-field>
                                            <div class="control has-icons-left  is-clearfix ">
                                                <input type="text" @keydown.enter.prevent autocomplete="off"
                                                    ref="locationQuery"
                                                    v-bind:placeholder="search_form.data.location.display_name"
                                                    v-model="search_form.data.location.input_value"
                                                    class="input input-naked placeholder-color"
                                                    style="height:2rem; background: transparent; width: 20.5rem;">
                                                <span class="icon is-left" style="height:2rem;">
                                                    <img style="width:1rem" class="toggle-button-image-2"
                                                        src="/images/locationicon.svg">
                                                </span>
                                            </div>
                                        </b-field>
                                    </template>

                                    <b-dropdown-item aria-role="menu-item" :focusable="false" custom
                                        v-if="!$store.getters['auth/isAuthenticated']">
                                        <a v-on:click="openLoginPopupModal">Login </a> to view history
                                    </b-dropdown-item>

                                    <b-dropdown-item v-for="(address, index) in user.saved_addresses" :key="index"
                                        :value="address" aria-role="listitem">
                                        <div class="media">
                                            <b-icon class="media-left" icon="clock-time-three-outline"></b-icon>
                                            <div class="media-content">
                                                <h3>{{ address.nick_name }} - {{ address.address_line_1 }}</h3>
                                            </div>
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="pt-2">
                                <button class="button is-light is-primary is-small"
                                    v-on:click="openCustomSearchPopupModal">Custom
                                    Search <span class="icon pl-5 pr-5"><i class="fas fa-camera"></i></span></button>
                            </div>
                        </div>
                        <div class="column is-hidden-touch">
                            <b-carousel :indicator-inside="false">
                                <b-carousel-item v-for="(item, i) in 1" :key="i">
                                    <b-image class="image" :src="getImgUrl(i)"></b-image>
                                </b-carousel-item>
                            </b-carousel>
                        </div>
                        <div class="column mobile-banner is-hidden-desktop pb-0">
                            <b-carousel :indicator-inside="false">
                                <b-carousel-item v-for="(item, i) in 1" :key="i">
                                    <b-image class="image mobile-banner" :src="getImgUrl(i)"></b-image>
                                </b-carousel-item>
                            </b-carousel>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Hero footer: will stick at the bottom -->
            <div class="hero-foot">
                <div class="container">
                    <div class="columns is-mobile is-hidden-touch">
                        <div class="column is-narrow">
                            <div class="columns">
                                <div class="column">
                                    <a href="https://apps.apple.com/in/app/findhub/id1579928022">
                                        <img style="width: 164px;" src="/images/appstore.png">
                                    </a>
                                </div>
                                <div class="column">
                                    <a href="https://play.google.com/store/apps/details?id=app.findhub.main">
                                        <img style="width: 164px;" src="/images/playstore.png">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field has-addons is-pulled-right">
                                <p class="control">
                                    <router-link :to="{ name: 'SupportChat' }">
                                        <button
                                            class="button is-rounded is-medium has-background-dark border-none mr-1">
                                            <i class="fas fa-comments mr-1 is-pulled-left" style="color:white"></i>
                                            <h4 class="has-text-white is-size-6 is-multiline is-size-6-mobile">Chat with
                                                us</h4>
                                        </button>
                                    </router-link>
                                </p>
                                <p class="control">
                                    <button class="button is-rounded is-medium has-background-dark border-none">
                                        <h4 class="has-text-white is-size-6 is-multiline is-size-6-mobile pr-2">Whatsapp
                                        </h4>
                                        <a href="https://wa.me/919061501127">
                                            <img style="width: 27px;" class="pt-1" src="/images/whatsapp_logo.png">
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-mobile is-hidden-desktop" style="padding: 0px 24px 24px 24px;">
                        <div class="column is-narrow py-0">
                            <div class="field has-addons is-pulled-right">
                                <p class="control">
                                    <a href="https://apps.apple.com/in/app/findhub/id1579928022">
                                        <button class="button is-rounded has-background-dark border-none mr-1 px-4">
                                            <img style="width: 20px;" src="/images/app-store-logo.png">
                                        </button>
                                    </a>
                                </p>
                                <p class="control">
                                    <a href="https://play.google.com/store/apps/details?id=app.findhub.main">
                                        <button class="button is-rounded has-background-dark border-none px-4">
                                            <img style="width: 24px;" src="/images/play-store-logo.png">
                                        </button>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="column py-0">
                            <div class="field has-addons is-pulled-right">
                                <p class="control">
                                    <router-link :to="{ name: 'SupportChat' }">
                                        <button class="button is-rounded has-background-dark border-none mr-1 px-4">
                                            <i class="fas fa-comments mr-1 is-pulled-left" style="color:white"></i>
                                        </button>
                                    </router-link>
                                </p>
                                <p class="control">
                                    <button class="button is-rounded has-background-dark border-none px-4">
                                        <a href="https://wa.me/919061501127">
                                            <img style="width: 20px;" class="pt-1" src="/images/whatsapp_logo.png">
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav class="tabs has-background-dark">
                    <div class="container">
                        <footer-home></footer-home>
                    </div>
                </nav>
            </div>
        </section>
    </div>
</template>

<script>
import { SearchService } from '@/services/search.service'
import { AuthService } from '@/services/auth.service'
import LoginPopupModal from '@/components/auth/LoginPopupModal'
import { ProfileService } from '@/services/profile.service'
import { GOOGLE_API_KEY } from '@/.env'
import { MapUtil } from '@/utils/map.util'
import _ from 'lodash'
import FooterHome from '../layout/FooterHome.vue'
import CustomSearchPopupModal from '@/components/customSearch/CustomSearchPopupModal'
// import FooterHome from '../layout/FooterHome.vue'

export default {
    components: {
        FooterHome
        // FooterHome
    },
    name: 'SearchHome',
    data () {
        return {
            show_login_modal: false,
            search_form: {
                loader: false,
                suggestion: {
                    loading: false,
                    data: [],
                    confirm_keys: ['Tab', 'Enter', ' ']
                },
                data: {
                    location: {
                        latitude: null,
                        longitude: null,
                        display_name: 'Enter your location',
                        google_place_id: null
                    },
                    query: '',
                    address_id: null
                }
            },
            open: false,
            overlay: true,
            fullheight: true,
            fullwidth: false,
            right: true,

            currentLocation: { latitude: 0, longitude: 0 },
            isLoading: true,
            googleAutoComplete: null,
            // dummy
            currentMenu: { icon: 'account-group', text: 'People' },
            menus: [
                { icon: 'account-group', text: 'People' },
                { icon: 'shopping-search', text: 'Orders' },
                { icon: 'credit-card-multiple', text: 'Payments' },
                { icon: 'dolly', text: 'Logistics' },
                { icon: 'clock-check', text: 'Jobs' },
                { icon: 'cart-arrow-right', text: 'Cart' },
                { icon: 'settings', text: 'Configuration' }
            ],
            user: {
                saved_addresses: [],
                selected_address: null
            },
            getImgUrl (value) {
                return '/images/banner.svg'
            }
        }
    },
    async mounted () {
        // this.geolocation()
        await this.$loadScript(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${GOOGLE_API_KEY}`)
        // eslint-disable-next-line no-undef
        this.googleAutoComplete = new google.maps.places.Autocomplete(this.$refs.locationQuery)
        this.googleAutoComplete.setTypes(['(regions)'])

        this.googleAutoComplete.addListener('place_changed', (event) => {
            this.$log.info('Inside googleAutoComplete.place_changed')
            const placeInfo = this.googleAutoComplete.getPlace()
            if (placeInfo && _.has(placeInfo, 'address_components') && placeInfo.address_components.length > 0) {
                this.search_form.data.location.google_place_id = placeInfo.place_id
                const placeData = MapUtil.parseAddressComponents(placeInfo.address_components)
                this.search_form.data.address_id = null
                this.user.selected_address = null
                this.updateSelectedLocation(MapUtil.getLocalDisplayName(placeData), placeInfo.geometry.location.lat(), placeInfo.geometry.location.lng())
            }
        })
        var self = this

        // eslint-disable-next-line no-undef
        google.maps.event.addDomListener(this.$refs.locationQuery, 'keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault()
            }
            if (self.$refs.locationDropdown.$el.classList.contains('is-active')) {
                self.$refs.locationDropdown.toggle()
            }
        })

        if (this.$store.getters['auth/isAuthenticated']) {
            await this.onPostLoginSuccessHandler()
        }

        await this.fetchCurrentLocation()
    },
    created () {

    },
    methods: {
        openLoginPopupModal () {
            this.$buefy.modal.open({
                component: LoginPopupModal,
                parent: this,
                width: 600,
                canCancel: true,
                trapFocus: true,
                props: this.currentLocation
            })
        },
        openCustomSearchPopupModal () {
            const isAuthenticated = this.$store.getters['auth/isAuthenticated']
            this.$log.debug('is user logged in? ' + isAuthenticated)
            if (isAuthenticated) {
                this.$buefy.modal.open({
                    component: CustomSearchPopupModal,
                    parent: this,
                    width: 600,
                    canCancel: true,
                    trapFocus: true,
                    props: this.search_form.data.location
                })
            } else {
                this.openLoginPopupModal()
            }
        },
        async fetchCurrentLocation () {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    var currentLatitude = position.coords.latitude
                    var currentLongitude = position.coords.longitude
                    console.log(currentLongitude, currentLatitude)

                    // eslint-disable-next-line no-undef
                    const geocoder = new google.maps.Geocoder()

                    geocoder
                        .geocode({ location: { lat: currentLatitude, lng: currentLongitude } })
                        .then((response) => {
                            if (response.results[0]) {
                                const placeInfo = response.results[0]
                                const placeData = MapUtil.parseAddressComponents(placeInfo.address_components)
                                this.updateSelectedLocation(MapUtil.getLocalDisplayName(placeData), placeInfo.geometry.location.lat(), placeInfo.geometry.location.lng())
                            } else {
                                window.alert('No results found')
                            }
                        })
                        .catch((e) => window.alert('Geocoder failed due to: ' + e))
                })
            }
        },
        async onLogout () {
            if (this.$store.getters['auth/isAuthenticated']) {
                await AuthService.attemptLogout()
                window.location.reload()
            }
        },
        getSearchSuggestions: _.debounce(async function (text) {
            console.log('Inside getSearchSuggestion ' + text.length)

            if (!text.length || text.length < 3) {
                this.search_form.suggestion.data = []
                return
            }
            this.search_form.suggestion.loading = true
            try {
                const res = await SearchService.getSearchSuggestions({ params: { text: text } })
                // console.log(res);
                this.search_form.suggestion.data = []
                // res.data.forEach((item) => {
                //     console.log(item.name)
                //     this.search_form.suggestion.data.push(item)
                // })
                const flattenedData = res.flatMap(item => item)

                // Iterate over the flattened array of objects
                flattenedData.forEach((item) => {
                    console.log(item)
                    this.search_form.suggestion.data.push(item)
                })
                // console.log(this.search_form.suggestion.data)
                // res.forEach((item) => {

                //     this.search_form.suggestion.data.push(item);

                // });
            } catch (e) {

            } finally {
                this.search_form.suggestion.loading = false
            }
        }, 500),
        completeSearchSuggestion (event) {
            if (event.key === 'Enter') {
                this.$refs.searchFormRef.requestSubmit()
            }
        },
        async onSearchSubmit () {
            const isAuthenticated = this.$store.getters['auth/isAuthenticated']
            this.$log.debug('is user logged in? ' + isAuthenticated)
            // check if user is logged in, if not force login.

            // if loggedin, show loader call api and redirect to search product page.
            if (isAuthenticated) {
                /* Local validation */
                if (!_.isNumber(this.search_form.data.location.latitude) || !_.isNumber(this.search_form.data.location.longitude)) {
                    this.$refs.locationQuery.focus()
                    await this.fetchCurrentLocation()
                    return
                }

                if (_.isEmpty(this.search_form.data.query)) {
                    this.$refs.searchQueryInput.focus()
                    return
                }

                this.search_form.loader = true
                console.log(this.search_form.data.address_id)
                try {
                    const reqData = {
                        term: this.search_form.data.query,
                        latitude: this.search_form.data.location.latitude,
                        longitude: this.search_form.data.location.longitude,
                        google_place_id: this.search_form.data.location.google_place_id,
                        address_id: this.search_form.data.address_id,
                        type: 1
                    }
                    const response = await SearchService.save(reqData)
                    if (response?.data?.id) {
                        this.$router.push('/search-product/' + response.data.id)
                    }
                } catch (e) {
                    this.search_form.loader = false
                }
            } else {
                this.openLoginPopupModal()
            }
        },
        async onPostLoginSuccessHandler () {
            // refresh the addresses
            this.user.saved_addresses = []
            const response = await ProfileService.getAddress()
            this.user.saved_addresses.push(...response.data)
        },
        onSelectSavedAddress (value) {
            this.$log.info('Inside onSelectSavedAddress')
            this.$log.info(value)
            this.updateSelectedLocation(`${value.nick_name} - ${value.address_line_1}`, _.toNumber(value.latitude), _.toNumber(value.longitude))
            this.search_form.data.address_id = value.id
        },
        updateSelectedLocation (displayValue, latitude, longitude) {
            this.$log.info('inside updateSelectedLocation')
            this.search_form.data.location.display_name = displayValue
            this.search_form.data.location.input_value = ''
            this.search_form.data.location.latitude = latitude
            this.search_form.data.location.longitude = longitude
        }

    }
}
</script>
