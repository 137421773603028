var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "columns is-mobile is-centered" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "h1",
            {
              staticClass:
                "is-size-1 heading has-text-centered has-text-weight-bold is-size-1-mobile"
            },
            [_vm._v("404")]
          ),
          _c(
            "p",
            {
              staticClass:
                "is-size-5 has-text-centered has-text-black is-uppercase is-size-7-mobile"
            },
            [_vm._v("We are sorry, But the page you requested was not found")]
          ),
          _c(
            "div",
            { staticClass: "has-text-centered" },
            [
              _c("router-link", { attrs: { to: { name: "SearchHome" } } }, [
                _c(
                  "button",
                  { staticClass: "button is-uppercase is-rounded my-3" },
                  [_vm._v("Go home")]
                )
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }