<template>
    <div class="columns p-4 is-centered" style="background-color:#D31F3E;">
        <div class="columns is-mobile is-centered is-multiline">
            <div class="column is-narrow">
                <router-link  to="/about-us">
                    <span class="has-text-white">About Us</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/what-we-do">
                    <span class="has-text-white">What We Do</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/privacy-policy">
                    <span class="has-text-white">Privacy Policy</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/refund-policy">
                    <span class="has-text-white">Refund Policy</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/terms-and-conditions">
                    <span class="has-text-white">Terms & Conditions</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/contact-us">
                    <span class="has-text-white">Contact Us</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link :to="{name:'SupportChat'}">
                    <span class="has-text-white">Chat with us</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterMenu',
    data () {
        return {
            currentYear: new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
