var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.onLoad.loading === true
        ? _c("page-loader")
        : _c("div", [
            _c("div", { staticClass: "page-title" }, [
              _c("div", { staticClass: "title has-text-centered" }, [
                _vm._v(" My Orders ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "page-content" },
              [
                _vm._l(_vm.orders.pData.data, function(order) {
                  return _c("div", { key: order.id, staticClass: "box" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column is-half" },
                        [
                          _c(
                            "b-steps",
                            {
                              attrs: { "has-navigation": false },
                              model: {
                                value: order.deliveryStep,
                                callback: function($$v) {
                                  _vm.$set(order, "deliveryStep", $$v)
                                },
                                expression: "order.deliveryStep"
                              }
                            },
                            [
                              _c("b-step-item", {
                                attrs: { label: "Placed", icon: "cart" }
                              }),
                              _c("b-step-item", {
                                attrs: { label: "Shipped", icon: "truck" }
                              }),
                              _c("b-step-item", {
                                attrs: {
                                  label: "Delivered",
                                  icon: "map-marker-check"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "column is-half" }, [
                        _c(
                          "div",
                          { staticClass: "has-text-right" },
                          [
                            _c(
                              "h2",
                              { staticClass: "is-size-6 has-text-weight-bold" },
                              [_vm._v(" " + _vm._s(order.order_number) + " ")]
                            ),
                            _c("p", { staticClass: "is-size-7" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    order.seller !== null
                                      ? order.seller.business_name
                                      : ""
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "order-items-container" },
                              _vm._l(order.order_items, function(item) {
                                return _c("div", { key: item.id }, [
                                  _c(
                                    "span",
                                    { staticClass: "has-text-weight-bold" },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                  _vm._v(" x" + _vm._s(item.quantity) + " ")
                                ])
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "order-price-container" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "has-text-danger is-size-5" },
                                  [
                                    _vm._v(
                                      " AED " +
                                        _vm._s(
                                          _vm.formatAmount(order.grand_total)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "is-text has-text-info",
                                  size: "is-small"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.getOrderInvoice(order)
                                  }
                                }
                              },
                              [_vm._v(" Order Confirmation ")]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "is-text has-text-info",
                                  size: "is-small"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.requestInvoice(order)
                                  }
                                }
                              },
                              [_vm._v(" Request Invoice ")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                }),
                _c("b-pagination", {
                  attrs: {
                    total: this.orders.pData.meta.total,
                    "range-before": 3,
                    "range-after": 2,
                    "per-page": this.orders.pData.meta.per_page
                  },
                  on: { change: _vm.onChangePage },
                  model: {
                    value: this.orders.current_page,
                    callback: function($$v) {
                      _vm.$set(this.orders, "current_page", $$v)
                    },
                    expression: "this.orders.current_page"
                  }
                })
              ],
              2
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }