var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "profile-app" } }, [
    this.loader == true
      ? _c("div", { staticClass: "has-text-centered" }, [_c("page-loader")], 1)
      : _vm._e(),
    this.loader == false
      ? _c(
          "section",
          { staticStyle: { "overflow-y": "hidden", "overflow-x": "hidden" } },
          [
            _c(
              "h3",
              {
                staticClass:
                  "title has-text-centered has-text-weight-bold is-size-5-mobile"
              },
              [_vm._v("Profile")]
            ),
            _c("div", { staticClass: "section has-background-white box" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "h3",
                  { staticClass: "title has-text-centered is-size-5-mobile " },
                  [_vm._v("Basic Information")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "columns is-two-fifths mt-3 is-size-7-mobile"
                  },
                  [
                    _c("div", { staticClass: "column  is-6" }, [
                      _c("div", { staticClass: "control " }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "label is-size-7-mobile has-text-centered-mobile"
                          },
                          [_vm._v("Full Name")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$store.state.auth.currentUser.name,
                              expression: "$store.state.auth.currentUser.name"
                            }
                          ],
                          staticClass: "input is-size-7-mobile",
                          attrs: {
                            type: "text",
                            placeholder: "Disabled input",
                            disabled: ""
                          },
                          domProps: {
                            value: _vm.$store.state.auth.currentUser.name
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$store.state.auth.currentUser,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "control " }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "label is-size-7-mobile has-text-centered-mobile"
                          },
                          [_vm._v("Email ID")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$store.state.auth.currentUser.email,
                              expression: "$store.state.auth.currentUser.email"
                            }
                          ],
                          staticClass: "input is-size-7-mobile",
                          attrs: {
                            type: "email",
                            placeholder: "Disabled input",
                            disabled: ""
                          },
                          domProps: {
                            value: _vm.$store.state.auth.currentUser.email
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$store.state.auth.currentUser,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]
                ),
                _c("h3", { staticClass: "is-size-7-mobile" }),
                _c("div", { staticClass: "columns is-two-fifths mt-3 " }, [
                  _c("div", { staticClass: "column  is-6" }, [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "label is-size-7-mobile has-text-centered-mobile"
                        },
                        [_vm._v("Phone Number")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.$store.state.auth.currentUser.mobile_number,
                            expression:
                              "$store.state.auth.currentUser.mobile_number"
                          }
                        ],
                        staticClass: "input is-size-7-mobile ",
                        attrs: {
                          type: "text",
                          placeholder: "Disabled input",
                          disabled: ""
                        },
                        domProps: {
                          value: _vm.$store.state.auth.currentUser.mobile_number
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$store.state.auth.currentUser,
                              "mobile_number",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "has-background-white box" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "h3",
                  { staticClass: "title has-text-centered is-size-5-mobile" },
                  [_vm._v("Shipping Address")]
                ),
                _c(
                  "div",
                  { staticClass: "columns is-multiline" },
                  [
                    _c("div", { staticClass: "column is-one-third" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onClickAddAddress()
                            }
                          }
                        },
                        [_vm._m(0)]
                      )
                    ]),
                    _vm._l(_vm.addressList, function(address, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "column is-one-third" },
                        [
                          _c(
                            "div",
                            { staticClass: "tile is-ancestor is-active" },
                            [
                              _c(
                                "div",
                                { staticClass: "tile is-parent" },
                                [
                                  _c(
                                    "dic",
                                    {
                                      staticClass: "tile is-child box",
                                      staticStyle: {
                                        "min-height": "12rem",
                                        "box-shadow": "none",
                                        border: "1px solid #d8d8d8"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "pb-4" }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "has-text-weight-bold pb-2",
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [_vm._v(_vm._s(address.name))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(address.address_line_1)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(address.address_line_2)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [_vm._v(_vm._s(address.landmark))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(address.city) +
                                                "," +
                                                _vm._s(address.state) +
                                                "," +
                                                _vm._s(address.postcode)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [_vm._v(_vm._s(address.country))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "0.875rem"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(address.isd_code) +
                                                "-" +
                                                _vm._s(address.phone_number)
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("a", { attrs: { href: "" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pt-4 has-text-info is-size-7",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.onClickEditAddress(
                                                  address
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Edit")]
                                        )
                                      ]),
                                      _c("a", { attrs: { href: "" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pt-4 pl-4 has-text-info is-size-7"
                                          },
                                          [_vm._v("Set as Default")]
                                        )
                                      ]),
                                      _c("a", { attrs: { href: "" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pt-4 pl-4 has-text-danger is-size-7"
                                          },
                                          [_vm._v("Delete")]
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tile is-ancestor" }, [
      _c("div", { staticClass: "tile is-parent" }, [
        _c(
          "div",
          {
            staticClass: "tile is-child box",
            staticStyle: {
              "min-height": "13.4rem",
              "box-shadow": "none",
              border: "1px solid #d8d8d8",
              "border-style": "dotted"
            }
          },
          [
            _c("div", { staticClass: "has-text-centered pt-6" }, [
              _c("i", {
                staticClass: "fa fa-plus fa-2x",
                attrs: { "aria-hidden": "true" }
              })
            ]),
            _c("div", { staticClass: "has-text-centered pt-4 pb-6" }, [
              _c("h3", { staticClass: "is-3" }, [_vm._v("Add Address")])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }