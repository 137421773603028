import $store from '../store'
// import { AuthService } from '@/services/auth.service'

// /**
//  * Current user state initialization
//  * @WARN Must be always first in middleware chain
//  */
// export async function initCurrentUserStateMiddleware (to, from, next) {
//     const currentUserId = $store.state.user.currentUser.id
//
//     if (AuthService.hasRefreshToken() && !currentUserId) {
//         try {
//             await AuthService.debounceRefreshTokens()
//             await $store.dispatch('user/getCurrent')
//             next()
//         } catch (e) {
//             console.log(e)
//         }
//     } else {
//         next()
//     }
// }

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware (to, from, next) {
    // load auth info
    $store.dispatch('auth/getAuthInfo').then(() => {
        const isAuthRoute = to.matched.some(item => item.meta.isAuth)
        const currentUserId = $store.state.auth.currentUser && $store.state.auth.currentUser.id
        if (isAuthRoute && currentUserId) { return next() }
        if (isAuthRoute && !currentUserId) { return next({ name: 'SearchHome' }) }
        next()
    })
}

export function setPageTitleMiddleware (to, from, next) {
    const pageTitle = to.matched.find(item => item.meta.title)

    if (pageTitle) window.document.title = pageTitle.meta.title
    next()
}
