<template>
    <div id="chat-with-admin" style="overflow-x: hidden;overflow-y: hidden;">
        <div class="page-wrapper">
            <div>
                <div class="page-content">
                    <h3 class="title has-text-centered has-text-weight-bold is-size-5-mobile">Build Your Search</h3>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column pl-5 pb-0">
                                    <h6 class="is-6 has-text-grey-dark">Market</h6>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div class="field">
                                        <div class="control">
                                            <div class="select is-rounded is-medium">
                                                <select>
                                                    <option>Select dropdown</option>
                                                    <option>With options</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-narrow">
                                    <div class="control">
                                        <button class="button is-primary is-primary-addon-1 is-medium"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column pl-5 pb-0">
                                    <h6 class="is-6 has-text-grey-dark">Category</h6>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div class="field">
                                        <div class="control">
                                            <div class="select is-rounded is-medium">
                                                <select>
                                                    <option>Select dropdown</option>
                                                    <option>With options</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-narrow">
                                    <div class="control">
                                        <button class="button is-primary is-primary-addon-1 is-medium"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column pl-5 pb-0">
                                    <h6 class="is-6 has-text-grey-dark">Components</h6>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div class="field">
                                        <div class="control">
                                            <div class="select is-rounded is-medium">
                                                <select>
                                                    <option>Select dropdown</option>
                                                    <option>With options</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-narrow">
                                    <div class="control">
                                        <button class="button is-primary is-primary-addon-1 is-medium"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column pl-5 pb-0">
                                    <h6 class="is-6 has-text-grey-dark">Type</h6>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div class="field">
                                        <div class="control">
                                            <div class="select is-rounded is-medium">
                                                <select>
                                                    <option>Select dropdown</option>
                                                    <option>With options</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-narrow">
                                    <div class="control">
                                        <button class="button is-primary is-primary-addon-1 is-medium"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column pl-5 pb-0">
                                    <h6 class="is-6 has-text-grey-dark">Details</h6>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <div class="control">
                                            <textarea class="textarea" placeholder="Enter Details Here"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns mb-6">
                        <div class="column is-3 is-offset-9 is-narrow">
                            <div class="file is-pulled-right">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="resume">
                                    <span class="file-cta has-background-dark has-text-white has-text-right is-rounded">
                                        <span class="file-label">
                                            Attach File/Photo
                                        </span>
                                        <span class="file-icon">
                                            <i class="fas fa-paperclip ml-3"></i>
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="columns mt-4">
                        <div class="column has-text-centered">
                            <div class="control">
                                <button class="button is-primary is-primary-addon-1 is-medium">Search<i class="fas fa-search ml-3"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CustomSearch',
    data: function () {
        return {
        }
    }
}
</script>
