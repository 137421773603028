<template>
    <div id="profile-app">
        <div v-if="this.loader == true" class="has-text-centered">
            <page-loader></page-loader>
        </div>
        <section style="overflow-y: hidden; overflow-x: hidden;" v-if="this.loader == false">
            <h3 class="title has-text-centered has-text-weight-bold is-size-5-mobile">Profile</h3>
            <div class="section has-background-white box">
                <div class="container">
                    <h3 class="title has-text-centered is-size-5-mobile ">Basic Information</h3>
                    <div class="columns is-two-fifths mt-3 is-size-7-mobile">
                        <div class="column  is-6">
                            <div class="control ">
                                <label class="label is-size-7-mobile has-text-centered-mobile">Full Name</label>
                                <input class="input is-size-7-mobile" type="text" v-model='$store.state.auth.currentUser.name'  placeholder="Disabled input" disabled >
                            </div>
                        </div>
                        <div class="column">
                            <div class="control ">
                                <label class="label is-size-7-mobile has-text-centered-mobile">Email ID</label>
                                <input class="input is-size-7-mobile" type="email" v-model='$store.state.auth.currentUser.email'   placeholder="Disabled input" disabled>
                            </div>
                        </div>
                    </div>
                    <h3 class="is-size-7-mobile">
                        <!-- IS AUTHENTICATED: -->
                        <!-- {{ $store.getters["user/isAuthenticated"] }} -->
                    <!-- {{ $store.state.user.currentUser }} -->
                    </h3>
                    <div class="columns is-two-fifths mt-3 ">
                        <div class="column  is-6">
                            <div class="control">
                                <label class="label is-size-7-mobile has-text-centered-mobile">Phone Number</label>
                                <input class="input is-size-7-mobile " type="text" v-model='$store.state.auth.currentUser.mobile_number'  placeholder="Disabled input"
                                disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="has-background-white box">
                <div class="container">
                    <h3 class="title has-text-centered is-size-5-mobile">Shipping Address</h3>
                    <div class="columns is-multiline">
                        <div class="column is-one-third">
                            <a href="" v-on:click.prevent="onClickAddAddress()">
                            <div class="tile is-ancestor">
                                <div class="tile is-parent">
                                    <div class="tile is-child box" style="min-height: 13.4rem; box-shadow: none; border: 1px solid #d8d8d8; border-style: dotted;
">
                                        <div class="has-text-centered pt-6">
                                            <i class="fa fa-plus fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div class="has-text-centered pt-4 pb-6">
                                            <h3 class="is-3">Add Address</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div class="column is-one-third" v-for="(address,index) in addressList" :key="index">
                            <div class="tile is-ancestor is-active">
                                <div class="tile is-parent" >
                                    <dic class="tile is-child box" style="min-height: 12rem; box-shadow: none; border: 1px solid #d8d8d8;">
                                        <div class="pb-4">
                                            <p class="has-text-weight-bold pb-2" style="font-size: 0.875rem;">{{address.name}}</p>
                                            <p style="font-size: 0.875rem;">{{address.address_line_1}}</p>
                                            <p style="font-size: 0.875rem;">{{address.address_line_2}}</p>
                                            <p style="font-size: 0.875rem;">{{address.landmark}}</p>
                                            <p style="font-size: 0.875rem;">{{address.city}},{{address.state}},{{address.postcode}}</p>
                                            <p style="font-size: 0.875rem;">{{address.country}}</p>
                                            <p style="font-size: 0.875rem;">{{address.isd_code}}-{{address.phone_number}}</p>
                                        </div>
                                        <a href=""><span class="pt-4 has-text-info is-size-7" v-on:click.prevent="onClickEditAddress(address)">Edit</span></a>
                                        <a href=""><span class="pt-4 pl-4 has-text-info is-size-7">Set as Default</span></a>
                                        <a href=""><span class="pt-4 pl-4 has-text-danger is-size-7">Delete</span></a>
                                    </dic>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// import { UsersService } from '@/services/users.service'
import AddAddressPopupModal from '@/components/profile/AddAddressPopupModal'
import EditAddressPopupModal from '@/components/profile/EditAddressPopupModal'
import { ProfileService } from '@/services/profile.service'
export default {
    name: 'Profile',
    data: function () {
        return {
            loader: true,
            address: [],
            inputs: [
                {
                    address: ''
                }
            ]
        }
    },
    components: {

    },
    mounted () {
        // UsersService.getCurrent()
        this.getAddress()
    },
    methods: {
    //     add (index) {
    //         this.inputs.push({ address: '' })
    //     },
    //     remove (index) {
    //         this.inputs.splice(index, 1)
    //     }
        async getAddress () {
            try {
                const response = await ProfileService.getAddress()
                this.addressList = response.data
                // console.log(this.addressList)
            } catch (e) {
                this.$log.error('Error while fetching addresses', e)
            } finally {
                this.loader = false
            }
        },
        onClickEditAddress (address) {
            this.$buefy.modal.open({
                parent: this,
                component: EditAddressPopupModal,
                trapFocus: true,
                props: address
            })
        },
        onClickAddAddress () {
            this.$buefy.modal.open({
                parent: this,
                component: AddAddressPopupModal,
                trapFocus: true
                // onCancel () {
                //     // window.location.reload()
                //     console.log('address list should be shown')
                // }
            })
        }
    }
}
</script>

<style scoped>

</style>
