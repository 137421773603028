<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="columns is-mobile is-centered">
                <div class="column">
                    <h1 class="is-size-1 heading has-text-centered has-text-weight-bold is-size-1-mobile">404</h1>
                    <p class="is-size-5 has-text-centered has-text-black is-uppercase is-size-7-mobile">We are sorry, But the page you requested was not found</p>
                    <div class="has-text-centered">
                        <router-link :to="{ name: 'SearchHome'}">
                            <button class="button is-uppercase is-rounded my-3">Go home</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Page404'
}
</script>

<style scoped>

</style>
