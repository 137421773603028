var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.onPageLoad.loading === true
        ? _c("page-loader", {
            attrs: { "loading-text": "Processing your payment..." }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }