var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "has-text-centered m-6" },
    [
      _c("clip-loader", { attrs: { loading: true, color: "#999" } }),
      _c("strong", [_vm._v(" " + _vm._s(_vm.loadingText) + " ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }