/**
 * import and init global mixins
 */

import Vue from 'vue'

import currentUser from '../mixins/currentUser'
import jumpTo from '../mixins/jumpTo'
import formatDateTime from '../mixins/formatDateTime'
import PageLoader from '@/components/misc/PageLoader'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import FooterMenu from '@/views/layout/FooterMenu'
import { FileuploadService } from '@/services/fileupload.service'
import _ from 'lodash'

Vue.mixin(currentUser)
Vue.mixin(jumpTo)
Vue.mixin(formatDateTime)

Vue.mixin({
    methods: {
        isoToLocale (dateStr) {
            // console.log("Converting " + dateStr  + " to " + DateTime.fromISO(dateStr).toLocaleString(DateTime.DATETIME_MED));
            return DateTime.fromISO(dateStr).toLocaleString(DateTime.DATETIME_MED)
        },
        formatAmount (amount) {
            return numeral(amount).format('0,0.00[00]')
        },
        imageUrl (fileId) {
            if (fileId == null) {
                return '/images/seller-default-logo.png'
            } else if (_.startsWith(fileId, 'http')) {
                return fileId
            } else {
                return FileuploadService.getFileUrl(fileId)
            }
        }

    }
})

// global components

Vue.component('page-loader', PageLoader)
Vue.component('footer-menu', FooterMenu)
