export default {
    AUTH_SUCCESS (state, user) {
        state.currentUser = user
    },
    AUTH_LOGOUT (state, user) {
        state.currentUser = null
    },
    SET_USER (state, user) {
        state.currentUser = user
    }
}
