import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class LoginService extends BaseService {
    static async saveLogin (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/auth/initial_auth`, reqData)
        return response
    }

    static async saveOtp (reqData) {
        const response = await axios.put(`${API_URL}/api/customer/auth/validate_auth`, reqData)
        return response
    }

    static async saveCustomer (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/auth/signup_request`, reqData)
        return response
    }

    static async valiadteNumber (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/auth/validate_number`, reqData)
        return response
    }
}
