import { UsersService } from '@/services/users.service'
import { AuthService } from '@/services/auth.service'
// import Vue from 'vue'

export default {
    getCurrent ({ commit }) {
        return UsersService.getCurrent()
            .then(user => commit('SET_CURRENT_USER', user.data))
            .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
    async getAuthInfo ({ commit }) {
        try {
            const user = await AuthService.getUserInfo()
            commit('SET_USER', user)
        } catch (e) {
            this._vm.$log.debug('User not logged in.')
        }
    }
}
