var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column", staticStyle: { "background-color": "#F1F1F1" } },
    [
      _c("div", { staticClass: "container " }, [
        _c(
          "h2",
          {
            staticClass:
              "title has-text-centered has-text-weight-bold is-size-5-mobile font-color"
          },
          [_vm._v(" Congratulations,you have successfully registered ")]
        ),
        _c(
          "h2",
          { staticClass: "subtitle has-text-centered font-color mt-3" },
          [
            _vm._v(
              "Your request is being processed by our administration team.You will receive an email notification shortly"
            )
          ]
        ),
        _c("div", [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns is-mobile is-centered" }, [
              _c("div", { staticClass: "column is-half-desktop" }, [
                _c(
                  "div",
                  { staticClass: "has-text-centered" },
                  [
                    _c("router-link", { attrs: { to: "/" } }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button is-primary is-primary-addon-1 is-size-7-mobile "
                        },
                        [_vm._v(" Back to Home ")]
                      )
                    ])
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._m(0)
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "columns is-mobile is-centered has-text-centered-desktop"
      },
      [
        _c("div", { staticClass: "column is-half" }, [
          _c("img", { attrs: { src: "/images/confirmation-upgrade.png" } })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }