<template>
    <div>
        <div class="columns pt-3 mb-0 is-centered is-hidden-touch">
            <div class="column is-narrow">
                <router-link to="/about-us">
                    <span class="has-text-white">About Us</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/what-we-do">
                    <span class="has-text-white">What We Do</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/join-as-seller">
                    <span class="has-text-white">Join as a Seller</span>
                </router-link>
            </div>
            <div class="column is-narrow">
                <router-link to="/contact-us">
                    <span class="has-text-white">Contact Us</span>
                </router-link>
            </div>
        </div>
        <div class="columns p-3 is-centered is-hidden-desktop" style="display: inline-flex;">
            <div class="column is-narrow px-0">
                <router-link to="/about-us">
                    <span class="has-text-white">About Us</span>
                </router-link>
            </div>
            <div class="column is-narrow px-0">
                <router-link to="/what-we-do">
                    <span class="has-text-white">What We Do</span>
                </router-link>
            </div>
            <div class="column is-narrow px-0">
                <router-link to="/join-as-seller">
                    <span class="has-text-white">Join as a Seller</span>
                </router-link>
            </div>
            <div class="column is-narrow px-0">
                <router-link to="/contact-us">
                    <span class="has-text-white">Contact Us</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterHome',
    data () {
        return {
            currentYear: new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
