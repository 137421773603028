import { REFRESH_DATA } from './mutation-types'
import _ from 'lodash'

export default {
    [REFRESH_DATA]: (state, data) => {
        _.forEach(data, (data, classifier) => {
            state.data[classifier] = {}
            state.data[classifier].data = data
            state.data[classifier].last_refresh_time = Date.now()
        })
    }
}
