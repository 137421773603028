var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.onLoad.loading === true
        ? _c("page-loader")
        : _c("div", [
            _vm.order.status !== 3
              ? _c("div", [
                  _vm._v(
                    " There has been an error while confirming your order. Please check orders page after a while. "
                  )
                ])
              : _vm.order.status === 3
              ? _c("div", { staticClass: "columns is-mobile is-centered" }, [
                  _c("div", { staticClass: "column order-confirmantion" }, [
                    _c("img", {
                      attrs: {
                        "data-v-30de49ae": "",
                        height: "356",
                        width: "854",
                        src: "/images/order-confirm.png"
                      }
                    }),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "title has-text-centered has-text-weight-semibold"
                      },
                      [_vm._v("We've received your order!")]
                    ),
                    _c("p", { staticClass: "subtitle has-text-centered" }, [
                      _vm._v(" Thank you for shopping with us. "),
                      _c("br"),
                      _vm._v(
                        "Your Order ID is " +
                          _vm._s(_vm.order.order_number) +
                          ". "
                      ),
                      _c("br"),
                      _vm._v(
                        "Our support team will contact you to verify the order. "
                      ),
                      _c("br"),
                      _vm._v(
                        "Once verified we will provide you with payment and delivery options. "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "has-text-centered" },
                      [
                        _c("router-link", { attrs: { to: "#" } }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button is-rounded mx-2 has-text-white has-background-grey-darker"
                            },
                            [_vm._v("Track my Order")]
                          )
                        ]),
                        _c(
                          "router-link",
                          { attrs: { to: { name: "SearchHome" } } },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button is-rounded is-primary is-primary-addon-1 mx-2"
                              },
                              [
                                _c("i", { staticClass: "fa fa-home" }, [
                                  _c("span", { staticClass: "ml-2" })
                                ]),
                                _vm._v("Back to Home ")
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }