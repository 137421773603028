<template>
    <page-loader v-if="meta.actions.onChatLoad.loading === true"></page-loader>
    <div v-else>
        <div class="sp-pc-nochat-actions pt-4" v-if="chat.thread == null" >
            <b-button type="is-primary" class="is-primary-addon-1" :loading="meta.actions.onChatStart.loading" v-on:click.prevent="startChat">Chat with Seller</b-button>
        </div>
        <div v-else>
            <div class="has-background-white p-5 chat-messages-container" ref="chatMessagesContainer">
                <perfect-scrollbar :options="{suppressScrollX: true}">
                    <div v-if="chat.messages.data.length > 0">
                        <div v-for="(message,index) in chat.messages.data.slice().reverse()" v-bind:key="index">
                            <div v-if="message.user.type=='CUSTOMER'">
                                <div class="columns">
                                    <div class="column is-10 is-offset-2">
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFE5C6;" v-if="message.type ==1">
                                            <p> {{ message.body }}</p>
                                        </div>
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFE5C6;" v-else>
                                            <figure class="image is-96x96">
                                                <img :src="imageUrl(message.body)">
                                            </figure>
                                        </div>
                                        <div class="cmc_user-footer has-text-right">
                                            <i class="fas fa-user"></i> Me
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="message.user.type=='SELLER'">
                                <div class="columns">
                                    <div class="column is-10">
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFD8D8;" v-if="message.type ==1">
                                            <p> {{ message.body }}</p>
                                        </div>
                                        <div class="p-4 border-top-left"
                                             style="background-color: #FFD8D8;" v-else>
                                            <figure class="image is-96x96">
                                                <img :src="imageUrl(message.body)">
                                            </figure>
                                        </div>
                                        <div class="cmc_user-footer">
                                            <i class="fas fa-user"></i> Seller
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="" style="position: absolute; width:320px; bottom: 0px;">
                <form action="" v-on:submit.prevent="onChatSubmit">
                    <b-field>
                        <b-input placeholder="Type your replies here"
                                 type="search"
                                 custom-class="chat-input-box"
                                 expanded
                                 v-model="chatForm.data.body"
                        >
                        </b-input>
                        <p class="control">
                            <b-button @click="openImageuploadPopupModal"
                                      class="button chat-input-button"
                                      icon-pack="fas"
                                      icon-right="camera"
                                      size="is-medium"
                            >
                            </b-button>
                            <b-button native-type="submit"
                                      class="button chat-input-button"
                                      icon-pack="fas"
                                      icon-right="paper-plane"
                                      size="is-medium"
                                      :loading="chatForm.loading"
                                      :disabled="chatForm.loading"
                            >
                            </b-button>
                        </p>
                    </b-field>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { EnquiryChatService } from '@/services/enquiry-chat.service'
import Echo from 'laravel-echo'
import { API_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from '@/.env'
import axios from 'axios'
import ImageUploadPopupModal from '@/components/search/ImageUploadPopupModal'

export default {
    name: 'ProductChat',
    props: ['sellerResponse', 'term'],
    data: function () {
        return {
            meta: {
                actions: {
                    onChatLoad: {
                        loading: true
                    },
                    onChatStart: {
                        loading: false
                    }
                }
            },
            chat: {
                thread: null,
                messages: {
                    data: []
                },
                pusher: null,
                echo: null
            },
            chatForm: {
                data: {
                    thread_id: null,
                    body: ''
                },
                loading: false
            }
        }
    },
    async mounted () {
        this.chatForm.data.thread_id = this.sellerResponse.thread_id

        if (this.sellerResponse.thread_id !== null) {
            await this.init()
        }
    },
    methods: {
        init: async function () {
            this.meta.actions.onChatLoad.loading = true
            this.chat.thread = await EnquiryChatService.getActiveThread({ response_id: this.sellerResponse.id })
            this.chat.messages = await EnquiryChatService.getThreadMessages(this.chat.thread.id)
            this.chatForm.data.thread_id = this.chat.thread.id
            // echo init
            // setup pusher and echo.
            this.chat.pusher = require('pusher-js')
            this.chat.echo = new Echo({
                broadcaster: 'pusher',
                key: PUSHER_APP_KEY,
                cluster: PUSHER_APP_CLUSTER,
                forceTLS: true,
                authEndpoint: API_URL + '/api/customer/auth/ws',
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            // eslint-disable-next-line no-undef
                            axios.post(API_URL + '/api/customer/broadcasting/auth', {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    // eslint-disable-next-line standard/no-callback-literal
                                    callback(false, response.data)
                                })
                                .catch(error => {
                                    // eslint-disable-next-line standard/no-callback-literal
                                    callback(true, error)
                                })
                        }
                    }
                }
            })

            this.chat.echo.private(`f.enquiry-chat.${this.chat.thread.id}`)
                .listen('EnquiryChatNewMessage', (e) => {
                    if (e.message.user.type === 'SELLER') {
                        this.chat.messages.data.unshift(e.message)
                    }
                })

            this.meta.actions.onChatLoad.loading = false
        },
        onChatSubmit: async function () {
            var formdata = this.chatForm.data
            if (!formdata.body.length > 0) {
                return
            }
            try {
                this.chatForm.loading = true
                const message = await EnquiryChatService.postNewMessage(this.chatForm.data)
                this.chat.messages.data.unshift(message)
                this.chatForm.data.body = ''
            } finally {
                this.chatForm.loading = false
            }
        },
        startChat: async function () {
            this.meta.actions.onChatStart.loading = true
            await this.init()
            this.meta.actions.onChatStart.loading = false
        },
        openImageuploadPopupModal () {
            this.$buefy.modal.open({
                component: ImageUploadPopupModal,
                parent: this,
                width: 500,
                canCancel: true,
                trapFocus: true,
                props: this.chatForm.data,
                events: {
                    close: async (guid) => {
                        const data = {
                            thread_id: this.chatForm.data.thread_id,
                            body: guid,
                            type: 2
                        }
                        try {
                            const message = await EnquiryChatService.postNewMessage(data)
                            this.chat.messages.data.unshift(message)
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }
            })
        }
    },
    watch: {
        sellerResponse: async function (sellerResponse, oldSellerResponse) {
            console.log('SellerResponse modified to')
            console.log(sellerResponse)
            if (oldSellerResponse.id === sellerResponse.id) {
                return
            }
            try {
                this.chat.echo.disconnect()
            } catch (e) {
                this.$log.error(e)
            }

            if (this.sellerResponse.thread_id !== null) {
                await this.init()
            }
        }
    }
}
</script>

<style scoped>
.ps {
    height: 544px;
}
</style>
