<template>
    <div class=""  >

         <div class="columns is-mobile is-centered has-text-centered-desktop">
                    <div class="column center">
                        <h3 class="title is-4 mt-3 has-text-black has-text-weight-bold">
                            My Order
                         </h3>
                    </div>
         </div>

        <section class="is-fullwidth is-fullheight">
            <div class="columns m-6  ">
                <div class="column box ">
                    <div class="columns v-centered">
                    <div class="column is-11  is-mobile is-centered">
                        <div class=" column has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10">
                            <h3 class="subtitle has-text-weight-bold  ">Iphone 12 Pro Max</h3>
                            <h3 class="subtitle has-text-weight-bold ">XYZ</h3>
                            <h3 class="font-color has-text-weight-bold">$ 1200</h3>

                        </div>

                    </div>
                    <div class="columns v-centered">
                    <div class="column is-1 mt-6 has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10">
                            <figure class="image is-32x32 ml-3">
                                <img  src="/images/enquiry-mobile.png">
                            </figure>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
            <div class="columns m-6  ">
                <div class="column box ">
                    <div class="columns v-centered">
                    <div class="column is-11  is-mobile is-centered">
                        <div class=" column has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10">
                            <h3 class="subtitle has-text-weight-bold  ">Iphone 12 Pro Max</h3>
                            <h3 class="subtitle has-text-weight-bold ">XYZ</h3>
                            <h3 class="font-color has-text-weight-bold">$ 1200</h3>

                        </div>

                    </div>
                    <div class="columns v-centered">
                    <div class="column is-1 mt-6 has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10">
                            <figure class="image is-32x32 ml-3">
                                <img  src="/images/enquiry-mobile.png">
                            </figure>
                        </div>
                    </div>
                    </div>

                </div>
            </div>

            <div class="columns is-centered">
                <div class="column is-one-quarter">
                    <router-link to="/payment">
                        <button class="button is-dark is-centered is-rounded " style="background: linear-gradient(#CE263F,#EA632B);"><span class=" is-centered mt-1 p-6">Proceed to Pay</span></button>
                    </router-link>
                </div>
            </div>
        </section>
    </div>

</template>

<script>

export default {
    name: 'OrderTrack'

}
</script>

<style scoped>
.container {
  width: 50%;
  font-family: 'Roboto', sans-serif;
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  position: relative;
  list-style: none;
  float: left;
  width: 33.33%;
  text-align: center;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #FF9100;
  line-height: 39px;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:before {
  background:#00E676;
  content: "✔";

}

.progressbar li.active + li:after {
  background: #00E676;
}

</style>
