<template>
    <div class="modal-content">
        <div class="has-background-white box w-6 ">
            <div class="p-6">
                <h4 class="title has-text-centered has-text-weight-bold is-size-5-mobile">Build Your Search</h4>
                <div class="columns">
                    <div class="column">
                        <div class="columns">
                            <div class="column pl-5 pb-0">
                                <h6 class="is-6 has-text-grey-dark">Product Name</h6>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <b-input placeholder="Product Name" rounded v-model="term"></b-input>
                                    <span style="color: red" v-if="errors.term" class="error">{{ errors.term[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="columns">
                            <div class="column pl-5 pb-0">
                                <h6 class="is-6 has-text-grey-dark">Market</h6>
                            </div>
                        </div>
                        <div class="columns is-mobile">
                            <div class="column">
                                <div class="select is-rounded">
                                    <select @change="fetchChildCategory($event.target.selectedIndex)" v-model="category1_id">
                                        <option
                                            v-for="option in categoryList"
                                            :value="option.category_id"
                                            :key="option.name">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                    <span style="color: red" v-if="errors.category1_id" class="error">{{ errors.category1_id[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="columns">
                            <div class="column pl-5 pb-0">
                                <h6 class="is-6 has-text-grey-dark">Category</h6>
                            </div>
                        </div>
                        <div class="columns is-mobile">
                            <div class="column">
                                <div class="select is-rounded">
                                    <select v-model="category2_id">
                                        <option v-if="category1_id == null">Select dropdown</option>
                                        <option v-else
                                            v-for="option in categoryList[index].child_category"
                                            :value="option.category_id"
                                            :key="option.name">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                    <span style="color: red" v-if="errors.category2_id" class="error">{{ errors.category2_id[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns" v-if="image1==null || image2==null || image3==null">
                    <div class="column">
                        <b-field class="file">
                            <b-upload v-model="files.file_data" v-on:input="handleFileUploads">
                                <span class="file-cta has-background-dark has-text-white has-text-right is-rounded">
                                    <span class="file-label">
                                        Attach File/Photo
                                    </span>
                                    <span class="file-icon">
                                        <i class="fas fa-paperclip ml-3"></i>
                                    </span>
                                </span>
                            </b-upload>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column" v-if="image1 !== null">
                        <div class="columns">
                            <div class="column">
                                <div class="image">
                                    <figure class="image is-square">
                                        <img :src="imageUrl(this.image1)">
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-button type="is-danger" size="is-small" outlined label="Delete" v-on:click="onDelete('image1')"/>
                            </div>
                        </div>
                    </div>
                    <div class="column" v-if="image2 !== null">
                        <div class="columns">
                            <div class="column">
                                <div class="image">
                                    <figure class="image is-square">
                                        <img :src="imageUrl(this.image2)">
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-button type="is-danger" size="is-small" outlined label="Delete" v-on:click="onDelete('image2')"/>
                            </div>
                        </div>
                    </div>
                    <div class="column" v-if="image3 !== null">
                                <div class="columns">
                            <div class="column">
                                <div class="image">
                                    <figure class="image is-square">
                                        <img :src="imageUrl(this.image3)">
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-button type="is-danger" size="is-small" outlined label="Delete" v-on:click="onDelete('image3')"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns mt-4">
                    <div class="column has-text-centered">
                        <div class="control">
                            <b-button class="button is-primary is-primary-addon-1 is-medium" size="is-medium"
                                    :loading="loader" v-on:click="onSearchSubmit" icon-pack="fas"
                                    icon-right="search">
                                Search
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SearchService } from '@/services/search.service'
import { FileuploadService } from '@/services/fileupload.service'
export default {
    name: 'CustomSearchPopupModal',
    props: ['latitude', 'longitude', 'google_place_id'],
    data: function () {
        return {
            loader: false,
            errors: [],
            category1_id: null,
            category2_id: null,
            term: null,
            categoryList: null,
            index: null,
            image1: null,
            image2: null,
            image3: null,
            files: {
                file_id: null,
                file_data: null
            }
        }
    },
    async mounted () {
        const response = await SearchService.getCategoryTree()
        this.categoryList = response
    },
    methods: {
        fetchChildCategory (selectedIndex) {
            this.index = selectedIndex
        },
        async handleFileUploads () {
            this.files.file_id = null
            if (this.files.file_data !== null) {
                const response = await FileuploadService.uploadFile(this.files.file_data)
                this.files.file_id = response.guid
            }
            if (this.image1 == null) {
                this.image1 = this.files.file_id
            } else if (this.image2 == null) {
                this.image2 = this.files.file_id
            } else {
                this.image3 = this.files.file_id
            }
        },
        async onSearchSubmit () {
            this.loader = true
            try {
                const reqData = {
                    term: this.term,
                    latitude: this.latitude,
                    longitude: this.longitude,
                    google_place_id: this.google_place_id,
                    category1_id: this.category1_id,
                    category2_id: this.category2_id,
                    image1: this.image1,
                    image2: this.image2,
                    image3: this.image3,
                    type: 2
                }
                console.log(reqData)
                const response = await SearchService.save(reqData)
                if (response?.data?.id) {
                    this.$router.push('/search-product/' + response.data.id)
                }
                this.$emit('close')
            } catch (e) {
                this.loader = false
                this.errors = e.response.data.errors
            }
        },
        onDelete (image) {
            switch (image) {
            case 'image1':
                this.image1 = null
                break
            case 'image2':
                this.image2 = null
                break
            case 'image3':
                this.image3 = null
                break
            }
        }
    }
}
</script>
