var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "overflow-x": "hidden", "overflow-y": "hidden" },
      attrs: { id: "what-we-do" }
    },
    [
      _c("div", { staticClass: "is-hidden-desktop" }, [
        _c("div", { staticClass: "container is-max-widescreen" }, [
          _c(
            "div",
            {
              staticClass:
                "pt-4 column is-one-fifth-desktop is-one-fifth-tablet is-two-fifths-mobile"
            },
            [
              _c("router-link", { attrs: { to: { name: "SearchHome" } } }, [
                _c("img", { attrs: { src: "/images/findhub-logo_1.png" } })
              ])
            ],
            1
          ),
          _c(
            "h3",
            {
              staticClass:
                "title my-4 is-size-4 is-size-5-mobile is-size-4-tablet has-text-centered"
            },
            [_vm._v("What We Do")]
          ),
          _vm._m(0)
        ])
      ]),
      _c("div", { staticClass: "is-hidden-touch" }, [
        _c("div", { staticClass: "container is-max-widescreen" }, [
          _c(
            "div",
            {
              staticClass:
                "pt-4 column is-one-fifth-desktop is-one-fifth-tablet is-two-fifths-mobile"
            },
            [
              _c("router-link", { attrs: { to: { name: "SearchHome" } } }, [
                _c("img", { attrs: { src: "/images/findhub-logo.svg" } })
              ])
            ],
            1
          ),
          _c(
            "h3",
            {
              staticClass:
                "title my-4 is-size-4 is-size-5-mobile is-size-4-tablet has-text-centered"
            },
            [_vm._v("What We Do")]
          ),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3)
        ])
      ]),
      _c("nav", { staticClass: "tabs has-background-dark" }, [
        _c("div", { staticClass: "container" }, [_c("footer-home")], 1)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column mb-6" }, [
        _c(
          "div",
          {
            staticClass:
              "column is-primary-gradient has-text-justified px-6 my-3 is-hidden-mobile",
            staticStyle: {
              height: "auto",
              "border-radius": "3px",
              display: "flex",
              "justify-content": "space-around",
              "align-items": "center"
            }
          },
          [
            _c("p", { staticClass: "has-text-white" }, [
              _vm._v(
                "Findhub is an online e-commerce search engine that helps you search for you products and services within the UAE. We aggregate products and services on a single platform, making search and access to any and all products and services easy and convenient"
              )
            ]),
            _c("img", {
              staticStyle: { "margin-top": "-60px", height: "140px" },
              attrs: { src: "/images/Whatwedo-banner.svg" }
            })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "column is-primary-gradient has-text-justified px-3 mb-3 mt-5 is-hidden-tablet",
            staticStyle: {
              height: "auto",
              "border-radius": "3px",
              display: "grid",
              "justify-items": "center",
              "align-items": "center"
            }
          },
          [
            _c("img", {
              staticStyle: { "margin-top": "-60px", height: "140px" },
              attrs: { src: "/images/Whatwedo-banner.svg" }
            }),
            _c("p", { staticClass: "has-text-white" }, [
              _vm._v(
                "Findhub is an online e-commerce search engine that helps you search for you products and services within the UAE. We aggregate products and services on a single platform, making search and access to any and all products and services easy and convenient"
              )
            ])
          ]
        ),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "media-left" }, [
                _c("div", { staticClass: "image is-64x64" }, [
                  _c("img", { attrs: { src: "/images/what-we-do-icon2.png" } })
                ])
              ]),
              _c("div", { staticClass: "media-content" }, [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "box is-pulled-left chat-box border-top-left",
                      staticStyle: { "background-color": "#FFD8D8" }
                    },
                    [
                      _c("p", [
                        _vm._v("Hey Sam, How have you been??"),
                        _c("br"),
                        _vm._v("Long time huh??")
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Hi Abu, I am fine dude. Great to see you.. Yeah that’s a long time. But I am really sorry man, I am in a hurry. Shall catch up with you tomorrow.."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v("Is everything alright?? Why in a hurry?")
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Actually, I am in search of an Air Fryer from the last few days... Day and night I am spending in the scour. Do you have any idea where can I find it??"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Chill Dude... Is that all???? Haven’t you heard about the FindHub app?? Let me just show you"
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column p-6" }, [
            _c(
              "div",
              {
                staticClass: "box",
                staticStyle: {
                  "background-color": "transparent",
                  "box-shadow": "none",
                  border: "1px solid red"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "media", attrs: { "data-aos": "flip-up" } },
                  [
                    _c("div", { staticClass: "media-content" }, [
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "has-text-weight-medium has-text-danger"
                          },
                          [
                            _vm._v(
                              "Findhub is that onestop destination where you will get all that you want without distress"
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "media-right" }, [
                      _c(
                        "figure",
                        {
                          staticClass: "image",
                          staticStyle: { width: "5rem" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/what-we-do-mobile-ui-image.png"
                            }
                          })
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "I don’t believe these online platforms Abu. I won’t get a good price there."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Seems like you have had a bad experience in the online market."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "That sounds good. But ain’t this going to take time? It might take quite a number of days before I get the item..."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "No Sam, Findhub shows you the nearest location available so that you get the required item at the earliest possible."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Hmmm.... But what if the price is unacceptable?"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "They provide you options to negotiate the price as well. You can settle for the best price possible."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "All that is fine Abu. But there is a catch, I am not looking for any Air Fryer. My specifications are a bit different. No online platforms give us the privilege to be versed on these."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "That’s exactly why I say, Findhub is exceptional. Through Findhub, you cancheck it all directly with the seller."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Do you mean, I can actually chat with the seller?"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [_c("p", [_vm._v("Yes, you can!!!")])]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "What if they continue to nag me later, to get more of their products???"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Don’t worry on that Sam!! Findhub makes sure that the customer details are not disclosed to the sellers."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Wow...that’s just amazing. But can we order the products in bulk as well????"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Yes, you can order the products in bulk also."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Sounds Excellent then.. Never before did I come across such a groundbreaking platform. I am waiting to get started."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "media", attrs: { "data-aos": "fade-left" } },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Yes, Findhub for sure is one of a kind. Even the registration process is simple. Let me help you Sign up...By the way Sam, you owe me a treat"
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column mb-6" }, [
        _c(
          "div",
          {
            staticClass:
              "column is-primary-gradient has-text-justified px-6 is-hidden-mobile",
            staticStyle: {
              height: "auto",
              "border-radius": "3px",
              display: "flex",
              "justify-content": "space-around",
              "align-items": "center"
            }
          },
          [
            _c("p", { staticClass: "has-text-white" }, [
              _vm._v(
                "Findhub is an online e-commerce search engine that helps you search for you products and services within the UAE. We aggregate products and services on a single platform, making search and access to any and all products and services easy and convenient"
              )
            ]),
            _c("img", {
              staticStyle: { "margin-top": "-60px", height: "140px" },
              attrs: { src: "/images/Whatwedo-banner.svg" }
            })
          ]
        ),
        _c("div", { staticClass: "mt-6" }, [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "media",
                      attrs: {
                        "data-aos": "fade-right",
                        "data-aos-offset": "300",
                        "data-aos-easing": "ease-in-sine"
                      }
                    },
                    [
                      _c("div", { staticClass: "media-left" }, [
                        _c("div", { staticClass: "image is-64x64" }, [
                          _c("img", {
                            attrs: { src: "/images/what-we-do-icon2.png" }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "media-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                              staticStyle: { "background-color": "#FFD8D8" }
                            },
                            [
                              _c("p", [
                                _vm._v("Hey Sam, How have you been??"),
                                _c("br"),
                                _vm._v("Long time huh??")
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "media",
                      attrs: {
                        "data-aos": "fade-left",
                        "data-aos-offset": "300",
                        "data-aos-easing": "ease-in-sine"
                      }
                    },
                    [
                      _c("div", { staticClass: "media-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                              staticStyle: { "background-color": "#FFE5C6" }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Hi Abu, I am fine dude. Great to see you.. Yeah that’s a long time. But I am really sorry man, I am in a hurry. Shall catch up with you tomorrow.."
                                )
                              ])
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "media-right" }, [
                        _c("div", { staticClass: "image is-64x64" }, [
                          _c("img", {
                            attrs: { src: "/images/what-we-do-icon1.png" }
                          })
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "media",
                      attrs: {
                        "data-aos": "fade-right",
                        "data-aos-offset": "300",
                        "data-aos-easing": "ease-in-sine"
                      }
                    },
                    [
                      _c("div", { staticClass: "media-left" }, [
                        _c("div", { staticClass: "image is-64x64" }, [
                          _c("img", {
                            attrs: { src: "/images/what-we-do-icon2.png" }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "media-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                              staticStyle: { "background-color": "#FFD8D8" }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Is everything alright?? Why in a hurry?"
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "media",
                      attrs: {
                        "data-aos": "fade-left",
                        "data-aos-offset": "300",
                        "data-aos-easing": "ease-in-sine"
                      }
                    },
                    [
                      _c("div", { staticClass: "media-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                              staticStyle: { "background-color": "#FFE5C6" }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Actually, I am in search of an Air Fryer from the last few days... Day and night I am spending in the scour. Do you have any idea where can I find it??"
                                )
                              ])
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "media-right" }, [
                        _c("div", { staticClass: "image is-64x64" }, [
                          _c("img", {
                            attrs: { src: "/images/what-we-do-icon1.png" }
                          })
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "media",
                      attrs: {
                        "data-aos": "fade-right",
                        "data-aos-offset": "300",
                        "data-aos-easing": "ease-in-sine"
                      }
                    },
                    [
                      _c("div", { staticClass: "media-left" }, [
                        _c("div", { staticClass: "image is-64x64" }, [
                          _c("img", {
                            attrs: { src: "/images/what-we-do-icon2.png" }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "media-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                              staticStyle: { "background-color": "#FFD8D8" }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Chill Dude... Is that all???? Haven’t you heard about the FindHub app?? Let me just show you"
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column mobile-ui-image-column" }, [
        _c("div", { staticClass: "box mobile-ui-image-box" }, [
          _c(
            "div",
            { staticClass: "media", attrs: { "data-aos": "flip-up" } },
            [
              _c("div", { staticClass: "media-content" }, [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "is-size-3 has-text-danger has-text-weight-medium"
                    },
                    [
                      _vm._v(
                        "Findhub is that onestop destination where you will get all that you want without distress"
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "media-right" }, [
                _c("figure", { staticClass: "image" }, [
                  _c("img", {
                    staticStyle: { width: "28rem" },
                    attrs: { src: "/images/what-we-do-mobile-ui-image.png" }
                  })
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column mb-6" }, [
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: {
                          "background-color": "#FFE5C6",
                          width: "26rem"
                        }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "I don’t believe these online platforms Abu. I won’t get a good price there."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: {
                          "background-color": "#FFD8D8",
                          width: "26rem"
                        }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Seems like you have had a bad experience in the online market."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: {
                          "background-color": "#FFE5C6",
                          width: "26rem"
                        }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "That sounds good. But ain’t this going to take time? It might take quite a number of days before I get the item..."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "No Sam, Findhub shows you the nearest location available so that you get the required item at the earliest possible."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Hmmm.... But what if the price is unacceptable?"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "They provide you options to negotiate the price as well. You can settle for the best price possible."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "All that is fine Abu. But there is a catch, I am not looking for any Air Fryer. My specifications are a bit different. No online platforms give us the privilege to be versed on these."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: {
                          "background-color": "#FFD8D8",
                          width: "26rem"
                        }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "That’s exactly why I say, Findhub is exceptional. Through Findhub, you can check it all directly with the seller."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Do you mean, I can actually chat with the seller?"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [_c("p", [_vm._v("Yes, you can!!!")])]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "What if they continue to nag me later, to get more of their products???"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Don’t worry on that Sam!! Findhub makes sure that the customer details are not disclosed to the sellers."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Wow...that’s just amazing. But can we order the products in bulk as well????"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-right",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Yes, you can order the products in bulk also."
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              {
                staticClass: "media",
                attrs: {
                  "data-aos": "fade-left",
                  "data-aos-offset": "300",
                  "data-aos-easing": "ease-in-sine"
                }
              },
              [
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-right chat-box border-top-right chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFE5C6" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Sounds Excellent then.. Never before did I come across such a groundbreaking platform. I am waiting to get started."
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "media-right" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon1.png" }
                    })
                  ])
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "media", attrs: { "data-aos": "fade-right" } },
              [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "image is-64x64" }, [
                    _c("img", {
                      attrs: { src: "/images/what-we-do-icon2.png" }
                    })
                  ])
                ]),
                _c("div", { staticClass: "media-content" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "box is-pulled-left chat-box border-top-left chat-box-width-desktop",
                        staticStyle: { "background-color": "#FFD8D8" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Yes, Findhub for sure is one of a kind. Even the registration process is simple. Let me help you Sign up...By the way Sam, you owe me a treat"
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }