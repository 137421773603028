<template>
    <div id="what-we-do" style="overflow-x: hidden;overflow-y: hidden;">
        <div class="is-hidden-desktop">
            <div class="container is-max-widescreen">
                <div class="pt-4 column is-one-fifth-desktop is-one-fifth-tablet is-two-fifths-mobile">
                    <router-link :to="{ name: 'SearchHome' }">
                            <img src="/images/findhub-logo_1.png">
                    </router-link>
                </div>
                            <h3 class="title my-4 is-size-4 is-size-5-mobile is-size-4-tablet has-text-centered">What We Do</h3>
                <div class="columns">
                    <div class="column mb-6">
                        <!-- banner container tablet -->
                        <div class="column is-primary-gradient has-text-justified px-6 my-3 is-hidden-mobile" style="height:auto; border-radius:3px; display:flex;justify-content:space-around; align-items:center">
                        <p class="has-text-white">Findhub is an online e-commerce search engine that helps you search for you products and services within the UAE. We aggregate products and services on a single platform, making search and access to any and all products and services easy and convenient</p>
                        <img src="/images/Whatwedo-banner.svg" style="margin-top:-60px; height:140px">
                        </div>
                        <!-- banner container mobile view-->
                        <div class="column is-primary-gradient has-text-justified px-3 mb-3 mt-5 is-hidden-tablet" style="height:auto; border-radius:3px; display:grid; justify-items: center; align-items:center">
                        <img src="/images/Whatwedo-banner.svg" style="margin-top:-60px; height:140px">
                        <p class="has-text-white">Findhub is an online e-commerce search engine that helps you search for you products and services within the UAE. We aggregate products and services on a single platform, making search and access to any and all products and services easy and convenient</p>
                        </div>

                <div class="columns">
                    <div class="column">
                        <div class="media">
                            <div class="media-left">
                                <div class="image is-64x64">
                                <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Hey Sam, How have you been??<br>Long time huh??</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>Hi Abu, I am fine dude. Great to see you.. Yeah that’s a long time. But I am really sorry man, I am in a hurry. Shall catch up with you tomorrow..</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Is everything alright?? Why in a hurry?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>Actually, I am in search of an Air Fryer from the last few days... Day and night I am spending in the scour. Do you have any idea where can I find it??</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Chill Dude... Is that all???? Haven’t you heard about the FindHub app?? Let me just show you</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column p-6">
                        <div class="box" style="background-color: transparent; box-shadow: none; border: 1px solid red;">
                            <div class="media" data-aos="flip-up">
                                <div class="media-content">
                                    <div class="content">
                                            <p class="has-text-weight-medium has-text-danger">Findhub is that onestop destination where you will get all that you want without distress</p>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <figure class="image" style="width: 5rem;">
                                        <img src="/images/what-we-do-mobile-ui-image.png">
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>I don’t believe these online platforms Abu. I won’t get a good price there.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Seems like you have had a bad experience in the online market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>That sounds good. But ain’t this going to take time? It might take quite a number of days before I get the item...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>No Sam, Findhub shows you the nearest location available so that you get the required item at the earliest possible.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>Hmmm.... But what if the price is unacceptable?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>They provide you options to negotiate the price as well. You can settle for the best price possible.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>All that is fine Abu. But there is a catch, I am not looking for any Air Fryer. My specifications are a bit different. No online platforms give us the privilege to be versed on these.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>That’s exactly why I say, Findhub is exceptional. Through Findhub, you cancheck it all directly with the seller.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>Do you mean, I can actually chat with the seller?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Yes, you can!!!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>What if they continue to nag me later, to get more of their products???</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Don’t worry on that Sam!! Findhub makes sure that the customer details are not disclosed to the sellers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>Wow...that’s just amazing. But can we order the products in bulk as well????</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Yes, you can order the products in bulk also.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-right chat-box border-top-right" style="background-color: #FFE5C6;">
                                        <p>Sounds Excellent then.. Never before did I come across such a groundbreaking platform. I am waiting to get started.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon1.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="media" data-aos="fade-left">
                            <div class="media-left">
                                <div class="image is-64x64">
                                    <img src="/images/what-we-do-icon2.png">
                                </div>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <div class="box is-pulled-left chat-box border-top-left" style="background-color: #FFD8D8;">
                                        <p>Yes, Findhub for sure is one of a kind. Even the registration process is simple. Let me help you Sign up...By the way Sam, you owe me a treat</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
        <div class="is-hidden-touch">
            <div class="container is-max-widescreen">
                <div class="pt-4 column is-one-fifth-desktop is-one-fifth-tablet is-two-fifths-mobile">
                    <router-link :to="{ name: 'SearchHome' }">
                            <img src="/images/findhub-logo.svg">
                    </router-link>
                </div>
                            <h3 class="title my-4 is-size-4 is-size-5-mobile is-size-4-tablet has-text-centered">What We Do</h3>
                <div class="columns">
                    <div class="column mb-6">
                        <div class="column is-primary-gradient has-text-justified px-6 is-hidden-mobile" style="height:auto; border-radius:3px; display:flex;justify-content:space-around; align-items:center">
                        <p class="has-text-white">Findhub is an online e-commerce search engine that helps you search for you products and services within the UAE. We aggregate products and services on a single platform, making search and access to any and all products and services easy and convenient</p>
                        <img src="/images/Whatwedo-banner.svg" style="margin-top:-60px; height:140px">
                        </div>

                <!-- <div class="column"> -->
                    <div class="mt-6">
                        <div class="columns">
                            <div class="column">
                                <div class="columns">
                                    <div class="column">
                                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                            <div class="media-left">
                                                <div class="image is-64x64">
                                                <img src="/images/what-we-do-icon2.png">
                                                </div>
                                            </div>
                                            <div class="media-content">
                                                <div class="content">
                                                    <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                                        <p>Hey Sam, How have you been??<br>Long time huh??</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                            <div class="media-content">
                                                <div class="content">
                                                    <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                                        <p>Hi Abu, I am fine dude. Great to see you.. Yeah that’s a long time. But I am really sorry man, I am in a hurry. Shall catch up with you tomorrow..</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="media-right">
                                                <div class="image is-64x64">
                                                    <img src="/images/what-we-do-icon1.png">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                            <div class="media-left">
                                                <div class="image is-64x64">
                                                    <img src="/images/what-we-do-icon2.png">
                                                </div>
                                            </div>
                                            <div class="media-content">
                                                <div class="content">
                                                    <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                                        <p>Is everything alright?? Why in a hurry?</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                            <div class="media-content">
                                                <div class="content">
                                                    <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                                        <p>Actually, I am in search of an Air Fryer from the last few days... Day and night I am spending in the scour. Do you have any idea where can I find it??</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="media-right">
                                                <div class="image is-64x64">
                                                    <img src="/images/what-we-do-icon1.png">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                            <div class="media-left">
                                                <div class="image is-64x64">
                                                    <img src="/images/what-we-do-icon2.png">
                                                </div>
                                            </div>
                                            <div class="media-content">
                                                <div class="content">
                                                    <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                                        <p>Chill Dude... Is that all???? Haven’t you heard about the FindHub app?? Let me just show you</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column mobile-ui-image-column">
                    <div class="box mobile-ui-image-box">
                        <div class="media" data-aos="flip-up">
                            <div class="media-content">
                                <div class="content">
                                        <p class="is-size-3 has-text-danger has-text-weight-medium">Findhub is that onestop destination where you will get all that you want without distress</p>
                                </div>
                            </div>
                            <div class="media-right">
                                <figure class="image">
                                    <img style="width: 28rem;" src="/images/what-we-do-mobile-ui-image.png">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column mb-6">
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6; width: 26rem;">
                                            <p>I don’t believe these online platforms Abu. I won’t get a good price there.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8; width: 26rem;">
                                            <p>Seems like you have had a bad experience in the online market.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6; width: 26rem;">
                                            <p>That sounds good. But ain’t this going to take time? It might take quite a number of days before I get the item...</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                            <p>No Sam, Findhub shows you the nearest location available so that you get the required item at the earliest possible.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                            <p>Hmmm.... But what if the price is unacceptable?</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                            <p>They provide you options to negotiate the price as well. You can settle for the best price possible.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                            <p>All that is fine Abu. But there is a catch, I am not looking for any Air Fryer. My specifications are a bit different. No online platforms give us the privilege to be versed on these.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8; width: 26rem;">
                                            <p>That’s exactly why I say, Findhub is exceptional. Through Findhub, you can check it all directly with the seller.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                            <p>Do you mean, I can actually chat with the seller?</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                            <p>Yes, you can!!!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                            <p>What if they continue to nag me later, to get more of their products???</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                            <p>Don’t worry on that Sam!! Findhub makes sure that the customer details are not disclosed to the sellers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                            <p>Wow...that’s just amazing. But can we order the products in bulk as well????</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8;">
                                            <p>Yes, you can order the products in bulk also.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-right chat-box border-top-right chat-box-width-desktop" style="background-color: #FFE5C6;">
                                            <p>Sounds Excellent then.. Never before did I come across such a groundbreaking platform. I am waiting to get started.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-right">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon1.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="media" data-aos="fade-right">
                                <div class="media-left">
                                    <div class="image is-64x64">
                                        <img src="/images/what-we-do-icon2.png">
                                    </div>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <div class="box is-pulled-left chat-box border-top-left chat-box-width-desktop" style="background-color: #FFD8D8; ">
                                            <p>Yes, Findhub for sure is one of a kind. Even the registration process is simple. Let me help you Sign up...By the way Sam, you owe me a treat</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav class="tabs has-background-dark">
        <div class="container">
            <footer-home></footer-home>
        </div>
    </nav>
    </div>
</template>

<script>
import FooterHome from '../layout/FooterHome.vue'

export default {
    components: {
        FooterHome
    },
    name: 'WhatWeDo'
}
</script>

<style scoped>
</style>
