<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.onPageLoad.loading === true" loading-text="Processing your payment..."></page-loader>
    </div>
</template>

<script>
import { STRIPE_KEY } from '@/.env'
import { PaymentService } from '@/services/payment.service'

export default {
    name: 'OrderPayment',
    async mounted () {
        await this.$loadScript('https://js.stripe.com/v3/')
        // eslint-disable-next-line no-undef
        const stripe = Stripe(STRIPE_KEY)
        const sessionData = await PaymentService.getStripSession()
        console.log(sessionData)

        stripe.redirectToCheckout({ sessionId: sessionData.session_id })
    },
    data: function () {
        return {
            meta: {
                actions: {
                    onPageLoad: {
                        loading: true
                    }
                }
            },
            order: null,
            payment: {
                method: 'ONLINE'
            }
        }
    }
}
</script>

<style scoped>

</style>
