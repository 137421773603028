<template>
    <div id="profile-app">
        <div v-if="this.loader == true" class="has-text-centered">
            <page-loader></page-loader>
        </div>
        <section style="overflow-y: hidden; overflow-x: hidden;" v-if="this.loader == false">
            <div class="has-background-white box">
                <div class="container">
                    <div class="columns is-multiline">
                        <div class="column is-one-third" v-for="(address,index) in addressList" :key="index">
                            <div class="tile is-ancestor is-active">
                                <div class="tile is-parent" >
                                    <div class="tile is-child box" style="min-height: 12rem; box-shadow: none; border: 1px solid #d8d8d8;">
                                        <div class="pb-4">
                                            <p class="has-text-weight-bold pb-2" style="font-size: 0.875rem;">{{address.name}}</p>
                                            <p style="font-size: 0.875rem;">{{address.address_line_1}}</p>
                                            <p style="font-size: 0.875rem;">{{address.address_line_2}}</p>
                                            <p style="font-size: 0.875rem;">{{address.landmark}}</p>
                                            <p style="font-size: 0.875rem;">{{address.city}},{{address.state}},{{address.postcode}}</p>
                                            <p style="font-size: 0.875rem;">{{address.country}}</p>
                                            <p style="font-size: 0.875rem;">{{address.isd_code}}-{{address.phone_number}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// import { UsersService } from '@/services/users.service'
import { ProfileService } from '@/services/profile.service'
export default {
    name: 'Profile',
    data: function () {
        return {
            loader: true,
            address: [],
            inputs: [
                {
                    address: ''
                }
            ]
        }
    },
    components: {

    },
    mounted () {
        this.getAddress()
    },
    methods: {
        async getAddress () {
            try {
                const response = await ProfileService.getAddress()
                this.addressList = response.data
            } catch (e) {
                this.$log.error('Error while fetching addresses', e)
            } finally {
                this.loader = false
            }
        }
    }
}
</script>

<style scoped>

</style>
