
// import user from './user'
import dom from './dom'
import toast from './toast'
import ref_code from './ref_code'
import auth from './auth'

export default {
    // user,
    dom,
    toast,
    ref_code,
    auth
}
