<template>
    <div class="has-text-centered m-6">
        <clip-loader :loading="true" color="#999"></clip-loader>
        <strong> {{loadingText}} </strong>
    </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
    name: 'PageLoader',
    components: {
        ClipLoader
    },
    data: function () {
        return {

        }
    },
    props: {
        loadingText: {
            default: 'Loading',
            type: String
        }
    }

}
</script>

<style scoped>

</style>
