var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "columns pt-3 mb-0 is-centered is-hidden-touch" },
      [
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/about-us" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("About Us")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/what-we-do" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("What We Do")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/join-as-seller" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Join as a Seller")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/contact-us" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Contact Us")
              ])
            ])
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "columns p-3 is-centered is-hidden-desktop",
        staticStyle: { display: "inline-flex" }
      },
      [
        _c(
          "div",
          { staticClass: "column is-narrow px-0" },
          [
            _c("router-link", { attrs: { to: "/about-us" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("About Us")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow px-0" },
          [
            _c("router-link", { attrs: { to: "/what-we-do" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("What We Do")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow px-0" },
          [
            _c("router-link", { attrs: { to: "/join-as-seller" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Join as a Seller")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow px-0" },
          [
            _c("router-link", { attrs: { to: "/contact-us" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Contact Us")
              ])
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }