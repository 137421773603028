<template>
    <div class="c-sr-sp-container">
        <div class="cssp-title">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <div class="has-text-white">
                            <i class="fas fa-user fa-sm"></i> &nbsp; {{ sellerResponse.seller.business_name }}
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="has-text-white">
                            <i class="fas fa-map-pin fa-sm"></i> &nbsp;
                            {{ sellerResponse.seller.city != null ? sellerResponse.seller.city.name : "NA" }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cssp-body">
            <div class="has-text-centered has-text-weight-bold is-size-6 pb-1 has-text-white">
                {{ term.term }}
            </div>
            <div class="cssp-b-images-container" v-if="product_images.length > 0">
<!--                <ProductZoomer :base-images="product_images" :base-zoomer-options="meta.zoomer_options"></ProductZoomer>-->
                <b-carousel :autoplay="false" :indicator-inside="false" :overlay="meta.image_container.gallery" @click="meta.image_container.gallery=true">
                    <b-carousel-item v-for="(item, i) in product_images" :key="i">
                        <a class="image">
                            <img class="sp-image-overlap has-text-centered" :src="item.url">
                        </a>
                    </b-carousel-item>
                    <span v-if="meta.image_container.gallery" @click="meta.image_container.gallery=false" class="modal-close is-large"/>
                    <template #indicators="props">
                        <figure class="al image" :draggable="false">
                            <img :draggable="false" :src="props.url">
                        </figure>
                    </template>
                </b-carousel>
            </div>
            <div v-else>
                <div class="has-text-centered">
                    <img src="/images/placeholder-image.png">
                </div>
                <div class="has-text-centered">
                    <span class="has-text-white">No image available!</span>
                </div>
            </div>
            <div class="cssp-estimate-container sp-margin-8">
                <div class="cssp-e-box mx-auto p-4" style="border-radius: 6px;">
                    <div class="has-text-weight-bold has-text-centered mb-1 has-text-white">Current Offer</div>
                    <div class="css-e-b-body p-2 is-size-7 has-text-white">
                        <div class="columns">
                            <div class="column">
                                Item Price
                            </div>
                            <div class="column has-text-right is-narrow">
                                {{ formatAmount(sellerResponse.price) }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                Service Charge
                            </div>
                            <div class="column has-text-right is-narrow">
                                {{ formatAmount(sellerResponse.commission + sellerResponse.shipping_fee) }}
                            </div>
                        </div>
                        <!-- <div class="columns">
                            <div class="column is-9">
                                Delivery Charge
                            </div>
                            <div class="column is-3 has-text-right">
                                {{ formatAmount(0) }}
                            </div>
                        </div> -->
                        <hr class="cssp-hr">
                        <div class="columns">
                            <div class="column">
                                Total Amount Payable
                            </div>
                            <div class="column has-text-right is-narrow">
                                {{ formatAmount(totalPrice) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cssp-footer mt-5">
                <div class="cssp-f-actions has-text-centered">
                    <b-button type="is-primary" class="has-background-dark is-rounded" :loading="loading" v-on:click.prevent="$emit('proceed-with-response')">Proceed</b-button>
                </div>
            </div>
            <div class="has-text-centered">
                <div class="p-2 is-size-7 has-text-white">
                    Please ensure that you have received the offer for the right product from the seller.
                    For any clarifications please use the chat tab on the top right!
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { FileuploadService } from '@/services/fileupload.service'

export default {
    name: 'ProductDetail',
    props: ['sellerResponse', 'term', 'loading'],
    data: function () {
        return {
            cart: null,
            meta: {
                actions: {
                    onProceedAction: {
                        loading: false
                    }
                },
                image_container: {
                    gallery: false
                },
                zoomer_options: {

                }
            }
        }
    },
    computed: {
        product_images: function () {
            const arr = []
            if (this.sellerResponse.image1 != null) {
                arr.push(this.getImageObject(this.sellerResponse.image1))
            }
            if (this.sellerResponse.image2 != null) {
                arr.push(this.getImageObject(this.sellerResponse.image2))
            }
            if (this.sellerResponse.image3 != null) {
                arr.push(this.getImageObject(this.sellerResponse.image3))
            }
            // return {
            //     normal_size: arr
            // }
            return arr
        },
        totalPrice: function () {
            return parseFloat(this.sellerResponse.price) + this.sellerResponse.commission + this.sellerResponse.shipping_fee
        }
    },
    async mounted () {
    },
    methods: {
        getImageObject: function (file_id) {
            return {
                url: FileuploadService.getFileUrl(file_id),
                file_id: file_id
            }

            // return {
            //     normal_size: [
            //         {}
            //     ]
            // }
        }
    },
    watch: {

    }
}
</script>
