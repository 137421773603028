var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-content" }, [
    _c("div", { staticClass: "has-background-white box w-6 " }, [
      _c("div", [
        _vm.files.file_id === null
          ? _c(
              "div",
              [
                _c(
                  "b-upload",
                  {
                    on: { input: _vm.handleFileUploads },
                    model: {
                      value: _vm.files.file_data,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "file_data", $$v)
                      },
                      expression: "files.file_data"
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "file-cta" },
                      [
                        _c("b-icon", {
                          staticClass: "file-icon",
                          attrs: { icon: "upload" }
                        }),
                        _c("span", { staticClass: "file-label" }, [
                          _vm._v("Click to upload")
                        ])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _c("div", [
              _c("img", { attrs: { src: _vm.imageUrl(_vm.files.file_id) } }),
              _c(
                "div",
                [
                  _c("b-button", {
                    attrs: {
                      type: "is-danger",
                      size: "is-small",
                      outlined: "",
                      label: "Delete"
                    },
                    on: { click: _vm.onDelete }
                  })
                ],
                1
              )
            ]),
        _c("div", { staticClass: "columns mt-4" }, [
          _c("div", { staticClass: "column has-text-centered" }, [
            _c(
              "div",
              { staticClass: "control" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button is-primary is-primary-addon-1",
                    on: { click: _vm.onSubmit }
                  },
                  [_vm._v(" Upload ")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }