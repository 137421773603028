import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class ContactUsService extends BaseService {
    static async contactFormData (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/contact_form`, reqData)
        return response
    }
}
