var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.onPageLoad.loading === true
        ? _c("page-loader")
        : _c("div", [
            _c("div", { staticClass: "page-content" }, [
              _c(
                "div",
                {
                  staticClass: "p-3 pb-4 result-header findhub-search-header",
                  attrs: { id: "search-status-header" }
                },
                [
                  _c("div", { staticClass: "columns is-vcentered" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _c("div", { staticClass: "media" }, [
                            _vm.termDetails.category
                              ? _c("div", { staticClass: "media-left" }, [
                                  _c("img", {
                                    staticStyle: { width: "2.5rem" },
                                    attrs: {
                                      src: _vm.imageUrl(
                                        this.termDetails.category.icon_image
                                      )
                                    }
                                  })
                                ])
                              : _c("div", { staticClass: "media-left" }, [
                                  _c("img", {
                                    staticStyle: { width: "2.5rem" },
                                    attrs: { src: _vm.imageUrl(null) }
                                  })
                                ]),
                            _c("div", { staticClass: "media-content" }, [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "title is-6 is-uppercase has-text-gray my-2"
                                },
                                [_vm._v(_vm._s(this.termDetails.term))]
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c("div", { attrs: { id: "ssh-availability" } }, [
                            _c("div", [
                              _vm.termDetails.status == "notified"
                                ? _c("i", {
                                    staticClass: "fas fa-spinner fa-pulse"
                                  })
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.search_status) + " ")
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _c("div", { attrs: { id: "ssh-seller-count" } }, [
                            _vm.search_results_status === "SELLERS_FOUND"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "icon has-text-success" },
                                    [_c("i", { staticClass: "fas fa-check" })]
                                  ),
                                  _vm._v(
                                    " Available. " +
                                      _vm._s(_vm.termDetails.responses.length) +
                                      " seller" +
                                      _vm._s(
                                        _vm.termDetails.responses.length > 1
                                          ? "s"
                                          : ""
                                      ) +
                                      " found! "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "column has-text-right" },
                      [
                        _vm.termDetails.status === "notified"
                          ? _c(
                              "b-button",
                              {
                                staticStyle: { "margin-right": "1em" },
                                attrs: { type: "button", size: "is-small" },
                                on: { click: _vm.cancelSearch }
                              },
                              [_vm._v("Cancel")]
                            )
                          : _vm._e(),
                        _c(
                          "b-dropdown",
                          {
                            attrs: { "aria-role": "list" },
                            scopedSlots: _vm._u([
                              {
                                key: "trigger",
                                fn: function(ref) {
                                  var active = ref.active
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type:
                                            "has-background-dark has-text-white ssh-sort",
                                          size: "is-small",
                                          "icon-right": active
                                            ? "menu-up"
                                            : "menu-down"
                                        }
                                      },
                                      [
                                        _vm._v(" Sort By "),
                                        _vm.sort_order != null
                                          ? _c("span", [
                                              _vm.sort_order === 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Price: Lowest First"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm.sort_order === 2
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Location: Nearest First"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.sort_order,
                              callback: function($$v) {
                                _vm.sort_order = $$v
                              },
                              expression: "sort_order"
                            }
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { value: 1, "aria-role": "listitem" } },
                              [_vm._v("Price: Lowest first")]
                            ),
                            _c(
                              "b-dropdown-item",
                              { attrs: { value: 2, "aria-role": "listitem" } },
                              [_vm._v("Location: Nearest first")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm.showable_results.length > 0
                ? _c("div", { attrs: { id: "seller-response-container" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "columns is-gapless is-hidden-touch pl-columns"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "column is-4 pt-4",
                            attrs: { id: "sr-list" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pb-4" },
                              _vm._l(_vm.showable_results, function(term) {
                                return _c(
                                  "div",
                                  {
                                    key: term.id,
                                    staticClass: "sr-l-item",
                                    class: {
                                      "is-active":
                                        term.id ===
                                        _vm.selected_term_response.id
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.selected_term_response = term
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "columns is-desktop is-vcentered",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.markForEnquiry(term)
                                            _vm.onClickEnquiry(term)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "column is-narrow" },
                                          [
                                            _c("img", {
                                              staticClass: "sr-image-width",
                                              attrs: {
                                                src: _vm.imageUrl(
                                                  term.seller.logo
                                                )
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "column p-1" },
                                          [
                                            _c("div", [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    term.seller.business_name
                                                  )
                                                )
                                              ])
                                            ]),
                                            _c("div", [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-map-pin fa-sm"
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "is-size-7" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      term.seller.city.name
                                                    ) +
                                                      " - " +
                                                      _vm._s(
                                                        (
                                                          term.distance / 1000
                                                        ).toFixed(2)
                                                      ) +
                                                      " Km"
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "is-size-7" },
                                                [
                                                  _vm._v(
                                                    _vm._s(term.seller.plan)
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              [
                                                _c("b-rate", {
                                                  attrs: {
                                                    size: "is-small",
                                                    "custom-text": "100 Reviews"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "column is-narrow" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "has-text-danger has-text-weight-semibold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatAmount(term.price)
                                                  ) + " AED"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "column is-4 is-primary-addon-1",
                            attrs: { id: "sr-detail" }
                          },
                          [
                            _c("product-detail", {
                              attrs: {
                                "seller-response": _vm.selected_term_response,
                                term: _vm.termDetails,
                                loading:
                                  _vm.meta.actions.onProceedAction.loading
                              },
                              on: {
                                "proceed-with-response": function($event) {
                                  return _vm.onProceedEnquiry(
                                    _vm.selected_term_response
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "column is-4",
                            attrs: { id: "sr-chat" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "has-text-centered mt-3" },
                              [
                                _c(
                                  "b-tag",
                                  {
                                    staticClass: "is-centered",
                                    attrs: { type: "is-danger" }
                                  },
                                  [
                                    _vm._v(
                                      "Session ends in " +
                                        _vm._s(
                                          _vm.selected_response_timer_display
                                        )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("product-chat", {
                              attrs: {
                                "seller-response": _vm.selected_term_response,
                                term: _vm.termDetails
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "columns is-hidden-desktop px-4" },
                      [
                        _vm.product_listing == true
                          ? _c(
                              "div",
                              { staticClass: "column" },
                              _vm._l(_vm.termDetails.responses, function(term) {
                                return _c(
                                  "div",
                                  {
                                    key: term.id,
                                    staticClass:
                                      "columns product-list is-mobile",
                                    on: {
                                      click: function($event) {
                                        _vm.productListing()
                                        _vm.markForEnquiry(term)
                                        _vm.onClickEnquiry(term)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "column is-narrow" },
                                      [
                                        _c("img", {
                                          staticClass: "sr-image-width",
                                          attrs: {
                                            src:
                                              "/images/seller-default-logo.png"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "column" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(term.seller.business_name)
                                        )
                                      ]),
                                      _c("br"),
                                      _c("span", { staticClass: "is-size-7" }, [
                                        _vm._v("Premium")
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "column is-narrow" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "has-text-danger has-text-weight-semibold"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatAmount(term.price)
                                              ) + " AED"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.product_listing == false
                          ? _c(
                              "div",
                              { staticClass: "column" },
                              [
                                _c(
                                  "b-tabs",
                                  { attrs: { position: "is-centered" } },
                                  [
                                    _c(
                                      "b-tab-item",
                                      {
                                        staticClass:
                                          "column is-4 is-primary-addon-1",
                                        attrs: { label: "Estimate" }
                                      },
                                      [
                                        _c("product-detail", {
                                          attrs: {
                                            "seller-response":
                                              _vm.selected_term_response,
                                            term: _vm.termDetails,
                                            loading:
                                              _vm.meta.actions.onProceedAction
                                                .loading
                                          },
                                          on: {
                                            "proceed-with-response": function(
                                              $event
                                            ) {
                                              return _vm.onProceedEnquiry(
                                                _vm.selected_term_response
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tab-item",
                                      { attrs: { label: "Chat" } },
                                      [
                                        _c("product-chat", {
                                          attrs: {
                                            "seller-response":
                                              _vm.selected_term_response,
                                            term: _vm.termDetails
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                : _c("div", { staticClass: "mt-6" }, [
                    _c("div", [
                      _c("img", {
                        attrs: { src: "/images/sr-popup-character.svg" }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "p-6 box",
                        staticStyle: { "margin-top": "-37px" }
                      },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "is-size-4 has-text-weight-semibold pb-0"
                          },
                          [_vm._v("Thank you for reaching us!")]
                        ),
                        _c(
                          "h4",
                          {
                            staticClass:
                              "is-size-4 has-text-weight-semibold pb-5"
                          },
                          [_vm._v("We are working on your search results.")]
                        ),
                        _c("p", { staticClass: "pb-3" }, [
                          _vm._v(
                            "Findhub Portal is an application that helps to make your search easier and delivers quality services based on your search results. However, please note that Findhub portal assumes no responsibility for the quality, warranty etc. of the products or services delivered. Nevertheless , we strive for continuous improvement in the quality of the services provided and are open to consider your concerns and queries with high priority. We ensure to take necessary actions required and therefore meet your expectations regularly."
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            "If you have any concerns, please reach out to "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "has-text-danger has-text-weight-semibold"
                            },
                            [_vm._v("support@findhub.ae")]
                          ),
                          _vm._v(" or "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "has-text-danger has-text-weight-semibold"
                            },
                            [_vm._v("800 3463 482")]
                          )
                        ])
                      ]
                    )
                  ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }