var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-sr-sp-container" }, [
    _c("div", { staticClass: "cssp-title" }, [
      _c("div", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("div", { staticClass: "level-item" }, [
            _c("div", { staticClass: "has-text-white" }, [
              _c("i", { staticClass: "fas fa-user fa-sm" }),
              _vm._v(
                " " + _vm._s(_vm.sellerResponse.seller.business_name) + " "
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c("div", { staticClass: "level-item" }, [
            _c("div", { staticClass: "has-text-white" }, [
              _c("i", { staticClass: "fas fa-map-pin fa-sm" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.sellerResponse.seller.city != null
                      ? _vm.sellerResponse.seller.city.name
                      : "NA"
                  ) +
                  " "
              )
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "cssp-body" }, [
      _c(
        "div",
        {
          staticClass:
            "has-text-centered has-text-weight-bold is-size-6 pb-1 has-text-white"
        },
        [_vm._v(" " + _vm._s(_vm.term.term) + " ")]
      ),
      _vm.product_images.length > 0
        ? _c(
            "div",
            { staticClass: "cssp-b-images-container" },
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    autoplay: false,
                    "indicator-inside": false,
                    overlay: _vm.meta.image_container.gallery
                  },
                  on: {
                    click: function($event) {
                      _vm.meta.image_container.gallery = true
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "indicators",
                        fn: function(props) {
                          return [
                            _c(
                              "figure",
                              {
                                staticClass: "al image",
                                attrs: { draggable: false }
                              },
                              [
                                _c("img", {
                                  attrs: { draggable: false, src: props.url }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1833998483
                  )
                },
                [
                  _vm._l(_vm.product_images, function(item, i) {
                    return _c("b-carousel-item", { key: i }, [
                      _c("a", { staticClass: "image" }, [
                        _c("img", {
                          staticClass: "sp-image-overlap has-text-centered",
                          attrs: { src: item.url }
                        })
                      ])
                    ])
                  }),
                  _vm.meta.image_container.gallery
                    ? _c("span", {
                        staticClass: "modal-close is-large",
                        on: {
                          click: function($event) {
                            _vm.meta.image_container.gallery = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _c("div", [_vm._m(0), _vm._m(1)]),
      _c("div", { staticClass: "cssp-estimate-container sp-margin-8" }, [
        _c(
          "div",
          {
            staticClass: "cssp-e-box mx-auto p-4",
            staticStyle: { "border-radius": "6px" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "has-text-weight-bold has-text-centered mb-1 has-text-white"
              },
              [_vm._v("Current Offer")]
            ),
            _c(
              "div",
              { staticClass: "css-e-b-body p-2 is-size-7 has-text-white" },
              [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _vm._v(" Item Price ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "column has-text-right is-narrow" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatAmount(_vm.sellerResponse.price)) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _vm._v(" Service Charge ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "column has-text-right is-narrow" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatAmount(
                              _vm.sellerResponse.commission +
                                _vm.sellerResponse.shipping_fee
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("hr", { staticClass: "cssp-hr" }),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _vm._v(" Total Amount Payable ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "column has-text-right is-narrow" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.formatAmount(_vm.totalPrice)) + " "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "cssp-footer mt-5" }, [
        _c(
          "div",
          { staticClass: "cssp-f-actions has-text-centered" },
          [
            _c(
              "b-button",
              {
                staticClass: "has-background-dark is-rounded",
                attrs: { type: "is-primary", loading: _vm.loading },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("proceed-with-response")
                  }
                }
              },
              [_vm._v("Proceed")]
            )
          ],
          1
        )
      ]),
      _vm._m(2)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "has-text-centered" }, [
      _c("img", { attrs: { src: "/images/placeholder-image.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "has-text-centered" }, [
      _c("span", { staticClass: "has-text-white" }, [
        _vm._v("No image available!")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "has-text-centered" }, [
      _c("div", { staticClass: "p-2 is-size-7 has-text-white" }, [
        _vm._v(
          " Please ensure that you have received the offer for the right product from the seller. For any clarifications please use the chat tab on the top right! "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }