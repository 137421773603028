import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV === 'development',
    plugins: [
        createPersistedState({ key: 'findhub-web-ls', paths: ['ref_code'] })
    ]
})
