<template>
<div class="column" style="background-color: #F1F1F1;">
    <div class="container ">
        <h2 class="title has-text-centered has-text-weight-bold is-size-5-mobile font-color">
             Congratulations,you have successfully registered
        </h2>
        <h2 class="subtitle has-text-centered font-color mt-3">Your request is being processed by
            our administration team.You will receive an email notification shortly</h2>
        <!-- <h2 class="subtitle has-text-centered font-color">Contact -0011111222</h2> -->
        <div>
            <div class="container">
                <div class="columns is-mobile is-centered">
                    <div class="column is-half-desktop">
                        <div class="has-text-centered">
                                <router-link to="/">
                                    <button class="button is-primary is-primary-addon-1 is-size-7-mobile ">
                                        Back to Home
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-mobile is-centered has-text-centered-desktop">
                    <div class="column is-half">
                        <img src="/images/confirmation-upgrade.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Confirmation'
}
</script>

<style scoped>

</style>
