<template>
    <div id="contact-page">
        <!-- <div class="background-image">
            <div class="container is-fluid">
                <div class="columns min-height-46">
                    <div class="column is-two-fifths">
                        <router-link :to="{ name: 'SearchHome' }">
                            <div class="my-5">
                                <img style="width: 12.5rem;" src="/images/logo-white.svg">
                            </div>
                        </router-link>
                        <div class="pt-6">
                            <div>
                                <h3 class="title is-size 3 has-text-white">Contact Us</h3>
                            </div>
                            <div class="mt-4">
                                <div>
                                    <div class="media">
                                        <figure class="media-left">
                                            <i class="fa fa-map-marker has-text-white" aria-hidden="true"></i>
                                        </figure>
                                        <div class="media-content">
                                            <div class="content">
                                                <h6 class="subtitle is-size-6 is-capitalized has-text-white mb-0">UBL Business Centre | AL Khazim Building | Garhoud | <br>Dubai , United Arab Emirates</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div class="media">
                                        <figure class="media-left">
                                            <i class="fa fa-envelope has-text-white" aria-hidden="true"></i>
                                        </figure>
                                        <div class="media-content">
                                            <div class="content">
                                                <h6 class="subtitle is-size-6 has-text-white">info@findhub.ae</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <div class="media">
                                        <figure class="media-left">
                                            <i class="fa fa-phone has-text-white" aria-hidden="true"></i>
                                        </figure>
                                        <div class="media-content">
                                            <div class="content">
                                                <h6 class="subtitle is-size-6 has-text-white">800 3463 482</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr-margin-width">
                            <div class="mb-3">
                                <h4 class="title is-size-4 has-text-white">Follow us on</h4>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-narrow">
                                    <a href="https://m.facebook.com/FindHub-103790968433156/"><i class="fab fa-facebook has-text-white fa-lg"></i></a>
                                </div>
                                <div class="column is-narrow">
                                    <a href="https://twitter.com/FindHub21"><i class="fab fa-twitter has-text-white fa-lg"></i></a>
                                </div>
                                <div class="column is-narrow">
                                    <a href="https://instagram.com/findhub?utm_medium=copy_link"><i class="fab fa-instagram has-text-white fa-lg"></i></a>
                                </div>
                                <div class="column is-narrow">
                                    <a href="https://www.linkedin.com/company/findhub-portal"><i class="fab fa-linkedin has-text-white fa-lg"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-hidden-desktop pb-6 margin-top-16">
                        <div class="has-text-centered">
                            <div class="my-5 margin-top-6">
                                <h3 class="title is-size-3 mb-0">Get in Touch</h3>
                                <p class="is-size-7">Feel free to drop us a line below</p>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input placeholder="Name" v-model="name"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.name" class="error has-text-danger has-text-left">{{ errors.name[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input type="email" placeholder="Email" v-model="email"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.email" class="error has-text-danger has-text-left">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-select
                                            placeholder="Type of Issue"
                                            v-model="issue_type"
                                            expanded>
                                            <option
                                                v-for="option in $store.state.ref_code.data.contact_form_issuetype.data"
                                                :value="option.code"
                                                :key="option.name">
                                                {{ option.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.issue_type" class="error has-text-danger">{{ errors.issue_type[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input maxlength="200" type="textarea" placeholder="Message" v-model="message"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.message" class="error has-text-danger has-text-left">{{ errors.message[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button v-on:click="onSubmit()" icon-right="paper-plane" icon-pack="fa" type="button is-primary is-primary-addon-1">Send</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-hidden-touch">
                        <div class="has-text-centered margin-top-10">
                            <div class="my-5">
                                <h3 class="title is-size-3 mb-0">Get in Touch</h3>
                                <p class="is-size-7">Feel free to drop us a line below</p>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input placeholder="Name" v-model="name"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.name" class="error has-text-danger has-text-left">{{ errors.name[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input type="email" placeholder="Email" v-model="email"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.email" class="error has-text-danger has-text-left">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-select
                                            placeholder="Type of Issue"
                                            v-model="issue_type"
                                            expanded>
                                            <option
                                                v-for="option in $store.state.ref_code.data.contact_form_issuetype.data"
                                                :value="option.code"
                                                :key="option.name">
                                                {{ option.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.issue_type" class="error has-text-danger">{{ errors.issue_type[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input maxlength="200" type="textarea" placeholder="Message" v-model="message"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.message" class="error has-text-danger has-text-left">{{ errors.message[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button v-on:click="onSubmit()" icon-right="paper-plane" icon-pack="fa" type="button is-primary is-primary-addon-1" :loading="contact_us_form.loader">Send</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer is-centered p-0 m-0">
            <div class="column">
                <footer-menu ></footer-menu>
            </div>
        </footer> -->
        <section class="hero background-image is-fullheight">
            <div class="container is-fluid">
                <div class="columns min-height-46">
                    <div class="column is-two-fifths">
                        <router-link :to="{ name: 'SearchHome' }">
                            <div class="my-5">
                                <img style="width: 12.5rem;" src="/images/logo-white.svg">
                            </div>
                        </router-link>
                        <div class="pt-6">
                            <div>
                                <h3 class="title is-size 3 has-text-white">Contact Us</h3>
                            </div>
                            <div class="mt-4">
                                <div>
                                    <div class="media">
                                        <figure class="media-left">
                                            <i class="fa fa-map-marker has-text-white" aria-hidden="true"></i>
                                        </figure>
                                        <div class="media-content">
                                            <div class="content">
                                                <h6 class="subtitle is-size-6 is-capitalized has-text-white mb-0">UBL Business Centre | AL Khazim Building | Garhoud | <br>Dubai , United Arab Emirates</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div class="media">
                                        <figure class="media-left">
                                            <i class="fa fa-envelope has-text-white" aria-hidden="true"></i>
                                        </figure>
                                        <div class="media-content">
                                            <div class="content">
                                                <h6 class="subtitle is-size-6 has-text-white">info@findhub.ae</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <div class="media">
                                        <figure class="media-left">
                                            <i class="fa fa-phone has-text-white" aria-hidden="true"></i>
                                        </figure>
                                        <div class="media-content">
                                            <div class="content">
                                                <h6 class="subtitle is-size-6 has-text-white">800 3463 482</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr-margin-width">
                            <div class="mb-3">
                                <h4 class="title is-size-4 has-text-white">Follow us on</h4>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-narrow">
                                    <a href="https://m.facebook.com/FindHub-103790968433156/"><i class="fab fa-facebook has-text-white fa-lg"></i></a>
                                </div>
                                <div class="column is-narrow">
                                    <a href="https://twitter.com/FindHub21"><i class="fab fa-twitter has-text-white fa-lg"></i></a>
                                </div>
                                <div class="column is-narrow">
                                    <a href="https://instagram.com/findhub?utm_medium=copy_link"><i class="fab fa-instagram has-text-white fa-lg"></i></a>
                                </div>
                                <div class="column is-narrow">
                                    <a href="https://www.linkedin.com/company/findhub-portal"><i class="fab fa-linkedin has-text-white fa-lg"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-hidden-desktop pb-6 margin-top-16">
                        <div class="has-text-centered">
                            <div class="my-5 margin-top-6">
                                <h3 class="title is-size-3 mb-0">Get in Touch</h3>
                                <p class="is-size-7">Feel free to drop us a line below</p>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input placeholder="Name" v-model="name"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.name" class="error has-text-danger has-text-left">{{ errors.name[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input type="email" placeholder="Email" v-model="email"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.email" class="error has-text-danger has-text-left">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-select
                                            placeholder="Type of Issue"
                                            v-model="issue_type"
                                            expanded>
                                            <option
                                                v-for="option in $store.state.ref_code.data.contact_form_issuetype.data"
                                                :value="option.code"
                                                :key="option.name">
                                                {{ option.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.issue_type" class="error has-text-danger">{{ errors.issue_type[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input maxlength="200" type="textarea" placeholder="Message" v-model="message"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.message" class="error has-text-danger has-text-left">{{ errors.message[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button v-on:click="onSubmit()" icon-right="paper-plane" icon-pack="fa" type="button is-primary is-primary-addon-1">Send</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-hidden-touch">
                        <div class="has-text-centered margin-top-10">
                            <div class="my-5">
                                <h3 class="title is-size-3 mb-0">Get in Touch</h3>
                                <p class="is-size-7">Feel free to drop us a line below</p>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input placeholder="Name" v-model="name"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.name" class="error has-text-danger has-text-left">{{ errors.name[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input type="email" placeholder="Email" v-model="email"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.email" class="error has-text-danger has-text-left">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-select
                                            placeholder="Type of Issue"
                                            v-model="issue_type"
                                            expanded>
                                            <option
                                                v-for="option in $store.state.ref_code.data.contact_form_issuetype.data"
                                                :value="option.code"
                                                :key="option.name">
                                                {{ option.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.issue_type" class="error has-text-danger">{{ errors.issue_type[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-centered">
                                <div class="column is-half">
                                    <b-field>
                                        <b-input maxlength="200" type="textarea" placeholder="Message" v-model="message"></b-input>
                                    </b-field>
                                    <div class="has-text-left">
                                        <span v-if="errors.message" class="error has-text-danger has-text-left">{{ errors.message[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-button v-on:click="onSubmit()" icon-right="paper-plane" icon-pack="fa" type="button is-primary is-primary-addon-1" :loading="contact_us_form.loader">Send</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav class="tabs has-background-dark">
                <div class="container">
                    <footer-home></footer-home>
                </div>
            </nav>
        </section>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import {
    ContactUsService
} from '@/services/contact_us.service'
import FooterHome from '../layout/FooterHome.vue'
export default {
    components: {
        FooterHome
    },
    name: 'ContactUs',
    data: function () {
        return {
            contact_form_issuetype: [],
            errors: [],
            name: null,
            email: null,
            data: null,
            issue_type: null,
            message: null,
            contact_us_form: {
                loader: false
            }
        }
    },
    mounted () {
        this.refCodeRefresh([
            'contact_form_issuetype'
        ]).then(() => {
            this.pageLoaded = true
        })
    },
    methods: {
        ...mapActions(['refCodeRefresh']),
        async onSubmit () {
            const reqData = {
                name: this.name,
                email: this.email,
                issue_type: this.issue_type,
                message: this.message
            }
            try {
                this.contact_us_form.loader = true
                this.response = await ContactUsService.contactFormData(reqData)
                this.$buefy.notification.open({
                    message: 'Thank you for your message. It has been sent.',
                    type: 'is-success'
                })
                this.contact_us_form.loader = false
                this.clearInputFields()
            } catch (e) {
                this.contact_us_form.loader = false
                this.errors = e.response.data.errors
            }
        },
        clearInputFields (e) {
            this.name = ''
            this.email = ''
            this.issue_type = ''
            this.message = ''
            e.preventDefault()
        }
    }
}
</script>

<style scoped>

</style>
