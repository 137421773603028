var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _vm._m(0),
    _c("section", { staticClass: "is-fullwidth is-fullheight" }, [
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "columns is-centered" }, [
        _c(
          "div",
          { staticClass: "column is-one-quarter" },
          [
            _c("router-link", { attrs: { to: "/payment" } }, [
              _c(
                "button",
                {
                  staticClass: "button is-dark is-centered is-rounded ",
                  staticStyle: {
                    background: "linear-gradient(#CE263F,#EA632B)"
                  }
                },
                [
                  _c("span", { staticClass: " is-centered mt-1 p-6" }, [
                    _vm._v("Proceed to Pay")
                  ])
                ]
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "columns is-mobile is-centered has-text-centered-desktop"
      },
      [
        _c("div", { staticClass: "column center" }, [
          _c(
            "h3",
            {
              staticClass: "title is-4 mt-3 has-text-black has-text-weight-bold"
            },
            [_vm._v(" My Order ")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns m-6  " }, [
      _c("div", { staticClass: "column box " }, [
        _c("div", { staticClass: "columns v-centered" }, [
          _c("div", { staticClass: "column is-11  is-mobile is-centered" }, [
            _c(
              "div",
              {
                staticClass:
                  " column has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10"
              },
              [
                _c("h3", { staticClass: "subtitle has-text-weight-bold  " }, [
                  _vm._v("Iphone 12 Pro Max")
                ]),
                _c("h3", { staticClass: "subtitle has-text-weight-bold " }, [
                  _vm._v("XYZ")
                ]),
                _c("h3", { staticClass: "font-color has-text-weight-bold" }, [
                  _vm._v("$ 1200")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "columns v-centered" }, [
            _c(
              "div",
              {
                staticClass:
                  "column is-1 mt-6 has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10"
              },
              [
                _c("figure", { staticClass: "image is-32x32 ml-3" }, [
                  _c("img", { attrs: { src: "/images/enquiry-mobile.png" } })
                ])
              ]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns m-6  " }, [
      _c("div", { staticClass: "column box " }, [
        _c("div", { staticClass: "columns v-centered" }, [
          _c("div", { staticClass: "column is-11  is-mobile is-centered" }, [
            _c(
              "div",
              {
                staticClass:
                  " column has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10"
              },
              [
                _c("h3", { staticClass: "subtitle has-text-weight-bold  " }, [
                  _vm._v("Iphone 12 Pro Max")
                ]),
                _c("h3", { staticClass: "subtitle has-text-weight-bold " }, [
                  _vm._v("XYZ")
                ]),
                _c("h3", { staticClass: "font-color has-text-weight-bold" }, [
                  _vm._v("$ 1200")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "columns v-centered" }, [
            _c(
              "div",
              {
                staticClass:
                  "column is-1 mt-6 has-text-centered-mobile has-text-right-tablet has-text-right-desktop padding-top-10"
              },
              [
                _c("figure", { staticClass: "image is-32x32 ml-3" }, [
                  _c("img", { attrs: { src: "/images/enquiry-mobile.png" } })
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }