import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class OrderService extends BaseService {
    static async getAddress () {
        const response = await axios.get(`${API_URL}/api/customer/address`)
        return response
    }

    static async createOrder (cart_id) {
        const reqData = {
            cart_id: cart_id
        }
        const response = await axios.post(`${API_URL}/api/customer/order`, reqData)
        return response.data
    }

    static async updateShipping (address) {
        const reqData = {
            shipping_address: address
        }
        const response = await axios.post(`${API_URL}/api/customer/order/update_shipping_address`, reqData)
        return response.data
    }

    static async updatePaymentAndPlaceOrder (payment) {
        const reqData = {
            payment: payment
        }
        const response = await axios.post(`${API_URL}/api/customer/order/update_payment_and_place_order`, reqData)
        return response.data
    }

    static async getOrder (orderId) {
        const response = await axios.get(`${API_URL}/api/customer/order/${orderId}`)
        return response.data
    }

    static async confirmPayment (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/order/confirm_payment`, reqData)
        return response.data
    }

    static async getOnlinePaymentRequest (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/order/create_payment_request`, reqData)
        return response.data
    }

    static async getOrdersList () {
        const response = await axios.get(`${API_URL}/api/customer/order`)
        return response.data
    }

    static async getOrderInvoice (orderId, responseType) {
        const response = await axios.get(`${API_URL}/api/customer/order/${orderId}/invoice`, responseType)
        return response.data
    }

    static async requestInvoice (orderId) {
        const response = await axios.get(`${API_URL}/api/customer/order/${orderId}/request-invoice`)
        return response.data
    }

    static async getPaymentMethods () {
        const response = await axios.get(`${API_URL}/api/customer/order/payment_methods`)
        return response.data
    }
}
