<template>
    <div class="modal-card" style="width:auto">
    <div class="modal-card-body">
        <!-- LOGIN 1 -->
        <div class="p-3" id="view-vendor_choose" v-if="currentView == 'Home'">
            <form v-on:submit.prevent="onRequestOTP">
                <div class="columns is-mobile is-marginless">
                    <div class="column">
                        <div class="mt-2">
                            <h4 class="title is-size-5">Welcome</h4>
                            <h5 class="subtitle is-6 font-color">Sign into your account</h5>
                        </div>
                    </div>
                    <div class="column">
                        <div class="image is-64x64 is-pulled-right">
                            <img src="/images/user-circle.svg">
                        </div>
                    </div>
                </div>
                <div class="column is-mobile">
                    <div class="margin-top-2">
                        <div class="field has-addons">
                            <div class="control" style="width:25%">
<!--                                <b-select placeholder="" v-model="isd_code" class="select is-rounded is-danger ">-->
<!--                                    <option-->
<!--                                        v-for="option in isd_codes"-->
<!--                                        :value="option.value"-->
<!--                                        :key="option.value">-->
<!--                                        {{ option.name }}-->
<!--                                    </option>-->
<!--                                </b-select>-->
                                <span class="select is-rounded is-danger" style="font-size:1.25rem;">
                                  <select v-model="isd_code" >
                                    <option
                                        v-for="option in isd_codes"
                                        :value="option.value"
                                        :key="option.value">
                                      {{ option.name }}
                                    </option>
                                  </select>
                                </span>
                            </div>
                            <div class="control">
                                <input class="input is-danger is-rounded is-medium " type="text"
                                        placeholder="Mobile Number" v-model="mobile_number" size="40">
                                <span v-if="errors.mobile_number" class="error">{{ errors.mobile_number[0] }}</span>
                            </div>
                        </div>
                        <div class="has-text-centered ">
                            <b-button tag="button" native-type="submit" type="is-primary" class="is-primary-addon-1 is-medium is-fullwidth" :loading="form.onRequestOTP.loading">
                                Send OTP
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="column is-mobile has-text-centered">
                    <div class="margin-top-3">
                        <div class="divider-horizontal is-danger">
                            <span class="title is-5 font-color">OR</span>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 class="title is-5 has-text-centered">Signin Using</h1>
                </div>
                <div class="columns is-mobile is-centered mt-5 mb-0">
                    <div class="column is-narrow">
                        <a href="" v-on:click.prevent="openGoogle()">
                            <img src="/images/search.png">
                        </a>
                    </div>
                    <div class="column is-narrow">
                        <a href="" v-on:click.prevent="openFacebook()">
                            <img src="/images/login-popup-facebook.png">
                        </a>
                    </div>
                </div>
                <div class="columns">
                    <div class="column has-text-centered">
                        <div class="mt-5 mb-5">
                            <p><span class="ml-4">By continuing you agree to our</span> <br>
                                <a href="/terms-and-conditions"><span class="has-text-danger">Terms & Conditions</span></a> and <a href="/privacy-policy"><span class="has-text-danger">Privacy Policy</span></a>
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="p-3" id="view-otp_page" v-if="currentView == 'OtpPage'">
            <form v-on:submit.prevent="onRequestSignup()">
                <div class="column left ">
                    <figure class="image is-24x24 ">
                        <a href="" v-on:click.prevent.stop="onBackArrow" class="is-active" id="arrow-back1">
                            <img src="/images/Icon ionic-ios-arrow-round-back.svg">
                        </a>
                    </figure>
                </div>
                <div class="p-3">
                    <div class="columns is-mobile is-centered has-text-centered">
                        <div class="column center">
                            <img style="width:3.5rem;" src="/images/pincode.svg">
                        </div>
                    </div>
                    <div>
                        <div class="margin-top-300">
                            <h1 class="title is-6 has-text-centered">Enter your One Time Password</h1>
                        </div>
                    </div>
                    <div class="columns  is-centered  mt-2">
                        <div class="column is-half">
                            <div class="otpBox ">
                                <v-otp-input ref="otpInput" input-classes="otp-input" separator="" :num-inputs="4" :should-auto-focus="true" :is-input-num="true" inpu @on-change="handleOnChange" @on-complete="handleOnComplete" />
                            </div>
                        </div>
                    </div>
                    <div class=" has-text-centered mt-2"><span v-if="errors.otp" class="error">{{ errors.otp[0] }}</span></div>
                        <div class="columns">
                            <div class="column ">
                                <h2 class="is-pulled-right"> Didn't get Otp?</h2>
                            </div>
                        </div>
                    </div>

                    <div class="column center">
                        <div class="has-text-centered">
                            <b-button tag="button" native-type="submit" type="is-primary" class="is-centered is-primary is-primary-addon-1" :loading="form.onRequestSignup.loading">
                                Submit
                            </b-button>
                        </div>
                    </div>
            </form>
        </div>
        <div class="p-3" id="view-signup_page" v-if="currentView == 'SignupPage'">
        <form v-on:submit.prevent="onSubmitSignup">
            <div class="column left ">
<!--                <figure class="image is-24x24 ">-->
<!--                    <a href="" v-on:click.prevent.stop="onBackArrow" class="is-active" id="arrow-back">-->
<!--                        <img src="/images/Icon ionic-ios-arrow-round-back.svg">-->
<!--                    </a>-->
<!--                </figure>-->
            </div>
            <div class="p-3">
                <div class="columns is-mobile is-marginless">
                    <div class="column  mt-1">
                        <div>
                            <h4 class="title is-size-5 ">Let's</h4>
                            <h5 class="subtitle is-6 font-color ">Get Started Now</h5>
                        </div>
                    </div>
                    <div class="column ">
                        <figure class="image is-64x64 is-pulled-right ">
                            <img src="/images/password.svg">
                        </figure>
                    </div>
                </div>
                <div class="column is-mobile">
                        <div class="field">
                            <div class="control p-1">
                                <input v-model="name" class="input border-red is-rounded is-medium" type="text"
                                       name="name" placeholder="Name" size="35">
                                 <div class=" has-text-centered mt-2"><span v-if="errors.name" class="error">{{ errors.name[0] }}</span></div>
                            </div>
                        </div>
                        <div class="has-text-centered">
                            <div class="field">
                                <div class="control p-1 mt-2">
                                    <input v-model="email" class="input is-danger is-rounded is-medium" type="email"
                                           name="email" placeholder="Email ID">
                                    <div class=" has-text-centered mt-2"> <span v-if="errors.email" class="error">{{ errors.email[0] }}</span></div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="column center">
                    <div class="has-text-centered">
                        <b-button tag="button" native-type="submit" type="is-primary" class="is-centered is-primary is-primary-addon-1" :loading="form.onSubmitSignup.loading">
                            Submit
                        </b-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
        <div class="p-3 has-text-centered" id="view-oauth-signin" v-if="currentView=='OAuthLogin'">
            <div>
                Logging in...
            </div>
            <div>
                <br>
                <pulse-loader :loading="oauth.loader" :color="'#999'"></pulse-loader>
            </div>
        </div>
    </div>

</div>
</template>
<script>

import { LoginService } from '@/services/login.service'
import { AuthService } from '@/services/auth.service'
import * as hellojs from 'hellojs'
import { FACEBOOK_CLIENT_ID, GOOGLE_CLIENT_ID } from '@/.env'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { ProfileService } from '@/services/profile.service'
import AddAddressPopupModal from '@/components/profile/AddAddressPopupModal'
import { RefCodeService } from '@/services/ref_code.service'

function logToLocalStorage (message) {
    const logs = JSON.parse(localStorage.getItem('Logs')) || []
    logs.push({ timestamp: new Date().toISOString(), message })
    localStorage.setItem('Logs', JSON.stringify(logs))
}
export default {
    name: 'LoginPopupModal',
    props: ['latitude', 'longitude'],
    data: function () {
        return {
            form: {
                onRequestOTP: {
                    loading: false
                },
                onRequestSignup: {
                    loading: false
                },
                onSubmitSignup: {
                    loading: false
                }
            },
            errors: [],
            mobile_number: '',
            isd_code: '91',
            otpInput: '',
            request_id: '',
            isd_codes: [],
            name: '',
            email: '',

            currentView: 'Home',
            oauth: {
                loader: true
            },
            addressList: null

        }
    },
    components: {
        PulseLoader
    },
    async mounted () {
        this.isd_codes = await RefCodeService.getAvailableISDCodes()
        this.isd_code = this.isd_codes[0].value
        hellojs.init({
            google: `${GOOGLE_CLIENT_ID}`,
            facebook: `${FACEBOOK_CLIENT_ID}`
        })
    },
    methods: {
        move (id) {
            this.$refs[id].focus()
        },

        openFacebook () {
            this.currentView = 'OAuthLogin'
            hellojs.login('facebook', {
                scope: 'email',
                redirect_uri: '/oauth-redirect'
            })
                .then(async () => {
                    const authRes = hellojs('facebook').getAuthResponse()
                    console.log(authRes)
                    logToLocalStorage(authRes)
                    try {
                        const response = await AuthService.attempOAuth2Login({ access_token: authRes.access_token, type: 'facebook' })
                        this.$store.commit('auth/AUTH_SUCCESS', response)
                        window.location.reload()

                        // console.log(response)
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.$emit('close')
                    }
                }, function (e) {
                    console.log(e)
                })
        },
        openGoogle () {
            this.currentView = 'OAuthLogin'

            hellojs.login('google', {
                scope: 'email,profile',
                redirect_uri: '/oauth-redirect'
            })
                .then(async () => {
                    const authRes = hellojs('google').getAuthResponse()
                    logToLocalStorage(authRes)
                    try {
                        const response = await AuthService.attempOAuth2Login(authRes)
                        this.$store.commit('auth/AUTH_SUCCESS', response)
                        window.location.reload()
                        // logToLocalStorage(response);
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.$emit('close')
                    }
                }, function (e) {
                    console.log(e)
                })
        },

        onBackArrow () {
            this.currentView = 'Home'
        },
        async onRequestOTP () {
            this.data = {
                mobile_number: this.mobile_number,
                isd_code: this.isd_code
            }

            try {
                this.form.onRequestOTP.loading = true
                const response = await LoginService.saveLogin(this.data)
                this.request_id = response.data.request_id
                this.currentView = 'OtpPage'
            } catch (e) {
                this.errors = e.response.data.errors
            } finally {
                this.form.onRequestOTP.loading = false
            }
        },
        async onRequestSignup () {
            this.data = {
                request_id: this.request_id,
                otp: this.otpInput
            }
            try {
                this.form.onRequestSignup.loading = true
                const response = await LoginService.saveOtp(this.data)
                if (response.data.is_user === false) {
                    this.currentView = 'SignupPage'
                } else {
                    const token = await AuthService.getToken(this.request_id)
                    const userInfo = await AuthService.getUserInfo(token)
                    this.$store.commit('auth/AUTH_SUCCESS', userInfo)
                    this.$emit('close')
                }
            } catch (e) {
                this.errors = e.response.data.errors
                console.log('this.errors', e.response.data.errors)
            } finally {
                this.form.onRequestSignup.loading = false
            }
        },
        async onSubmitSignup () {
            this.data = {
                name: this.name,
                email: this.email,
                request_id: this.request_id
            }
            try {
                this.form.onSubmitSignup.loading = true
                const response = await LoginService.saveCustomer(this.data)
                const userInfo = await AuthService.getUserInfo(response)
                this.$store.commit('auth/AUTH_SUCCESS', userInfo)
                // await this.getAddress()
                this.$emit('close')
                console.log(response)
            } catch (e) {
                this.errors = e.response.data.errors
                console.log('this.errors', e.response.data.errors)
            } finally {
                this.form.onSubmitSignup.loading = false
            }
        },
        handleOnComplete (value) {
            console.log('OTP completed: ', value)
        },
        handleOnChange (value) {
            this.otpInput = value
        },
        // async getNearByAddress () {
        //     this.data = {
        //         latitude: this.latitude,
        //         longitude: this.longitude
        //     }
        //     try {
        //         this.address = await ProfileService.getNearByAddress(500, this.data)
        //     } catch (e) {
        //         this.errors = e.response.data.errors
        //         console.log('this.errors', e.response.data.errors)
        //     }
        // },
        async getAddress () {
            try {
                const response = await ProfileService.getAddress()
                this.addressList = response.data
                if (this.addressList.length === 0) {
                    await this.onClickAddAddress()
                }
            } catch (e) {
                this.$log.error('Error while fetching addresses', e)
            } finally {
                this.loader = false
            }
        },
        async onClickAddAddress () {
            this.$buefy.modal.open({
                parent: this,
                component: AddAddressPopupModal,
                trapFocus: true
            })
        }
    }
}
</script>
<style scoped>
</style>
