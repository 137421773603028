<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.onPageLoad.loading === true"></page-loader>
        <div v-else>
            <div class="page-content">
                <div id="search-status-header" class="p-3 pb-4 result-header findhub-search-header">
                    <div class="columns is-vcentered">
                        <div class="column">
                            <div class="columns">
                                <div class="column">
                                    <div class="media">
                                        <div class="media-left" v-if="termDetails.category">
                                            <img :src="imageUrl(this.termDetails.category.icon_image)" style="width: 2.5rem;">
                                        </div>
                                        <div class="media-left" v-else>
                                            <img :src="imageUrl(null)" style="width: 2.5rem;">
                                        </div>
                                        <div class="media-content">
                                            <h6 class="title is-6 is-uppercase has-text-gray my-2">{{ this.termDetails.term }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third">
                                    <div id="ssh-availability" class="">
                                        <div>

                                            <i v-if="termDetails.status == 'notified'" class="fas fa-spinner fa-pulse"></i>
                                            {{ search_status }}
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div id="ssh-seller-count" class="">
                                        <div v-if="search_results_status==='SELLERS_FOUND'">
                                            <span class="icon has-text-success">
                                                <i class="fas fa-check"></i>
                                            </span>
                                            Available. {{ termDetails.responses.length }} seller{{ termDetails.responses.length>1?"s":"" }} found!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column has-text-right">
                            <b-button type="button" class="" size="is-small" style="margin-right:1em;" v-if="termDetails.status === 'notified'" v-on:click="cancelSearch">Cancel</b-button>
                            <b-dropdown aria-role="list" v-model="sort_order" class="">
                                <template #trigger="{ active }">
                                    <b-button type="has-background-dark has-text-white ssh-sort" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" >
                                        Sort By
                                        <span v-if="sort_order!=null">
                                            <span v-if="sort_order===1">Price: Lowest First</span>
                                            <span v-if="sort_order===2">Location: Nearest First</span>
                                        </span>

                                    </b-button>
                                </template>

                                <b-dropdown-item :value="1" aria-role="listitem">Price: Lowest first</b-dropdown-item>
                                <b-dropdown-item :value="2" aria-role="listitem">Location: Nearest first</b-dropdown-item>
                            </b-dropdown>

                        </div>
                    </div>
                </div>
                <div id="seller-response-container" class="" v-if="showable_results.length > 0">
                    <div class="columns is-gapless is-hidden-touch pl-columns">
                        <div class="column is-4 pt-4" id="sr-list">
                            <div class="pb-4">
                                <div class="sr-l-item" v-on:click.prevent="selected_term_response=term" v-for="(term) in showable_results" :key="term.id" v-bind:class="{ 'is-active': term.id===selected_term_response.id }" >
                                    <div class="columns is-desktop is-vcentered"
                                         v-on:click.prevent="markForEnquiry(term); onClickEnquiry(term);">
                                        <div class="column is-narrow">
                                            <img class="sr-image-width" :src="imageUrl(term.seller.logo)">
                                        </div>
                                        <div class="column p-1">
                                            <div>
                                                <span>{{ term.seller.business_name }}</span>
                                            </div>
                                            <div>
                                                <i class="fas fa-map-pin fa-sm"></i> &nbsp;<span class="is-size-7">{{ term.seller.city.name }} - {{ ((term.distance)/1000).toFixed(2) }} Km</span>
                                            </div>
                                            <div>
                                                <span class="is-size-7">{{ term.seller.plan }}</span>
                                            </div>
                                            <div>
                                                <b-rate size="is-small" custom-text="100 Reviews"></b-rate>
                                            </div>
                                        </div>
                                        <div class="column is-narrow">
                                            <span class="has-text-danger has-text-weight-semibold">{{
                                            formatAmount(term.price)
                                            }} AED</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-4 is-primary-addon-1" id="sr-detail">
                            <product-detail :seller-response="selected_term_response"
                                            :term="termDetails"
                                            v-on:proceed-with-response="onProceedEnquiry(selected_term_response)"
                                            :loading="meta.actions.onProceedAction.loading"
                            ></product-detail>
                        </div>
                        <div class="column is-4" id="sr-chat">
                            <div class="has-text-centered mt-3">
                                <b-tag type="is-danger" class="is-centered">Session ends in {{ selected_response_timer_display }}</b-tag>
                            </div>
                            <product-chat :seller-response="selected_term_response"
                                          :term="termDetails"
                            ></product-chat>
                        </div>
                    </div>
                    <div class="columns is-hidden-desktop px-4">
                        <div class="column" v-if="product_listing==true">
                            <div v-on:click="productListing(); markForEnquiry(term); onClickEnquiry(term);"
                                 class="columns product-list is-mobile" v-for="(term) in termDetails.responses"
                                 :key="term.id">
                                <div class="column is-narrow">
                                    <img class="sr-image-width" src="/images/seller-default-logo.png">
                                </div>
                                <div class="column">
                                    <span>{{ term.seller.business_name }}</span>
                                    <br>
                                    <span class="is-size-7">Premium</span>
                                </div>
                                <div class="column is-narrow">
                                    <span class="has-text-danger has-text-weight-semibold">{{ formatAmount(term.price) }} AED</span>
                                </div>
                            </div>
                        </div>
                        <div class="column" v-if="product_listing==false">
                            <b-tabs position="is-centered">
                                <b-tab-item class="column is-4 is-primary-addon-1" label="Estimate">
                                    <product-detail :seller-response="selected_term_response"
                                            :term="termDetails"
                                            v-on:proceed-with-response="onProceedEnquiry(selected_term_response)"
                                            :loading="meta.actions.onProceedAction.loading"
                                    ></product-detail>
                                </b-tab-item>

                                <b-tab-item label="Chat">
                                    <product-chat :seller-response="selected_term_response"
                                          :term="termDetails"
                                    >
                                    </product-chat>
                                </b-tab-item>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <div v-else class="mt-6">
                    <div>
                        <img src="/images/sr-popup-character.svg">
                    </div>
                    <div class="p-6 box" style="margin-top: -37px;">
                        <h4 class="is-size-4 has-text-weight-semibold pb-0">Thank you for reaching us!</h4>
                        <h4 class="is-size-4 has-text-weight-semibold pb-5">We are working on your search results.</h4>
                        <p class="pb-3">Findhub Portal is an application that helps to make your search easier and delivers quality services based on your search results. However, please note that Findhub portal assumes no responsibility for the quality, warranty etc. of the products or services delivered. Nevertheless , we strive for continuous improvement in the quality of the services provided and are open to consider your concerns and queries with high priority. We ensure to take necessary actions required and therefore meet your expectations regularly.</p>
                        <p>If you have any concerns, please reach out to <span class="has-text-danger has-text-weight-semibold">support@findhub.ae</span> or <span class="has-text-danger has-text-weight-semibold">800 3463 482</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { SearchService } from '@/services/search.service'
import ProductDetail from '../../components/search/ProductDetail.vue'
import ProductChat from '../../components/search/ProductChat.vue'
import { CartService } from '@/services/cart.service'
import _ from 'lodash'
import moment from 'moment'

export default {
    name: 'SearchProduct',
    data: function () {
        return {
            termDetails: {
                responses: []
            },
            selected_term_response: null,
            term_id: this.$route.params.id,
            product_listing: true,
            enquiries: {
                loading: true,
                data: [],
                pagination: {}
            },
            cart: null,
            product_detail: null,
            sort_order: 2,
            selectedResponseExpiryTimer: {
                value: 0,
                intervalId: null
            },
            meta: {
                actions: {
                    onPageLoad: {
                        loading: true
                    },
                    onProceedAction: {
                        loading: false
                    }
                }
            }
        }
    },
    computed: {
        search_results_status: function () {
            if (this.termDetails.responses.length === 0) {
                return 'SELLERS_NOT_FOUND'
            }
            return 'SELLERS_FOUND'
        },
        search_status: function () {
            if (this.termDetails.status === 'cancelled') {
                return 'Cancelled'
            }
            return 'Searching'
        },
        showable_results: function () {
            return _.filter(this.termDetails.responses, function (o) {
                return o.delivery_method !== null && o.enquiry_response_expiry_in > 0
            })
        },
        selected_response_timer_display: function () {
            if (this.selectedResponseExpiryTimer.value > 0) {
                const diff = moment.duration(this.selectedResponseExpiryTimer.value, 'seconds')
                return `${diff.days()}d ${diff.hours()}h ${diff.minutes()}m`
            }
            return '0 seconds'
        }
    },
    components: {
        ProductDetail,
        ProductChat
    },
    async mounted () {
        await this.fetchTermInfo()
        const reqData = {
            clear: 1
        }
        this.cart = await CartService.getCart(reqData)
        this.meta.actions.onPageLoad.loading = false
    },
    created () {
        this.interval = setInterval(() => this.fetchTermInfo(), 10000)
    },
    beforeDestroy () {
        try {
            this.$log.info('Cleraing intervals')
            if (this.interval != null) {
                clearInterval(this.interval)
            }
        } catch (e) {
            this.$log.error('Error while trying to clear interval', e)
        }
    },
    methods: {
        async cancelSearch () {
            try {
                const { result, dialog } = await this.$buefy.dialog.confirm({
                    title: 'Cancel Search',
                    message: 'Are you sure you want to <b>cancel</b> your search?',
                    closeOnConfirm: false,
                    hasIcon: true
                })
                console.log(result, dialog)
                if (result) {
                    dialog.close()
                    await SearchService.cancel(this.term_id)
                    await this.fetchTermInfo()
                }
                //
            } catch (e) {
                console.log(e)
            }
        },
        async fetchTermInfo () {
            try {
                let sort_order_param = null
                switch (this.sort_order) {
                case 1:
                    sort_order_param = 'price-asc-rank'
                    break
                case 2:
                    sort_order_param = 'distance-asc-rank'
                }
                const response = await SearchService.viewSearch(this.term_id, { params: { sort: sort_order_param } })
                this.termDetails = response

                if (this.showable_results.length > 0 && this.selected_term_response == null) {
                    this.selected_term_response = this.showable_results[0]
                } else if (this.selected_term_response !== null) {
                    this.selected_term_response = _.find(this.showable_results, { id: this.selected_term_response.id })
                }
            } catch (e) {
                this.errors = e.response
            } finally {
                this.loader = false
            }
        },
        async markForEnquiry (stResponse) {
            try {
                stResponse._meta.actions.onProceed.loading = true
                await SearchService.markForEnquiry(stResponse.id)
            } catch (e) {
                this.errors = e.response
                console.log('this.errors', e.response)
            }
        },
        productListing () {
            this.product_listing = false
        },
        onClickEnquiry (term) {
            this.product_detail = term
        },
        async onProceedEnquiry (seller_response) {
            const reqData = {
                cart_id: this.cart.id,
                search_term_response_id: seller_response.id
            }
            try {
                this.meta.actions.onProceedAction.loading = true
                await CartService.addItemToCart(reqData)
                return await this.$router.push({ name: 'OrderDeliveryAddress' })
            } catch (e) {
                this.$log.error('Failed while adding to cart', e)
                this.meta.actions.onProceedAction.loading = false
            }
        }
    },
    watch: {
        sort_order: async function (sortOrder) {
            await this.fetchTermInfo()
        },
        selectedResponseExpiryTimer: {
            handler (value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.selectedResponseExpiryTimer.value = this.selectedResponseExpiryTimer.value - 1
                    }, 1000)
                }
            },
            immediate: true
        },
        selected_term_response: {
            handler (value) {
                if (value != null) {
                    this.selectedResponseExpiryTimer.value = value.enquiry_response_expiry_in
                    if (this.selectedResponseExpiryTimer.intervalId != null) {
                        clearInterval(this.selectedResponseExpiryTimer.intervalId)
                    }
                    this.selectedResponseExpiryTimer.intervalId = setInterval(() => {
                        this.selectedResponseExpiryTimer.value = this.selectedResponseExpiryTimer.value - 1
                    }, 1000)
                }
            }
        }
    }
}
</script>
