var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "search-history" } },
    [
      _vm.meta.actions.onPageLoad.loading === true
        ? _c("page-loader")
        : _c(
            "b-table",
            {
              attrs: {
                data: _vm.searchHistory.pData.data,
                bordered: false,
                striped: false
              }
            },
            [
              _c("b-table-column", {
                attrs: { field: "search_term", label: "Search Term" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.term) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { field: "actions", label: "Actions" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "is-warning is-light",
                              size: "is-small"
                            },
                            on: {
                              click: function($event) {
                                return _vm.routeToDetailPage(props.row)
                              }
                            }
                          },
                          [_vm._v("View Details ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-pagination", {
                attrs: {
                  simple: _vm.isSimple,
                  "per-page": _vm.searchHistory.pData.meta.per_page,
                  total: _vm.searchHistory.pData.meta.total,
                  "icon-pack": _vm.iconPack,
                  "icon-prev": _vm.prevIcon,
                  "icon-next": _vm.nextIcon,
                  order: _vm.order
                },
                on: { change: _vm.onChangePage },
                model: {
                  value: _vm.searchHistory.pData.meta.current_page,
                  callback: function($$v) {
                    _vm.$set(_vm.searchHistory.pData.meta, "current_page", $$v)
                  },
                  expression: "searchHistory.pData.meta.current_page"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }