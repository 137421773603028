var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      attrs: {
        "wrapper-class": "container is-max-desktop",
        type: "is-primary is-primary-addon-1"
      },
      scopedSlots: _vm._u(
        [
          {
            key: "brand",
            fn: function() {
              return [
                _c(
                  "b-navbar-item",
                  {
                    attrs: {
                      active: false,
                      tag: "router-link",
                      to: { name: "SearchHome" }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/new_logo_name_white.png",
                        alt:
                          "Lightweight UI components for Vue.js based on Bulma"
                      }
                    })
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "start",
            fn: function() {
              return undefined
            },
            proxy: true
          },
          _vm.$store.state.auth.currentUser
            ? {
                key: "end",
                fn: function() {
                  return [
                    _c(
                      "b-navbar-dropdown",
                      {
                        attrs: { label: _vm.$store.state.auth.currentUser.name }
                      },
                      [
                        _c(
                          "b-navbar-item",
                          { attrs: { tag: "router-link", to: "/profile" } },
                          [_vm._v(" Profiles ")]
                        ),
                        _c(
                          "b-navbar-item",
                          { attrs: { tag: "router-link", to: "/orders-list" } },
                          [_vm._v(" Orders ")]
                        ),
                        _c(
                          "b-navbar-item",
                          {
                            attrs: {
                              tag: "router-link",
                              to: { name: "SearchHistory" }
                            }
                          },
                          [_vm._v(" Search History ")]
                        ),
                        _c(
                          "b-navbar-item",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onLogout($event)
                              }
                            }
                          },
                          [_vm._v(" Logout ")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [_vm._v("r ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }