
import _ from 'lodash'

export class MapUtil {
    static parseAddressComponents (address_components) {
        const obj = {}
        _.each(address_components, function (v1) {
            _.each(v1.types, function (v2, k2) {
                obj[v2] = v1.long_name
            })
        })
        return obj
    }

    static getLocalDisplayName (parsed_address_components) {
        const parsedData = parsed_address_components
        let out = ''
        if (_.has(parsedData, 'sublocality')) {
            out = out + parsedData.sublocality + ', '
        }

        if (_.has(parsedData, 'locality')) {
            out = out + parsedData.locality + ', '
        }

        if (_.has(parsedData, 'administrative_area_level_2')) {
            out = out + parsedData.administrative_area_level_2 + ', '
        }

        if (_.has(parsedData, 'administrative_area_level_1')) {
            out = out + parsedData.administrative_area_level_1 + ', '
        }

        out = out + parsedData.country

        return out
    }
}
