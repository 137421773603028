var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "chat-with-admin" } }, [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _vm.loading
          ? _c("page-loader")
          : _c("div", [
              _c("div", { staticClass: "page-content" }, [
                _c(
                  "div",
                  {
                    ref: "chatMessagesContainer",
                    staticClass: "has-background-white p-5",
                    staticStyle: { "margin-top": "-23px" },
                    attrs: { id: "chat-messages-container" }
                  },
                  [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "title has-text-centered has-text-weight-bold is-size-5-mobile is-size-5"
                      },
                      [_vm._v("Findhub Support")]
                    ),
                    _vm.chat.messages.data.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.chat.messages.data, function(
                            message,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              message.user.type == "ADMIN"
                                ? _c("div", [
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        { staticClass: "column is-4" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "p-4 box  chat-box-findhub"
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  " " + _vm._s(message.body)
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              message.user.type == "CUSTOMER"
                                ? _c("div", [
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column is-4 is-offset-8"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "p-4 box chat-box-customer"
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  " " + _vm._s(message.body)
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "has-background-white" }, [
                  _c(
                    "form",
                    {
                      attrs: { action: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onChatSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "b-field",
                        { staticClass: "m-0" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "Type your replies here",
                              type: "search",
                              "custom-class": "chat-input-box",
                              expanded: ""
                            },
                            model: {
                              value: _vm.chatForm.data.body,
                              callback: function($$v) {
                                _vm.$set(_vm.chatForm.data, "body", $$v)
                              },
                              expression: "chatForm.data.body"
                            }
                          }),
                          _c(
                            "p",
                            { staticClass: "control" },
                            [
                              _c("b-button", {
                                staticClass: "button chat-input-button",
                                attrs: {
                                  "native-type": "submit",
                                  "icon-pack": "fas",
                                  "icon-right": "paper-plane",
                                  size: "is-medium",
                                  loading: _vm.chatForm.loading,
                                  disabled: _vm.chatForm.loading
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }