var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "profile-app" } }, [
    this.loader == true
      ? _c("div", { staticClass: "has-text-centered" }, [_c("page-loader")], 1)
      : _vm._e(),
    this.loader == false
      ? _c(
          "section",
          { staticStyle: { "overflow-y": "hidden", "overflow-x": "hidden" } },
          [
            _c("div", { staticClass: "has-background-white box" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "columns is-multiline" },
                  _vm._l(_vm.addressList, function(address, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "column is-one-third" },
                      [
                        _c(
                          "div",
                          { staticClass: "tile is-ancestor is-active" },
                          [
                            _c("div", { staticClass: "tile is-parent" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tile is-child box",
                                  staticStyle: {
                                    "min-height": "12rem",
                                    "box-shadow": "none",
                                    border: "1px solid #d8d8d8"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "pb-4" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "has-text-weight-bold pb-2",
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [_vm._v(_vm._s(address.name))]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [_vm._v(_vm._s(address.address_line_1))]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [_vm._v(_vm._s(address.address_line_2))]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [_vm._v(_vm._s(address.landmark))]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(address.city) +
                                            "," +
                                            _vm._s(address.state) +
                                            "," +
                                            _vm._s(address.postcode)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [_vm._v(_vm._s(address.country))]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "0.875rem" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(address.isd_code) +
                                            "-" +
                                            _vm._s(address.phone_number)
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }