<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.onPageLoad.loading === true" loading-text="Processing your payment..."></page-loader>
        <div v-else>
            <stripe-checkout
                ref="checkoutRef"
                mode="payment"
                :pk="payment.stripe_key"
                :session-id="payment.request.provider_ref_id"
                :success-url="payment.success_url"
                :cancel-url="payment.cancel_url"
            >

            </stripe-checkout>
        </div>
    </div>

</template>

<script>
import _ from 'lodash'
import { OrderService } from '@/services/order.service'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { STRIPE_KEY } from '@/.env'

export default {
    name: 'OrderProcessPayment',
    async mounted () {
        const order_id = this.$route.query.order_id
        if (!_.isNumber(parseInt(order_id))) {
            this.$router.push({ name: 'Page404' })
        }

        this.payment.success_url = window.location.origin + this.$router.resolve({ name: 'OrderConfirmation' }).href + `?order_id=${order_id}`
        this.payment.cancel_url = window.location.origin + this.$router.resolve({ name: 'OrderDetails' }).href + `?order_id=${order_id}`

        this.order = await OrderService.getOrder(order_id)
        this.payment.request = await OrderService.getOnlinePaymentRequest({ order_id: this.order.id, success_url: this.payment.success_url, cancel_url: this.payment.cancel_url })

        this.meta.actions.onPageLoad.loading = false
        setTimeout(() => {
            this.$refs.checkoutRef.redirectToCheckout()
        }, 100)
    },
    data: function () {
        return {
            meta: {
                actions: {
                    onPageLoad: {
                        loading: true
                    }
                }
            },
            order: null,
            payment: {
                request: null,
                stripe_key: STRIPE_KEY,
                success_url: null,
                cancel_url: null
            }
        }
    },
    components: {
        StripeCheckout
    }
}
</script>

<style scoped>

</style>
