var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "body-wrapper" } }, [
    _c("header", [_c("navigation-bar")], 1),
    _c("div", { attrs: { id: "main-content" } }, [
      _c(
        "div",
        { staticClass: "content-wrapper container is-max-desktop mt-5" },
        [_c("router-view")],
        1
      )
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "content has-text-centered" }, [
          _c("p", [_vm._v(" © 2024 Findhub ")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }