<template>
    <div id="body-wrapper">
        <header>
            <navigation-bar></navigation-bar>
        </header>
        <div id="main-content">
            <div class="content-wrapper container is-max-desktop mt-5" >
                <router-view>
                </router-view>
            </div>
        </div>
        <footer class="footer">
            <div class="container">
                <div class="content has-text-centered">
                    <p>
                        &copy; 2024 Findhub
                    </p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

import NavigationBar from '@/views/layout/partials/NavigationBar'

export default {
    name: 'LayoutMaster',
    components: {
        NavigationBar
    },
    data: function () {
        return {

        }
    },
    methods: {

    }
}
</script>
