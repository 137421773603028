var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.onPageLoad.loading === true
        ? _c("page-loader", {
            attrs: { "loading-text": "Processing your payment..." }
          })
        : _c(
            "div",
            [
              _c("stripe-checkout", {
                ref: "checkoutRef",
                attrs: {
                  mode: "payment",
                  pk: _vm.payment.stripe_key,
                  "session-id": _vm.payment.request.provider_ref_id,
                  "success-url": _vm.payment.success_url,
                  "cancel-url": _vm.payment.cancel_url
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }