// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./bebasneuethin.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./bebasneuethin.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./bebasneuethin.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./bebasneuelight.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./bebasneuelight.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./bebasneuelight.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./bebasneuebook.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./bebasneuebook.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./bebasneuebook.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./bebasneueregular.woff2");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./bebasneueregular.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./bebasneueregular.ttf");
var ___CSS_LOADER_URL_IMPORT_12___ = require("./bebasneuebold.woff2");
var ___CSS_LOADER_URL_IMPORT_13___ = require("./bebasneuebold.woff");
var ___CSS_LOADER_URL_IMPORT_14___ = require("./bebasneuebold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Bebas Neue';\n    src: local('Bebas Neue Thin'),\n    local('BebasNeue-Thin'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n    font-weight: 100;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Bebas Neue';\n    src: local('Bebas Neue Light'),\n    local('BebasNeue-Light'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('truetype');\n    font-weight: 200;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Bebas Neue';\n    src: local('Bebas Neue Book'),\n    local('BebasNeueBook'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('truetype');\n    font-weight: 300;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Bebas Neue';\n    src: local('Bebas Neue Regular'),\n    local('BebasNeueRegular'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Bebas Neue';\n    src: local('Bebas Neue Bold'),\n    local('BebasNeueBold'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format('truetype');\n    font-weight: 700;\n    font-style: normal;\n}\n", ""]);
// Exports
module.exports = exports;
