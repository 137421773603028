// import axios from 'axios'
import { REFRESH_DATA } from './mutation-types'
import { RefCodeService } from '@/services/ref_code.service'

// async function getRefCodes () {
//     return {
//         data: {
//             countries: {
//                 data: [
//                     {
//                         id: 1,
//                         name: 'India'
//                     },
//                     {
//                         id: 2,
//                         name: 'India'
//                     }
//                 ]
//             }
//         }
//     }
// }

export const refCodeRefresh = {
    root: true,
    handler: async ({ commit }, classifiers) => {
        try {
            console.log('Inside refreshData')
            const response = await RefCodeService.getReferenceCodes(classifiers)
            commit(REFRESH_DATA, response.data)
        } catch (e) {
            console.log('Error loading data')
            throw e
        }
        return null
    }
}
