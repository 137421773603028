// import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class RefCodeService {
    static async getReferenceCodes (classifiers) {
        const response = await axios.get(`${API_URL}/api/customer/reference_codes`, {
            params: {
                classifiers: classifiers.join(',')
            }
        })
        return response
    }

    static async getAvailableISDCodes () {
        return [
            {
                name: '+971',
                value: '971'
            }
        ]
    }
}
