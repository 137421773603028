import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class ProfileService extends BaseService {
    static async saveAddress (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/address`, reqData)
        return response
    }

    static async getAddress () {
        const response = await axios.get(`${API_URL}/api/customer/address`)
        return response
    }

    static async updateAddress (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/address/modify`, reqData)
        return response
    }

    static async getNearByAddress (radius, data) {
        const response = await axios.get(`${API_URL}/api/customer/address/find_nearby_address/${radius}`, { params: data })
        
        return response
    }
}
