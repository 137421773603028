<template>
    <b-navbar wrapper-class="container is-max-desktop" type="is-primary is-primary-addon-1">
        <template #brand>
            <b-navbar-item :active="false" tag="router-link" :to="{ name: 'SearchHome' }">
                <img src="/images/new_logo_name_white.png" alt="Lightweight UI components for Vue.js based on Bulma">
            </b-navbar-item>
        </template>

        <template #start>

        </template>r

        <template #end v-if="$store.state.auth.currentUser">
            <b-navbar-dropdown :label="$store.state.auth.currentUser.name">
                <b-navbar-item tag="router-link" to="/profile">
                    Profiles
                </b-navbar-item>
                <b-navbar-item tag="router-link" to="/orders-list">
                    Orders
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'SearchHistory' }">
                    Search History
                </b-navbar-item>
                <b-navbar-item v-on:click.prevent="onLogout">
                    Logout
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
</template>

<script>
import { AuthService } from '@/services/auth.service'

export default {
    name: 'NavigationBar',
    methods: {
        async onLogout () {
            if (this.$store.getters['auth/isAuthenticated']) {
                await AuthService.attemptLogout()
                window.location.href = '/home'
            }
        }
    }
}
</script>

<style scoped>

</style>
