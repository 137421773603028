<template>
    <div id="oauth-redirect-page">

    </div>
</template>

<script>

// import axios from 'axios'
import 'hellojs'

export default {
    name: 'OAuthRedirect',
    data () {
        return {
        }
    },
    components: {

    },
    mounted () {
        console.log('Inside mounted()')
    }
}

</script>
