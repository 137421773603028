var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { "overflow-x": "hidden", "overflow-y": "hidden" },
        attrs: { id: "chat-with-admin" }
      },
      [
        _c("div", { staticClass: "page-wrapper" }, [
          _c("div", [
            _c("div", { staticClass: "page-content" }, [
              _c(
                "h3",
                {
                  staticClass:
                    "title has-text-centered has-text-weight-bold is-size-5-mobile"
                },
                [_vm._v("Build Your Search")]
              ),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column pl-5 pb-0" }, [
                      _c("h6", { staticClass: "is-6 has-text-grey-dark" }, [
                        _vm._v("Market")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "div",
                            { staticClass: "select is-rounded is-medium" },
                            [
                              _c("select", [
                                _c("option", [_vm._v("Select dropdown")]),
                                _c("option", [_vm._v("With options")])
                              ])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-primary is-primary-addon-1 is-medium"
                          },
                          [_c("i", { staticClass: "fas fa-plus" })]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column pl-5 pb-0" }, [
                      _c("h6", { staticClass: "is-6 has-text-grey-dark" }, [
                        _vm._v("Category")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "div",
                            { staticClass: "select is-rounded is-medium" },
                            [
                              _c("select", [
                                _c("option", [_vm._v("Select dropdown")]),
                                _c("option", [_vm._v("With options")])
                              ])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-primary is-primary-addon-1 is-medium"
                          },
                          [_c("i", { staticClass: "fas fa-plus" })]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column pl-5 pb-0" }, [
                      _c("h6", { staticClass: "is-6 has-text-grey-dark" }, [
                        _vm._v("Components")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "div",
                            { staticClass: "select is-rounded is-medium" },
                            [
                              _c("select", [
                                _c("option", [_vm._v("Select dropdown")]),
                                _c("option", [_vm._v("With options")])
                              ])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-primary is-primary-addon-1 is-medium"
                          },
                          [_c("i", { staticClass: "fas fa-plus" })]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column pl-5 pb-0" }, [
                      _c("h6", { staticClass: "is-6 has-text-grey-dark" }, [
                        _vm._v("Type")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "div",
                            { staticClass: "select is-rounded is-medium" },
                            [
                              _c("select", [
                                _c("option", [_vm._v("Select dropdown")]),
                                _c("option", [_vm._v("With options")])
                              ])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-primary is-primary-addon-1 is-medium"
                          },
                          [_c("i", { staticClass: "fas fa-plus" })]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column pl-5 pb-0" }, [
                      _c("h6", { staticClass: "is-6 has-text-grey-dark" }, [
                        _vm._v("Details")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("textarea", {
                            staticClass: "textarea",
                            attrs: { placeholder: "Enter Details Here" }
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns mb-6" }, [
                _c(
                  "div",
                  { staticClass: "column is-3 is-offset-9 is-narrow" },
                  [
                    _c("div", { staticClass: "file is-pulled-right" }, [
                      _c("label", { staticClass: "file-label" }, [
                        _c("input", {
                          staticClass: "file-input",
                          attrs: { type: "file", name: "resume" }
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "file-cta has-background-dark has-text-white has-text-right is-rounded"
                          },
                          [
                            _c("span", { staticClass: "file-label" }, [
                              _vm._v(" Attach File/Photo ")
                            ]),
                            _c("span", { staticClass: "file-icon" }, [
                              _c("i", { staticClass: "fas fa-paperclip ml-3" })
                            ])
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "columns mt-4" }, [
                _c("div", { staticClass: "column has-text-centered" }, [
                  _c("div", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button is-primary is-primary-addon-1 is-medium"
                      },
                      [
                        _vm._v("Search"),
                        _c("i", { staticClass: "fas fa-search ml-3" })
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }