var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "about-us" } }, [
    _c("div", [
      _c("div", { staticClass: "container is-max-widescreen" }, [
        _c(
          "div",
          {
            staticClass:
              "pt-4 column is-one-fifth-desktop is-one-fifth-tablet is-two-fifths-mobile"
          },
          [
            _c("router-link", { attrs: { to: { name: "SearchHome" } } }, [
              _c("img", { attrs: { src: "/images/findhub-logo_1.png" } })
            ])
          ],
          1
        ),
        _c(
          "h3",
          {
            staticClass:
              "title my-4 is-size-4 is-size-5-mobile is-size-4-tablet has-text-centered"
          },
          [_vm._v("About Us")]
        ),
        _vm._m(0)
      ])
    ]),
    _c("nav", { staticClass: "tabs has-background-dark" }, [
      _c("div", { staticClass: "container" }, [_c("footer-home")], 1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column mb-6" }, [
        _c(
          "div",
          {
            staticClass:
              "column is-primary-gradient has-text-justified px-6 is-hidden-mobile",
            staticStyle: {
              height: "auto",
              "border-radius": "3px",
              display: "flex",
              "justify-content": "space-around",
              "align-items": "center"
            }
          },
          [
            _c("p", { staticClass: "has-text-white" }, [
              _vm._v(
                "Findhub is a venture between two friends who struggled to find the right product in the UAE market that they were searching for. To read about their struggle and what led to the creation of Findhub app"
              )
            ]),
            _c("img", {
              staticStyle: { "margin-top": "-35px", height: "115px" },
              attrs: { src: "/images/about-banner.svg" }
            })
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "column is-primary-gradient has-text-justified px-3 mt-3 is-hidden-desktop is-hidden-tablet",
            staticStyle: {
              height: "auto",
              "border-radius": "3px",
              display: "grid",
              "justify-items": "center",
              "align-items": "center"
            }
          },
          [
            _c("img", {
              staticStyle: { "margin-top": "-35px", height: "115px" },
              attrs: { src: "/images/about-banner.svg" }
            }),
            _c("p", { staticClass: "has-text-white pt-2" }, [
              _vm._v(
                "Findhub is a venture between two friends who struggled to find the right product in the UAE market that they were searching for. To read about their struggle and what led to the creation of Findhub app"
              )
            ])
          ]
        ),
        _c("div", { staticClass: "content p-2 mt-5 has-text-justified" }, [
          _c("h5", { staticClass: "title" }, [_vm._v("Introduction")]),
          _c("p", [
            _vm._v(
              "Founded by two colleagues in 2020, FindHub is just as the same suggests. An online UAE-based local search engine that uses the best of technology to bring you what you have been searching for. Minimum labour, easy use, and great accessibility are just some of the many features of our application. It doesn't get easier than this!"
            )
          ])
        ]),
        _c("div", { staticClass: "content p-2 has-text-justified" }, [
          _c("h5", { staticClass: "title" }, [_vm._v("Origin Story")]),
          _c("p", [
            _vm._v(
              "2020 has been quite the year. The onset of a sudden pandemic and the subsequent lockdowns saw people confined to their houses with little or no avenue of stepping out or enjoying recreational activities such as shopping, picnics, etc. "
            )
          ]),
          _c("p", [
            _vm._v(
              "This particular lockdown also saw a sudden surge in the shopping frequency. As a result of this consumer shopping behaviour, we saw some unique patterns. People were now looking for products that they never had before or never needed to look for before. Most times they ended up with the wrong products that were shipped from far-off and unknown sources. We found ourselves in a similar predicament. We needed some very specific products that seemed out of reach. A local search for something small in the streets of Dubai turned into an expedition of sorts. We scoured every local market, combed through online platforms and stores, searched high and low in every lane, and continued to endeavour for a few days still."
            )
          ]),
          _c("p", [
            _vm._v(
              "Finally, we had to resort to online platforms with international shipping before we discovered a wholesale vendor who had the product, right down our lane! So we decided to do just that. We created our own application to help people search for all the products and services they require, from verified sources without any hassle or discomfort. Our necessity gave way to FindHub. Our application made way for a number of satisfied users and vendors, alike."
            )
          ])
        ]),
        _c("div", { staticClass: "container has-text-justified" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              {
                staticClass: "column m-2 p-5",
                staticStyle: {
                  "border-radius": "5px",
                  "background-color": "#FAFAFA",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: {
                      "background-image": "url('/images/question.svg')",
                      "background-size": "20%",
                      "background-position": "center",
                      "background-repeat": "no-repeat"
                    }
                  },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v("Why Do You Need A Local Search Engine?")
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "Search engine use accounts for 93% of the web traffic currently. Simply put, everyone is constantly looking for something or the other. We've faced that struggle. We know the pain that goes into looking for that important item. However, it always seems out of reach due to unavailability or a lack of demand in the market."
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "column m-2 p-5",
                staticStyle: {
                  "border-radius": "5px",
                  "background-color": "#FAFAFA",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: {
                      "background-image": "url('/images/question.svg')",
                      "background-size": "20%",
                      "background-position": "center",
                      "background-repeat": "no-repeat"
                    }
                  },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v("Why Can't I Find What I Want Locally?")
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "This is the exact problem we wish to address. An e-commerce search engine with the potential to turn into the best e-commerce search engine in helping you look for what you need without having to resort to travelling far and wide for it. A new radiator for your car? A repairman for that 5-year old AC? All you have to do is type it in. In short, we provide local search-related services in the UAE."
                      )
                    ])
                  ]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "columns mt-1" }, [
            _c(
              "div",
              {
                staticClass: "column m-2 p-5",
                staticStyle: {
                  "border-radius": "5px",
                  "background-color": "#FAFAFA",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: {
                      "background-image": "url('/images/question.svg')",
                      "background-size": "20%",
                      "background-position": "center",
                      "background-repeat": "no-repeat"
                    }
                  },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v("What Is Our Mission?")
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "We facilitate growth by getting the right people in touch. Great things happen when you go looking for the perfect item only to find it in the oddest of places. What started as a game of hunt is now a whole new platform to find the right fit. If you are looking for a product or service that you cannot find anywhere nearby, well, now you know where to look!. Findhub makes your search easier."
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "column m-2 p-5",
                staticStyle: {
                  "border-radius": "5px",
                  "background-color": "#FAFAFA",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: {
                      "background-image": "url('/images/question.svg')",
                      "background-size": "20%",
                      "background-position": "center",
                      "background-repeat": "no-repeat"
                    }
                  },
                  [
                    _c("h5", { staticClass: "title" }, [
                      _vm._v("What Is Our Vision?")
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "People search for a million things a day. It can be an image search, a product search, or even a people search; there is nothing you cannot look for. We envision a future where this gap between a customer and a vendor is bridged successfully. We aim to be the best local search engine to find the exact thing you need."
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }