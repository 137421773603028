var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "seller-join-app" } }, [
    _c("section", [
      _c("div", { staticClass: "container is-fluid mb-5" }, [
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c("router-link", { attrs: { to: { name: "SearchHome" } } }, [
                _c("div", { staticClass: "my-5" }, [
                  _c("img", {
                    staticStyle: { width: "12.5rem" },
                    attrs: { src: "/images/findhub-logo_1.png" }
                  })
                ])
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-5" }, [
            _c(
              "div",
              { staticClass: "is-hidden-touch" },
              [_c("join-as-seller-form")],
              1
            )
          ]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "is-hidden-desktop" },
            [_c("join-as-seller-form")],
            1
          )
        ])
      ]),
      _c("nav", { staticClass: "tabs has-background-dark" }, [
        _c("div", { staticClass: "container" }, [_c("footer-home")], 1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("div", { staticClass: "mb-3" }, [
        _c(
          "h4",
          {
            staticClass:
              "title is-size-5 has-text-grey-dark is-capitalized has-text-weight-bold"
          },
          [_vm._v("Why Join Findhub As a Seller")]
        )
      ]),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-hand-pointer fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v(
                            "Are you finding it difficult to take your business online?"
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub can take your business online and boost your sales beyond your geographical area. Our platform will help you save costs on websites/applications that are otherwise expensive to create and maintain."
                  )
                ])
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-clipboard-list fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v(
                            "Are you struggling to maintain and update inventory?"
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub is the solution to your problem as there is no need to maintain or update your inventory here like any other e-commerce portal. Our enquiries are real-time and hassle free."
                  )
                ])
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-search-dollar fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v(
                            "Are you looking for more customers by providing discounts and promotions?"
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub makes it easier for you. The price you provide to your customers through Findhub is real-time i.e. you can provide discounts and promote your products on the spot and therefore attract more customers."
                  )
                ])
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-sign-out-alt fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v("Are you facing a high sales return?")
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub helps you lower your sales returns. HOW? Our application allows you to interact with the customers directly & clarify their queries thus meeting their requirements in the best possible manner."
                  )
                ])
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-truck fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v(
                            "Are you having trouble delivering your products to the customer?"
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub can take up the responsibility of delivering your products to your customers and allowing you to concentrate entirely on your sales*."
                  )
                ])
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-cash-register fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v(
                            "Do you want to know what is the most selling item in the market?"
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub generates reports for you thus helping you to expand your business/product line or diversify your business based on the analysis reports."
                  )
                ])
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column is-6" }, [
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: {
                "background-color": "transparent",
                border: "1px solid white"
              }
            },
            [
              _c("div", { staticClass: "has-text-grey-dark" }, [
                _c("div", { staticClass: "media pb-2" }, [
                  _c("figure", { staticClass: "media-left" }, [
                    _c("div", { staticClass: "box p-2" }, [
                      _c("i", { staticClass: "fas fa-wrench fa-lg" })
                    ])
                  ]),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("p", { staticClass: "is-size-7" }, [
                        _c("strong", { staticClass: "has-text-grey-dark" }, [
                          _vm._v(
                            "Are you a plumber, carpenter, or electrician; who is looking to generate more leads for your services within your geographical area?"
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(
                    "Findhub can help you generate more leads & enquiries."
                  )
                ])
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "my-5" }, [
        _c(
          "h4",
          {
            staticClass:
              "title is-size-4 has-text-grey-dark is-capitalized has-text-weight-bold"
          },
          [_vm._v("How to use the app")]
        )
      ]),
      _c("div", { staticClass: "is-hidden-touch" }, [
        _c("figure", { staticClass: "image px-6" }, [
          _c("img", { attrs: { src: "/images/how-to-use-app-web.svg" } })
        ])
      ]),
      _c("div", { staticClass: "is-hidden-desktop" }, [
        _c("figure", { staticClass: "image" }, [
          _c("img", { attrs: { src: "/images/how-to-use-app-mobile.svg" } })
        ])
      ]),
      _c("div", { staticClass: "columns mt-5" }, [
        _c("div", { staticClass: "column" }, [
          _c("iframe", {
            attrs: {
              id: "player",
              height: "200",
              src: "https://www.youtube.com/watch?v=-mNp3-UX9Qc",
              frameborder: "0",
              allow: "autoplay; encrypted-media",
              allowfullscreen: ""
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }