import { DOMAIN_TITLE } from '@/.env'

// views

import SearchHome from '@/views/search/SearchHome'
// import SearchHomeTemp from '@/views/search/SearchHomeTemp'
import LayoutMaster from '@/views/layout/LayoutMaster'
import PageJoinAsSeller from '@/views/page/PageJoinAsSeller'
import TermsAndConditions from '@/views/page/TermsAndConditions'
import WhatWeDo from '@/views/page/WhatWeDo'
import ContactUs from '@/views/page/ContactUs'
import Profile from '@/views/account/Profile'
import SearchProduct from '@/views/search/SearchProduct'
import PrivacyPolicy from '@/views/page/PrivacyPolicy'
import RefundPolicy from '@/views/page/RefundPolicy'
import SellerDirectory from '@/views/page/SellerDirectory'
import Confirmation from '@/views/page/Confirmation'
import OrderDetails from '@/views/order/OrderDetails'
import OrdersList from '@/views/order/OrdersList'
import OrderPayment from '@/views/order/OrderPayment'
import OrderConfirmation from '@/views/order/OrderConfirmation'
import OrderTrack from '@/views/order/OrderTrack'
import OAuthRedirect from '@/views/auth/OAuthRedirect'
import LayoutMasterEmpty from '@/views/layout/LayoutMasterEmpty'
import Enquiry from '@/views/enquiry/Enquiry'
import OrderDeliveryAddress from '@/views/order/OrderDeliveryAddress'
import AboutUs from '@/views/page/AboutUs'
import Page404 from '@/views/page/Page404'
import OrderProcessPayment from '@/views/order/OrderProcessPayment'
import SupportChat from '@/views/page/SupportChat'
import CustomSearch from '@/views/search/CustomSearch'
import SearchHistory from '@/views/search/SearchHistory'
import ChooseAddress from '@/views/account/ListAddress'

export const routes = [
    {
        path: '/',
        component: LayoutMasterEmpty,
        children: [
            {
                path: '/',
                name: 'SearchHome',
                component: SearchHome,
                meta: { title: `${DOMAIN_TITLE} | Home` }
            },
            {
                path: '/oauth-redirect',
                name: 'OuathRedirect',
                component: OAuthRedirect,
                meta: { title: `${DOMAIN_TITLE} | Home` }
            },
            {
                path: '/about-us',
                name: 'AboutUs',
                component: AboutUs,
                meta: { title: `${DOMAIN_TITLE} | About Us` }
            },
            {
                path: '/join-as-seller',
                meta: { title: `${DOMAIN_TITLE} | Join As Seller` },
                component: PageJoinAsSeller
            },
            {
                path: '/terms-and-conditions',
                meta: { title: `${DOMAIN_TITLE} | Join As Seller` },
                component: TermsAndConditions,
                name: 'TermsAndConditions'
            },
            {
                path: '/what-we-do',
                meta: { title: `${DOMAIN_TITLE} | What We Do` },
                component: WhatWeDo
            },
            {
                path: '/contact-us',
                meta: { title: `${DOMAIN_TITLE} | Contact Us` },
                component: ContactUs,
                name: 'ContactUs'
            },
            {
                path: '/privacy-policy',
                meta: { title: `${DOMAIN_TITLE} | Privacy Policy` },
                component: PrivacyPolicy,
                name: 'PrivacyPolicy'
            },
            {
                path: '/refund-policy',
                meta: { title: `${DOMAIN_TITLE} | Refund Policy` },
                component: RefundPolicy,
                name: 'RefundPolicy'
            }
        ]
    },
    {
        path: '/',
        component: LayoutMaster,
        meta: { isAuth: true },
        children: [
            {
                path: 'confirmation',
                meta: { title: `${DOMAIN_TITLE} | Confirmation` },
                component: Confirmation
            },
            {
                path: '/search-product/:id',
                meta: { title: `${DOMAIN_TITLE} | Search Product` },
                component: SearchProduct,
                name: 'SearchProduct'
            },

            {
                path: 'profile',
                meta: { title: `${DOMAIN_TITLE} | Profile` },
                component: Profile,
                name: 'Profile'
            },
            {
                path: 'choose-address',
                meta: { title: `${DOMAIN_TITLE} | Choose Address` },
                component: ChooseAddress,
                name: 'ChooseAddress'
            },
            {
                path: 'enquiry',
                meta: { title: `${DOMAIN_TITLE} | Enquiry` },
                component: Enquiry,
                name: 'Enquiry'
            },
            {
                path: 'order/choose-delivery-address',
                meta: { title: `${DOMAIN_TITLE} | Order` },
                component: OrderDeliveryAddress,
                name: 'OrderDeliveryAddress'
            },
            {
                path: 'order/confirm-details',
                meta: { title: `${DOMAIN_TITLE} | Order Details` },
                component: OrderDetails,
                name: 'OrderDetails'
            },
            {
                path: 'order/process-payment',
                meta: { title: `${DOMAIN_TITLE} | Order Payment` },
                component: OrderProcessPayment,
                name: 'OrderProcessPayment'
            },
            {
                path: 'payment',
                meta: { title: `${DOMAIN_TITLE} | Order Payment` },
                component: OrderPayment
            },
            {
                path: 'order-confirmation',
                meta: { title: `${DOMAIN_TITLE} | Order Confirmation` },
                component: OrderConfirmation,
                name: 'OrderConfirmation'
            },
            {
                path: 'order-track',
                meta: { title: `${DOMAIN_TITLE} | Order Track` },
                component: OrderTrack
            },
            {
                path: 'orders-list',
                meta: { title: `${DOMAIN_TITLE} | Orders List` },
                component: OrdersList,
                name: 'OrdersList'
            },
            {
                path: 'support/chat',
                meta: { title: `${DOMAIN_TITLE} | Support Chat` },
                component: SupportChat,
                name: 'SupportChat'
            },
            {
                path: 'custom-search',
                meta: { title: `${DOMAIN_TITLE} | Custom Search` },
                component: CustomSearch
            },
            {
                path: 'search-history',
                meta: { title: `${DOMAIN_TITLE} | Search History` },
                component: SearchHistory,
                name: 'SearchHistory'
            }

        ]
    },
    {
        path: '/',
        component: LayoutMaster,
        children: [
            {
                path: '/404',
                meta: { title: `${DOMAIN_TITLE} | Refund Policy` },
                component: Page404,
                name: 'Page404'
            },
            {
                path: '/seller-directory',
                meta: { title: `${DOMAIN_TITLE} | Seller Directory` },
                component: SellerDirectory,
                name: 'SellerDirectory'
            }
        ]
    },
    {
        path: '/',
        component: LayoutMaster,
        children: [
            { path: '*', component: Page404 }
        ]
    }
]
