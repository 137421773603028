<template>
    <div class="page-wrapper">
        <page-loader v-if="meta.actions.onLoad.loading === true"></page-loader>
        <div v-else>
            <div class="page-title">
                <div class="title has-text-centered">
                    My Orders
                </div>
            </div>
            <div class="page-content">
                <div class="box" v-for="order in orders.pData.data" :key="order.id">
                    <div class="columns">
                        <div class="column is-half">
                            <b-steps v-model="order.deliveryStep" :has-navigation="false">
                                <b-step-item label="Placed" icon="cart"></b-step-item>
                                <b-step-item label="Shipped" icon="truck"></b-step-item>
                                <b-step-item label="Delivered" icon="map-marker-check"></b-step-item>
                            </b-steps>

                        </div>
                        <div class="column is-half">
                            <div class="has-text-right">
                                <h2 class="is-size-6 has-text-weight-bold">
                                    {{ order.order_number }}
                                </h2>
                                <p class="is-size-7">
                                    {{ order.seller!==null? order.seller.business_name: "" }}
                                </p>
                                <div class="order-items-container">
                                    <div v-for="item in order.order_items" v-bind:key="item.id">
                                        <span class="has-text-weight-bold">
                                            {{ item.name }}
                                        </span> x{{ item.quantity }}
                                    </div>
                                </div>
                                <div class="order-price-container">
                                    <div class="has-text-danger is-size-5">
                                        AED {{ formatAmount(order.grand_total) }}
                                    </div>
                                </div>
                                <b-button type="is-text has-text-info" size="is-small" v-on:click.prevent="getOrderInvoice(order)">
                                        Order Confirmation
                                    </b-button>
                                <b-button type="is-text has-text-info" size="is-small" v-on:click.prevent="requestInvoice(order)">
                                        Request Invoice
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <b-pagination
                    :total="this.orders.pData.meta.total"
                    v-model="this.orders.current_page"
                    :range-before="3"
                    :range-after="2"
                    :per-page="this.orders.pData.meta.per_page"
                    @change="onChangePage"
                >
                </b-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { OrderService } from '@/services/order.service'

export default {
    name: 'OrdersList',
    data: function () {
        return {
            meta: {
                actions: {
                    onLoad: {
                        loading: true
                    }
                }
            },
            orders: {
                pData: {
                    data: []
                },
                current_page: 1
            },
            activeStep: 1
        }
    },
    async mounted () {
        this.orders.pData = await OrderService.getOrdersList()
        this.processOrder()
        this.meta.actions.onLoad.loading = false
    },
    methods: {
        async onChangePage (current_page) {
            this.page = current_page
            this.orders.pData = await OrderService.getOrdersList({
                params: {
                    per_page: this.orders.pData.meta.per_page,
                    page: this.orders.pData.meta.current_page
                }
            })
            this.processOrder()
            this.meta.actions.onLoad.loading = false
        },
        processOrder () {
            this.orders.pData.data.forEach(e => {
                e.deliveryStep = 0
                if (e.shipping_status === 1) {
                    e.deliveryStep = 0
                } else if (e.shipping_status === 2) {
                    e.deliveryStep = 1
                } else if (e.shipping_status === 3) {
                    e.deliveryStep = 2
                }
            })
        },
        async getOrderInvoice (order) {
            try {
                const data = {
                    responseType: 'blob'
                }
                const response = await OrderService.getOrderInvoice(order.id, data)
                var fileURL = window.URL.createObjectURL(new Blob([response]))
                var fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', 'Invoice.' + order.order_number + '.pdf')
                document.body.appendChild(fileLink)
                fileLink.click()
            } catch (e) {
                this.$log.error('Error while downloading invoice', e)
            }
        },
        async requestInvoice (order) {
            try {
                const response = await OrderService.requestInvoice(order.id)
                console.log(response)
                this.$buefy.notification.open({
                    message: 'Your request has been sent to findhub team',
                    type: 'is-success'
                })
            } catch (e) {
                this.$log.error('Error while fetching saved addresses', e)
            }
        }
    }
}
</script>
