import axios from 'axios'
import { API_URL } from '@/.env'

export class PaymentService {
    static async getStripeSecret () {
        const response = await axios.get(`${API_URL}/api/customer/payment/get_stripe_secret`)
        return response.data
    }

    static async getStripSession () {
        const response = await axios.get(`${API_URL}/api/customer/payment/get_stripe_session`)
        return response.data
    }
}
