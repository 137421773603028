<template>
    <div class="modal-content">
        <div class="has-background-white box w-6 ">
            <div class="p-6">
                <h1 class="title has-text-weight-light">Add New Address</h1>
                <div class="columns">
                    <div class="column">
                        <h5 class="title is-5 has-text-weight-light has-text-grey-dark mb-0">Contact Information</h5>
                        <hr class="has-background-grey-dark mt-3">
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">Full Name</label>
                            <div class="control">
                                <input class="input" v-model="name" type="text">
                                <span style="color: red" v-if="errors.name" class="error">{{ errors.name[0] }}</span>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">Phone</label>
                            <div class="control">
                                <input class="input" v-model="phone_number" type="text">
                                <span style="color: red" v-if="errors.phone_number" class="error">{{ errors.phone_number[0] }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <h5 class="title is-5 has-text-weight-light has-text-grey-dark mb-0">Address</h5>
                        <hr class="has-background-grey-dark mt-3">
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">Street Address</label>
                            <div class="control">
                                <input class="input" v-model="address_line_1" type="text">
                                <span style="color: red" v-if="errors.address_line_1" class="error">{{ errors.address_line_1[0] }}</span>
                            </div>
                            <div class="control pt-2">
                                <input class="input" v-model="address_line_2" type="text">
                                <span style="color: red" v-if="errors.address_line_2" class="error">{{ errors.address_line_2[0] }}</span>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">Country</label>
                            <div class="select">
                                <select v-model="country">
                                    <option
                                        v-for="option in $store.state.ref_code.data.countries.data"
                                        :value="option.name"
                                        :key="option.name">
                                        {{ option.name }}
                                    </option>
                                    <span style="color: red" v-if="errors.country" class="error">{{ errors.country[0] }}</span>
                                </select>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">State/Province</label>
                            <div class="select">
                                <select v-model="state">
                                    <option
                                        v-for="option in $store.state.ref_code.data.states.data"
                                        :value="option.name"
                                        :key="option.name">
                                        {{ option.name }}
                                    </option>
                                    <span style="color: red" v-if="errors.state" class="error">{{ errors.state[0] }}</span>
                                </select>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">City</label>
                            <div class="control">
                                <input class="input" v-model="city" type="text">
                                <span style="color: red" v-if="errors.city" class="error">{{ errors.city[0] }}</span>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label has-text-weight-normal is-uppercase required">Zip/Postal Code</label>
                            <div class="control">
                                <input class="input" v-model="postcode" type="text">
                                <span style="color: red" v-if="errors.postcode" class="error">{{ errors.postcode[0] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <GmapMap
                        :center="myCoordinates"
                        :zoom="zoom"
                        :draggable="true"
                        class="map-width"
                        style="height: 30rem; margin: 32px auto"
                        ref="mapRef"
                        @dragend="handleDrag">
                        <GmapMarker
                            :position="mapCoordinates"
                            :clickable="false"
                            :draggable="false">
                        </GmapMarker>
                    </GmapMap>
                </div>
                <div class="columns is-centered">
                    <div class="column is-half has-text-centered">
                        <button class="button is-primary is-primary-addon-1" v-on:click="updateAddress()">Update Address</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    mapActions
} from 'vuex'
import { ProfileService } from '@/services/profile.service'
// import Vuex from 'vuex'
// import Vue from 'vue'
// Vue.use(Vuex)
export default {
    name: 'EditAddressPopupModal',
    props: ['id', 'name', 'address_line_1', 'address_line_2', 'city', 'postcode', 'state', 'country', 'landmark', 'phone_number', 'isd_code', 'latitude', 'longitude'],
    data: function () {
        return {
            map: null,
            zoom: 16,
            myCoordinates: {
                lat: this.latitude,
                lng: this.longitude
            },
            errors: []
            // input: {
            // name: null,
            // address_line_1: null,
            // address_line_2: null,
            // city: null,
            // postcode: null,
            // state: null,
            // country: null,
            // landmark: null,
            // phone_number: null
            // }
        }
    },
    created () {
        // does the user have a saved center? use it instead of the default
        if (localStorage.center) {
            this.myCoordinates = JSON.parse(localStorage.center)
        } else {
            // user's coordinates from browser request
            this.$getLocation({})
                .then((coordinates) => {
                    this.myCoordinates = coordinates
                })
                .catch((error) => alert(error))
        }

        // does the user have a saved zoom? use it instead of the default
        if (localStorage.zoom) {
            this.zoom = parseInt(localStorage.zoom)
        }
    },
    updated () {
        if (!this.map && this.$refs && this.$refs.mapRef) {
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.map = map
            })
        }
    },
    computed: {
        mapCoordinates () {
            if (!this.map) {
                return {
                    lat: 0,
                    lng: 0
                }
            }

            return {
                lat: this.map.getCenter().lat(),
                lng: this.map.getCenter().lng()
            }
        }
    },
    methods: {
        ...mapActions(['refCodeRefresh']),
        handleDrag () {
            // get center and zoom level, store in localstorage
            const center = {
                lat: this.map.getCenter().lat(),
                lng: this.map.getCenter().lng()
            }
            const zoom = this.map.getZoom()

            localStorage.center = JSON.stringify(center)
            localStorage.zoom = zoom
        },

        async updateAddress () {
            // console.log(this.name)
            this.data = {
                id: this.id,
                name: this.name,
                address_line_1: this.address_line_1,
                address_line_2: this.address_line_2,
                city: this.city,
                postcode: this.postcode,
                state: this.state,
                country: this.country,
                landmark: this.landmark,
                phone_number: this.phone_number,
                isd_code: this.isd_code,
                longitude: this.mapCoordinates.lng,
                latitude: this.mapCoordinates.lat
            }
            try {
                this.response = await ProfileService.updateAddress(this.data)
                this.$buefy.notification.open({
                    message: 'Address updated successfully!',
                    type: 'is-success'
                })
                window.location.reload()
            } catch (e) {
                this.errors = e.response.data.errors
                console.log('this.errors', e.response.data.errors)
            }
        }

    },
    mounted () {
        this.refCodeRefresh([
            'countries',
            'states',
            'delivery_type',
            'business_type',
            'core_business',
            'cities'
        ]).then(() => {
            this.pageLoaded = true
        })
        // add the map to a data object
    }
}
</script>
<style scoped>
hr {
    height: 1px;
}
.required:after {
    content:" *";
    color: red;
}
</style>
