import { BaseService } from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'

export class SearchService extends BaseService {
    static async save (reqData) {
        const response = await axios.post(`${API_URL}/api/customer/search`, reqData)
        return response
    }

    static async viewSearch (id, options) {
        const response = await axios.get(`${API_URL}/api/customer/search/${id}`, options)
        return response.data
    }

    static async updateStatus (id, status) {
        const response = await axios.post(`${API_URL}/api/customer/search_term_response/${id}/update_status`, status)
        return response
    }

    static async markForEnquiry (id) {
        const response = await axios.post(`${API_URL}/api/customer/search_term_response/${id}/mark_for_enquiry`, status)
        return response
    }

    static async myEnquiries () {
        const response = await axios.get(`${API_URL}/api/customer/search_term_response/my_enquiries`)
        return response
    }

    static async selectedEnquiry (id) {
        const response = await axios.get(`${API_URL}/api/customer/search_term_response/enquiry`, {
            params: {
                response_id: id
            }
        })
        return response
    }

    static async getCart () {
        const response = await axios.get(`${API_URL}/api/customer/cart`)
        return response
    }

    static async addCartItem (data) {
        const response = await axios.post(`${API_URL}/api/customer/cart/items`, data)
        return response
    }

    static async searchHistory (options) {
        const response = await axios.get(`${API_URL}/api/customer/search`, { params: options?.params })
        return response.data
    }

    static async getSearchSuggestions (options) {
        const response = await axios.get(`${API_URL}/api/customer/search/autocomplete`, { params: options?.params })
        return response.data
    }

    static async cancel (id) {
        const response = await axios.get(`${API_URL}/api/customer/search/${id}/cancel`)
        return response.data
    }

    static async getCategoryTree () {
        const response = await axios.get(`${API_URL}/api/customer/search/get_all_categories`)
        return response.data
    }
}
