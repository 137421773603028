<template>
    <div id="chat-with-admin">
        <div class="page-wrapper">
            <page-loader v-if="loading"></page-loader>
            <div v-else>
                <div class="page-content">
                    <div class="has-background-white p-5" id="chat-messages-container" ref="chatMessagesContainer" style="margin-top: -23px;">
                        <h5 class="title has-text-centered has-text-weight-bold is-size-5-mobile is-size-5">Findhub Support</h5>
                        <div v-if="chat.messages.data.length > 0">
                            <div v-for="(message,index) in chat.messages.data" v-bind:key="index">
                                <div v-if="message.user.type=='ADMIN'">
                                    <div class="columns">
                                        <div class="column is-4">
                                            <div class="p-4 box  chat-box-findhub">
                                                <p> {{ message.body }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="message.user.type=='CUSTOMER'">
                                    <div class="columns">
                                        <div class="column is-4 is-offset-8">
                                            <div class="p-4 box chat-box-customer">
                                                <p> {{ message.body }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="has-background-white">
                        <form action="" v-on:submit.prevent="onChatSubmit">
                            <b-field class="m-0">
                                <b-input placeholder="Type your replies here"
                                         type="search"
                                         custom-class="chat-input-box"
                                         expanded
                                         v-model="chatForm.data.body"
                                >
                                </b-input>
                                <p class="control">
                                    <b-button native-type="submit"
                                              class="button chat-input-button"
                                              icon-pack="fas"
                                              icon-right="paper-plane"
                                              size="is-medium"
                                              :loading="chatForm.loading"
                                              :disabled="chatForm.loading"
                                    >
                                    </b-button>
                                </p>
                            </b-field>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { SupportChatService } from '@/services/support-chat.service'
import Echo from 'laravel-echo'
import { API_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from '@/.env'
import axios from 'axios'

export default {
    name: 'SupportChat',
    data: function () {
        return {
            loading: true,
            chat: {
                thread: null,
                messages: {
                    data: []
                },
                pusher: null,
                echo: null
            },
            chatForm: {
                data: {
                    thread_id: null,
                    body: ''
                },
                loading: false
            }
        }
    },
    async mounted () {
        this.loading = true
        this.chat.thread = await SupportChatService.getActiveThread()
        this.chatForm.data.thread_id = this.chat.thread.id

        // setup pusher and echo.
        this.chat.pusher = require('pusher-js')
        this.chat.echo = new Echo({
            broadcaster: 'pusher',
            key: PUSHER_APP_KEY,
            cluster: PUSHER_APP_CLUSTER,
            forceTLS: true,
            authEndpoint: API_URL + '/api/customer/auth/ws',
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        // eslint-disable-next-line no-undef
                        axios.post(API_URL + '/api/customer/broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name
                        })
                            .then(response => {
                                // eslint-disable-next-line standard/no-callback-literal
                                callback(false, response.data)
                            })
                            .catch(error => {
                                // eslint-disable-next-line standard/no-callback-literal
                                callback(true, error)
                            })
                    }
                }
            }
        })

        // bind to channel.

        this.chat.echo.private(`f.support-chat.${this.chat.thread.id}`)
            .listen('SupportChatAdminMessaged', (e) => {
                this.chat.messages.data.unshift(e.message)
            })

        // get messages upto current timestamp
        this.chat.messages = await SupportChatService.getThreadMessages(this.chat.thread.id)
        this.loading = false
        this.scrollToElement()
    },
    updated () {
        this.$nextTick(() => {
            this.scrollToElement()
        })
    },

    methods: {
        scrollToElement () {
            const el = this.$refs.chatMessagesContainer

            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollTop = el.scrollHeight
            }
        },
        onChatSubmit: async function () {
            var formdata = this.chatForm.data
            if (!formdata.body.length > 0) {
                return
            }
            try {
                this.chatForm.loading = true
                const message = await SupportChatService.postNewMessage(this.chatForm.data)
                this.chat.messages.data.unshift(message)
                this.chatForm.data.body = ''
            } finally {
                this.chatForm.loading = false
            }
        }
    }
}
</script>

<style scoped>
#chat-messages-container {
    height: 500px;
    overflow-y: scroll;
}
</style>
