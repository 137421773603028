var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _vm.meta.actions.onPageLoad.loading === true
        ? _c("page-loader")
        : _c("div", [
            _c("div", { staticClass: "page-title" }, [
              _c("div", { staticClass: "title" }, [_vm._v(" Order Details ")])
            ]),
            _c("div", { staticClass: "page-content" }, [
              _c(
                "div",
                { staticClass: "box" },
                _vm._l(_vm.order.order_items, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "columns is-mobile" },
                    [
                      _c("div", { staticClass: "column is-two-fifths" }, [
                        _c("div", { staticClass: "media" }, [
                          _c("div", { staticClass: "media-left" }, [
                            _c("img", {
                              staticStyle: { width: "50px", height: "50px" },
                              attrs: { src: _vm.imageUrl(null) }
                            })
                          ]),
                          _c("div", { staticClass: "media-content" }, [
                            _c(
                              "h5",
                              { staticClass: "has-text-weight-bold is-size-6" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c("span", { staticClass: "is-size-7" }, [
                              _vm._v(
                                " Quantity: " + _vm._s(item.quantity) + " "
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "column is-two-fifths" }, [
                        _c("div", {}, [
                          _c(
                            "h5",
                            { staticClass: "has-text-weight-bold is-5" },
                            [
                              _vm._v(
                                _vm._s(
                                  item.search_term_response.seller.business_name
                                )
                              )
                            ]
                          ),
                          _c(
                            "figure",
                            {
                              staticClass:
                                "image is-24x24 has-text-centered-mobile"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.imageUrl(
                                    item.search_term_response.seller.logo
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "has-text-right" }, [
                          _c("span", { staticClass: "is-size-5" }, [
                            _vm._v(" " + _vm._s(item.total_price) + " ")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c(
                            "span",
                            { staticClass: "is-size-6 has-text-weight-light" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.quantity) +
                                  " x " +
                                  _vm._s(item.unit_price) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("h3", { staticClass: "has-text-weight-bold mb-5" }, [
                      _vm._v(" Payment Method ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _vm.availablePaymentMethods.indexOf("ONLINE") > -1
                          ? _c(
                              "b-radio",
                              {
                                staticClass: "mr-6",
                                attrs: {
                                  name: "payment_method",
                                  "native-value": "ONLINE"
                                },
                                model: {
                                  value: _vm.payment.method,
                                  callback: function($$v) {
                                    _vm.$set(_vm.payment, "method", $$v)
                                  },
                                  expression: "payment.method"
                                }
                              },
                              [_vm._v(" Credit / Debit Card ")]
                            )
                          : _vm._e(),
                        _vm.availablePaymentMethods.indexOf("COD") > -1
                          ? _c(
                              "b-radio",
                              {
                                attrs: {
                                  name: "payment_method",
                                  "native-value": "COD"
                                },
                                model: {
                                  value: _vm.payment.method,
                                  callback: function($$v) {
                                    _vm.$set(_vm.payment, "method", $$v)
                                  },
                                  expression: "payment.method"
                                }
                              },
                              [_vm._v(" Confirm Order ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("p", [_vm._v("Click to Confirm your Order")])
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("h3", { staticClass: "has-text-weight-bold mb-5" }, [
                      _vm._v(" Order Summary ")
                    ]),
                    _c("div", { staticClass: "columns is-mobile" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", [
                          _c("h6", { staticClass: "is-7" }, [_vm._v("Total")])
                        ])
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "is-pulled-right" }, [
                          _c("h6", { staticClass: "is-7" }, [
                            _vm._v(_vm._s(_vm.order.sub_total))
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "columns is-mobile" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", [
                          _c("h6", { staticClass: "is-7" }, [
                            _vm._v("Service Charge")
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "is-pulled-right" }, [
                          _c("h6", { staticClass: "is-7" }, [
                            _vm._v(
                              _vm._s(
                                _vm.order.commission + _vm.order.shipping_fee
                              )
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "px-10" }, [
                      _c("hr", { staticClass: "hr-bg" })
                    ]),
                    _c("div", { staticClass: "columns is-mobile" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", [
                          _c("h6", { staticClass: "is-7" }, [
                            _vm._v("Total Amount Payable ")
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "is-pulled-right" }, [
                          _c("h6", { staticClass: "is-7 font-color" }, [
                            _vm._v(_vm._s(_vm.order.grand_total))
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "columns is-mobile is-centered" }, [
                _c("div", { staticClass: "column is-half-desktop" }, [
                  _c(
                    "div",
                    { staticClass: "has-text-centered" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "is-primary-addon-1",
                          attrs: {
                            type: "is-primary",
                            loading: _vm.meta.actions.onPlaceOrder.loading
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onPlaceOrder()
                            }
                          }
                        },
                        [_vm._v(" Place Order and Pay ")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }