var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home-app" } }, [
    _c("section", { staticClass: "hero is-primary-addon-1 is-fullheight" }, [
      _c("div", { staticClass: "hero-head" }, [
        _c("nav", { staticClass: "navbar" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "navbar-brand" }, [
              _vm._m(0),
              _vm.$store.getters["auth/isAuthenticated"]
                ? _c(
                    "span",
                    {
                      staticClass: "home-navbar-burger",
                      attrs: { "data-target": "navbarMenuHeroA" },
                      on: {
                        click: function($event) {
                          _vm.open = true
                        }
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            "background-color": "transparent",
                            "border-color": "transparent"
                          },
                          attrs: {
                            type: "has-text-white pt-5 is-hidden-desktop",
                            "icon-right": _vm.active ? "menu-up" : "menu-down"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$store.state.auth.currentUser.name) + " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            "background-color": "transparent",
                            "border-color": "transparent"
                          },
                          attrs: {
                            type: "has-text-white pt-5 is-hidden-desktop",
                            "icon-right": "menu-up"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$store.state.auth.currentUser.name) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.$store.getters["auth/isAuthenticated"]
                ? _c(
                    "span",
                    {
                      staticClass: "navbar-burger",
                      staticStyle: { color: "white" },
                      attrs: { "data-target": "navbarMenuHeroA" },
                      on: { click: _vm.openLoginPopupModal }
                    },
                    [_c("span"), _c("span"), _c("span")]
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "navbar-menu", attrs: { id: "navbarMenuHeroA" } },
              [
                _c("div", { staticClass: "navbar-end" }, [
                  _c("div", { staticClass: "navbar-item" }, [
                    !_vm.$store.getters["auth/isAuthenticated"]
                      ? _c("span", [
                          _c("a", { on: { click: _vm.openLoginPopupModal } }, [
                            _c("img", {
                              staticClass: "toggle-button-image-2 mr-4",
                              staticStyle: { width: "2.5rem" },
                              attrs: { src: "/images/home-login.svg" }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.$store.getters["auth/isAuthenticated"]
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-dropdown",
                              {
                                attrs: { "aria-role": "list" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "trigger",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              staticStyle: {
                                                "background-color":
                                                  "transparent",
                                                "border-color": "transparent"
                                              },
                                              attrs: {
                                                type: "has-text-white",
                                                "icon-right": active
                                                  ? "menu-up"
                                                  : "menu-down"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$store.state.auth
                                                    .currentUser.name
                                                ) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  862808699
                                )
                              },
                              [
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: {
                                        "background-color": "transparent",
                                        "border-color": "transparent"
                                      },
                                      attrs: {
                                        type: "has-text-white",
                                        "icon-right": "menu-up"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$store.state.auth.currentUser.name
                                        ) + " "
                                      )
                                    ]
                                  )
                                ],
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { "aria-role": "listitem" },
                                    on: { click: _vm.onLogout }
                                  },
                                  [_vm._v("Logout")]
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "navbar-item" }, [
                    _c(
                      "div",
                      [
                        _vm.$store.getters["auth/isAuthenticated"]
                          ? _c(
                              "div",
                              {
                                staticClass: "is-clickable",
                                staticStyle: { width: "1.5rem" },
                                on: {
                                  click: function($event) {
                                    _vm.open = true
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "toggle-button-image-1",
                                  attrs: { src: "/images/menubar-white.svg" }
                                })
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-sidebar",
                          {
                            attrs: {
                              type:
                                "is-primary-sidebargradient has-text-weight-semibold",
                              fullheight: _vm.fullheight,
                              fullwidth: _vm.fullwidth,
                              overlay: _vm.overlay,
                              right: _vm.right
                            },
                            model: {
                              value: _vm.open,
                              callback: function($$v) {
                                _vm.open = $$v
                              },
                              expression: "open"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "px-4 pt-6" },
                              [
                                _c(
                                  "b-menu",
                                  [
                                    _c(
                                      "b-menu-list",
                                      { attrs: { label: "Menu" } },
                                      [
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "Enquiry" },
                                            icon: "information-outline",
                                            label: "My Enquiries"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "OrdersList" },
                                            icon: "cart-outline",
                                            label: "My Orders"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "Profile" },
                                            icon: "account-outline",
                                            label: "My Profile"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "SearchHistory" },
                                            icon: "history",
                                            label: "Search History"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "SellerDirectory" },
                                            icon: "store",
                                            label: "Seller Directory"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "PrivacyPolicy" },
                                            icon: "shield",
                                            label: "Privacy Policy"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "RefundPolicy" },
                                            icon: "undo",
                                            label: "Refund Policy"
                                          }
                                        }),
                                        _c("b-menu-item", {
                                          attrs: {
                                            tag: "router-link",
                                            to: { name: "TermsAndConditions" },
                                            icon: "file",
                                            label: "Terms & Conditions"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-menu-list",
                                      { attrs: { label: "Actions" } },
                                      [
                                        _c("b-menu-item", {
                                          attrs: {
                                            icon: "logout",
                                            label: "Logout"
                                          },
                                          on: { click: _vm.onLogout }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "column p-0 ",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "flex-end"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "column p-0 is-pulled-left is-three-quarters",
                                  attrs: {
                                    src: "/images/menubar-icon-lady.svg"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    !_vm.$store.getters["auth/isAuthenticated"]
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "is-clickable",
                              on: { click: _vm.openLoginPopupModal }
                            },
                            [
                              _c("img", {
                                staticClass: "toggle-button-image-1",
                                staticStyle: { width: "1.5rem" },
                                attrs: { src: "/images/menubar-white.svg" }
                              })
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "hero-body py-0" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "columns is-vcentered mt-auto" }, [
            _c("div", { staticClass: "column" }, [
              _c(
                "h1",
                {
                  staticClass:
                    "title is-size-1 has-text-white is-size-3-touch mb-2"
                },
                [_vm._v(" What are you searching today?! ")]
              ),
              _c(
                "form",
                {
                  ref: "searchFormRef",
                  attrs: { action: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSearchSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c(
                      "div",
                      { staticClass: "control is-expanded is-hidden-touch" },
                      [
                        _c(
                          "b-field",
                          [
                            _c("b-autocomplete", {
                              ref: "searchQueryInput",
                              staticStyle: {
                                "border-top-right-radius": "0px",
                                "border-bottom-right-radius": "0px"
                              },
                              attrs: {
                                placeholder: "iPhone 12 cover, Samsung note 9",
                                type: "search",
                                expanded: "",
                                size: "is-medium",
                                "custom-class": "sb-input-search",
                                field: "name",
                                data: _vm.search_form.suggestion.data,
                                clearable: true,
                                "confirm-keys":
                                  _vm.search_form.suggestion.confirm_keys
                              },
                              on: { typing: _vm.getSearchSuggestions },
                              nativeOn: {
                                keyup: function($event) {
                                  return _vm.completeSearchSuggestion($event)
                                }
                              },
                              model: {
                                value: _vm.search_form.data.query,
                                callback: function($$v) {
                                  _vm.$set(_vm.search_form.data, "query", $$v)
                                },
                                expression: "search_form.data.query"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("b-button", {
                                  staticClass:
                                    "is-primary-addon-2 search-button-color",
                                  staticStyle: {
                                    "border-top-left-radius": "0px",
                                    "border-bottom-left-radius": "0px"
                                  },
                                  attrs: {
                                    "native-type": "submit",
                                    loading: _vm.search_form.loader,
                                    "icon-pack": "fas",
                                    size: "is-medium",
                                    "icon-right": "search"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "control is-expanded is-hidden-desktop" },
                      [
                        _c(
                          "b-field",
                          [
                            _c("b-autocomplete", {
                              ref: "searchQueryInput",
                              staticStyle: {
                                "border-top-right-radius": "0px",
                                "border-bottom-right-radius": "0px"
                              },
                              attrs: {
                                placeholder: "iPhone 12 cover , Samsung note 9",
                                type: "search",
                                expanded: "",
                                "custom-class": "sb-input-search",
                                field: "name",
                                data: _vm.search_form.suggestion.data,
                                clearable: true,
                                "confirm-keys":
                                  _vm.search_form.suggestion.confirm_keys
                              },
                              on: { typing: _vm.getSearchSuggestions },
                              nativeOn: {
                                keyup: function($event) {
                                  return _vm.completeSearchSuggestion($event)
                                }
                              },
                              model: {
                                value: _vm.search_form.data.query,
                                callback: function($$v) {
                                  _vm.$set(_vm.search_form.data, "query", $$v)
                                },
                                expression: "search_form.data.query"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("b-button", {
                                  staticClass:
                                    "is-primary-addon-2 search-button-color",
                                  staticStyle: {
                                    "border-top-left-radius": "0px",
                                    "border-bottom-left-radius": "0px"
                                  },
                                  attrs: {
                                    "native-type": "submit",
                                    loading: _vm.search_form.loader,
                                    "icon-pack": "fas",
                                    "icon-right": "search"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "pt-2" },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "locationDropdown",
                      attrs: { "aria-role": "list", triggers: ["click"] },
                      on: { change: _vm.onSelectSavedAddress },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function() {
                            return [
                              _c("b-field", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "control has-icons-left  is-clearfix "
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.search_form.data.location
                                              .input_value,
                                          expression:
                                            "search_form.data.location.input_value"
                                        }
                                      ],
                                      ref: "locationQuery",
                                      staticClass:
                                        "input input-naked placeholder-color",
                                      staticStyle: {
                                        height: "2rem",
                                        background: "transparent",
                                        width: "20.5rem"
                                      },
                                      attrs: {
                                        type: "text",
                                        autocomplete: "off",
                                        placeholder:
                                          _vm.search_form.data.location
                                            .display_name
                                      },
                                      domProps: {
                                        value:
                                          _vm.search_form.data.location
                                            .input_value
                                      },
                                      on: {
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.search_form.data.location,
                                            "input_value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "icon is-left",
                                        staticStyle: { height: "2rem" }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "toggle-button-image-2",
                                          staticStyle: { width: "1rem" },
                                          attrs: {
                                            src: "/images/locationicon.svg"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.user.selected_address,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "selected_address", $$v)
                        },
                        expression: "user.selected_address"
                      }
                    },
                    [
                      !_vm.$store.getters["auth/isAuthenticated"]
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "aria-role": "menu-item",
                                focusable: false,
                                custom: ""
                              }
                            },
                            [
                              _c(
                                "a",
                                { on: { click: _vm.openLoginPopupModal } },
                                [_vm._v("Login ")]
                              ),
                              _vm._v(" to view history ")
                            ]
                          )
                        : _vm._e(),
                      _vm._l(_vm.user.saved_addresses, function(
                        address,
                        index
                      ) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: index,
                            attrs: { value: address, "aria-role": "listitem" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "media" },
                              [
                                _c("b-icon", {
                                  staticClass: "media-left",
                                  attrs: { icon: "clock-time-three-outline" }
                                }),
                                _c("div", { staticClass: "media-content" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(address.nick_name) +
                                        " - " +
                                        _vm._s(address.address_line_1)
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("div", { staticClass: "pt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-light is-primary is-small",
                    on: { click: _vm.openCustomSearchPopupModal }
                  },
                  [_vm._v("Custom Search "), _vm._m(1)]
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "column is-hidden-touch" },
              [
                _c(
                  "b-carousel",
                  { attrs: { "indicator-inside": false } },
                  _vm._l(1, function(item, i) {
                    return _c(
                      "b-carousel-item",
                      { key: i },
                      [
                        _c("b-image", {
                          staticClass: "image",
                          attrs: { src: _vm.getImgUrl(i) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column mobile-banner is-hidden-desktop pb-0" },
              [
                _c(
                  "b-carousel",
                  { attrs: { "indicator-inside": false } },
                  _vm._l(1, function(item, i) {
                    return _c(
                      "b-carousel-item",
                      { key: i },
                      [
                        _c("b-image", {
                          staticClass: "image mobile-banner",
                          attrs: { src: _vm.getImgUrl(i) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "hero-foot" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "columns is-mobile is-hidden-touch" }, [
            _vm._m(2),
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "field has-addons is-pulled-right" }, [
                _c(
                  "p",
                  { staticClass: "control" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "SupportChat" } } },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-rounded is-medium has-background-dark border-none mr-1"
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-comments mr-1 is-pulled-left",
                              staticStyle: { color: "white" }
                            }),
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "has-text-white is-size-6 is-multiline is-size-6-mobile"
                              },
                              [_vm._v("Chat with us")]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._m(3)
              ])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "columns is-mobile is-hidden-desktop",
              staticStyle: { padding: "0px 24px 24px 24px" }
            },
            [
              _vm._m(4),
              _c("div", { staticClass: "column py-0" }, [
                _c("div", { staticClass: "field has-addons is-pulled-right" }, [
                  _c(
                    "p",
                    { staticClass: "control" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "SupportChat" } } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button is-rounded has-background-dark border-none mr-1 px-4"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-comments mr-1 is-pulled-left",
                                staticStyle: { color: "white" }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._m(5)
                ])
              ])
            ]
          )
        ]),
        _c("nav", { staticClass: "tabs has-background-dark" }, [
          _c("div", { staticClass: "container" }, [_c("footer-home")], 1)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-4" }, [
      _c("img", {
        staticStyle: { width: "10rem" },
        attrs: { src: "/images/new_logo_name_white.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon pl-5 pr-5" }, [
      _c("i", { staticClass: "fas fa-camera" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://apps.apple.com/in/app/findhub/id1579928022"
              }
            },
            [
              _c("img", {
                staticStyle: { width: "164px" },
                attrs: { src: "/images/appstore.png" }
              })
            ]
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://play.google.com/store/apps/details?id=app.findhub.main"
              }
            },
            [
              _c("img", {
                staticStyle: { width: "164px" },
                attrs: { src: "/images/playstore.png" }
              })
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c(
        "button",
        {
          staticClass:
            "button is-rounded is-medium has-background-dark border-none"
        },
        [
          _c(
            "h4",
            {
              staticClass:
                "has-text-white is-size-6 is-multiline is-size-6-mobile pr-2"
            },
            [_vm._v("Whatsapp ")]
          ),
          _c("a", { attrs: { href: "https://wa.me/919061501127" } }, [
            _c("img", {
              staticClass: "pt-1",
              staticStyle: { width: "27px" },
              attrs: { src: "/images/whatsapp_logo.png" }
            })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-narrow py-0" }, [
      _c("div", { staticClass: "field has-addons is-pulled-right" }, [
        _c("p", { staticClass: "control" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://apps.apple.com/in/app/findhub/id1579928022"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "button is-rounded has-background-dark border-none mr-1 px-4"
                },
                [
                  _c("img", {
                    staticStyle: { width: "20px" },
                    attrs: { src: "/images/app-store-logo.png" }
                  })
                ]
              )
            ]
          )
        ]),
        _c("p", { staticClass: "control" }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://play.google.com/store/apps/details?id=app.findhub.main"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "button is-rounded has-background-dark border-none px-4"
                },
                [
                  _c("img", {
                    staticStyle: { width: "24px" },
                    attrs: { src: "/images/play-store-logo.png" }
                  })
                ]
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c(
        "button",
        {
          staticClass: "button is-rounded has-background-dark border-none px-4"
        },
        [
          _c("a", { attrs: { href: "https://wa.me/919061501127" } }, [
            _c("img", {
              staticClass: "pt-1",
              staticStyle: { width: "20px" },
              attrs: { src: "/images/whatsapp_logo.png" }
            })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }