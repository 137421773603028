<template>
    <div id="page-wrapper" class="has-background-white">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'LayoutMasterEmpty',
    components: {

    },
    data: function () {
        return {

        }
    },
    methods: {

    }
}
</script>
