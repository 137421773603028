var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "columns p-4 is-centered",
      staticStyle: { "background-color": "#D31F3E" }
    },
    [
      _c("div", { staticClass: "columns is-mobile is-centered is-multiline" }, [
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/about-us" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("About Us")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/what-we-do" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("What We Do")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/privacy-policy" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Privacy Policy")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/refund-policy" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Refund Policy")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/terms-and-conditions" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Terms & Conditions")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: "/contact-us" } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Contact Us")
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("router-link", { attrs: { to: { name: "SupportChat" } } }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Chat with us")
              ])
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }