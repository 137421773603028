<template>
    <!-- JOIN AS SELLER PAGE -->
    <div id="seller-join-app">
        <section>
            <div class="container is-fluid mb-5">
                <div class="columns">
                    <div class="column">
                        <router-link :to="{ name: 'SearchHome' }">
                            <div class="my-5">
                                <img style="width: 12.5rem;" src="/images/findhub-logo_1.png">
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-5">
                        <div class="is-hidden-touch">
                            <join-as-seller-form></join-as-seller-form>
                        </div>
                    </div>
                    <div class="column">
                        <div class="mb-3">
                            <h4 class="title is-size-5 has-text-grey-dark is-capitalized has-text-weight-bold">Why Join Findhub As a Seller</h4>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-hand-pointer fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Are you finding it difficult to take your business online?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub can take your business online and boost your sales beyond
                                            your geographical area. Our platform will help you save costs on websites/applications
                                            that are otherwise expensive to create and maintain.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-clipboard-list fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Are you struggling to maintain and update inventory?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub is the solution to your problem as there is no need to
                                            maintain or update your inventory here like any other e-commerce
                                            portal. Our enquiries are real-time and hassle free.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-search-dollar fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Are you looking for more customers by providing discounts and promotions?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub makes it easier for you. The price you provide to your customers
                                            through Findhub is real-time i.e. you can provide discounts and promote your
                                            products on the spot and therefore attract more customers.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-sign-out-alt fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Are you facing a high sales return?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub helps you lower your sales returns. HOW? Our application allows you
                                            to interact with the customers directly & clarify their queries thus meeting
                                            their requirements in the best possible manner.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-truck fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Are you having trouble delivering your products to the customer?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub can take up the responsibility of delivering your products to your
                                            customers and allowing you to concentrate entirely on your sales*.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-cash-register fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Do you want to know what is the most selling item in the market?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub generates reports for you thus helping you to expand your business/product
                                            line or diversify your business based on the analysis reports.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <div class="box" style="background-color: transparent; border: 1px solid white;">
                                    <div class="has-text-grey-dark">
                                        <div class="media pb-2">
                                            <figure class="media-left">
                                                <div class="box p-2"><i class="fas fa-wrench fa-lg"></i></div>
                                            </figure>
                                            <div class="media-content">
                                                <div class="content">
                                                    <p class="is-size-7">
                                                        <strong class="has-text-grey-dark">Are you a plumber, carpenter, or electrician; who is
                                                            looking to generate more leads for your services
                                                            within your geographical area?</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="is-size-7">Findhub can help you generate more leads & enquiries.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-5">
                            <h4 class="title is-size-4 has-text-grey-dark is-capitalized has-text-weight-bold">How to use the app</h4>
                        </div>
                        <div class="is-hidden-touch">
                            <figure class="image px-6">
                                <img src="/images/how-to-use-app-web.svg">
                            </figure>
                        </div>
                        <div class="is-hidden-desktop">
                            <figure class="image">
                                <img src="/images/how-to-use-app-mobile.svg">
                            </figure>
                        </div>
                        <div class="columns mt-5">
                            <div class="column">
                                <iframe id="player" height="200" src="https://www.youtube.com/watch?v=-mNp3-UX9Qc" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="is-hidden-desktop">
                        <join-as-seller-form></join-as-seller-form>
                    </div>
                </div>
            </div>
            <nav class="tabs has-background-dark">
                <div class="container">
                    <footer-home></footer-home>
                </div>
            </nav>
        </section>
    </div>
</template>

<script>
import JoinAsSellerForm from '../../components/page/JoinAsSellerForm.vue'
import FooterHome from '../layout/FooterHome.vue'
export default {
    components: {
        JoinAsSellerForm,
        FooterHome
    },
    name: 'PageJoinAsSeller'
}
</script>

<style scoped>
</style>
