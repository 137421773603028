// Firebase App (the core Firebase SDK) is always required and must be listed first
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firebase-database'
import { API_URL } from '@/.env'
import axios from 'axios'

export class SupportChatService {
    static async getActiveThread () {
        const response = await axios.get(`${API_URL}/api/customer/support-chat/active-thread`)
        return response.data
    }

    static async postNewMessage (data) {
        const response = await axios.post(`${API_URL}/api/customer/support-chat/thread/message`, data)
        return response.data
    }

    static async getThreadMessages (threadId) {
        const response = await axios.get(`${API_URL}/api/customer/support-chat/thread/${threadId}/messages`)
        return response.data
    }
}
